import React, { useEffect, useState } from "react";
import InitialsLogo from "../../all/InitialsLogo";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";
import Svg from "../../../components/assets/Iphone-spinner-2.gif"
import { ScaleLoader } from 'react-spinners';

function DashboardPipelineSkeleton() {

  return (
    <>
      <div className="flex  px-[18px]  py-[12px] items-center border-t border-t-gray-300 ">
        <div className="w-2/5 flex items-center">
          <Skeleton width="32px" height="32px" />
          <div className="ml-2 flex w-full flex-col">
            <Skeleton width={"200px"} height={10} />
            <Skeleton width={"100px"} height={10} />
          </div>
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />{" "}
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />
        </div>
      </div>
      <div className="flex  px-[18px]  py-[12px] items-center border-t border-t-gray-300 ">
        <div className="w-2/5 flex items-center">
          <Skeleton width="32px" height="32px" />
          <div className="ml-2 flex w-full flex-col">
            <Skeleton width={"200px"} height={10} />
            <Skeleton width={"100px"} height={10} />
          </div>
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />{" "}
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />
        </div>
        <div className="w-1/5 px-[2px]">
          <Skeleton height={"31px"} />
        </div>
      </div>
    </>
  );
}



function DashboardPipeline({ data, loading }) {


  const [pipelineData, setPipelineData] = useState(null)
  const[sortBulian,setSortBulian]=useState(true)

  useEffect(() => {
    setPipelineData(data)
  }, [data])


  const sortByAlphabet = () => {
    const sortedData = [...pipelineData]?.sort((a, b) => a.position.localeCompare(b.position));
    setPipelineData(sortedData);
  };
  

  const sortByDate = () => {
    const sortedData =  [...pipelineData]?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setPipelineData(sortedData)
  }

  const nav = useNavigate()
  const calculateTotal = (stages) => {
    let total = 0;
    const filteredStages = stages?.filter(
      (item) =>
        item?.name !== "Hired" &&
        item?.name !== "New Applicant" &&
        item?.name !== "Rejected"
    );
    filteredStages?.forEach((item) => {
      total += +String(item?.value).split(" ")[0];
    });
    return total;
  };

  return (
    <div className="rounded-[12px] bg-white shadow-PipelineCardsShadow py-[18px]">
      <div className="flex justify-between px-[18px]">
        <span className="text-gray-800 text-base font-medium not-italic">
          Hiring pipeline
        </span>
        <button>
          <Link
            to="/job/active"
            className="text-[#1369E9] text-sm font-normal not-italic "
          >
            View all jobs
          </Link>
        </button>
      </div>
      <div className="flex mt-[16px] px-[18px]  py-[12px] items-center bg-bg1">
        <span className="w-2/5 flex items-center cursor-pointer"
          onClick={e=>{
            if(sortBulian){
              sortByAlphabet()
              setSortBulian(!sortBulian)
            }
            else{
              sortByDate()
              setSortBulian(!sortBulian)
            }
            }}
        >Jobs
          <svg className="ml-[10px]" xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
            <path d="M5.78445 3.49328L4.44652 2.02321L3.62958 1.12102C3.28364 0.74091 2.72095 0.74091 2.37501 1.12102L0.215966 3.49328C-0.0674595 3.8047 0.136774 4.33594 0.532737 4.33594H2.871H5.46768C5.86781 4.33594 6.06788 3.8047 5.78445 3.49328Z" fill="#5F6989" />
            <path d="M0.2175 8.09266L1.55544 9.56272L2.37237 10.4649C2.71832 10.845 3.281 10.845 3.62695 10.4649L5.78599 8.09266C6.06941 7.78124 5.86518 7.25 5.46922 7.25L3.13095 7.25L0.534271 7.25C0.13414 7.25 -0.0659258 7.78124 0.2175 8.09266Z" fill="#5F6989" />
          </svg>
        </span>
        <span className="w-1/5 flex justify-center">New applicant</span>
        <span className="w-1/5 flex justify-center">In process</span>
        <span className="w-1/5 flex justify-center">Hired</span>
      </div>
      {pipelineData === null ? (
        <DashboardPipelineSkeleton />
      ) : (
        <>
          {pipelineData?.map((item, idx) => (
            <div
              className="flex px-[18px]  py-[12px] items-center border-t border-t-gray-300 "
              key={idx}
            >
              <div className="w-2/5 flex items-center cursor-pointer"
                onClick={e => nav(`/job/active/${item?.id}/description`)}
              >
                <div>

                  <InitialsLogo
                    str={item?.company?.name??"Natera"}
                    company={true}
                    width="32px"
                    height="32px"
                  />
                </div>
                <div className="flex flex-col ml-2">
                  <span className="text-gray-800 text-sm font-medium not-italic">
                    {item?.position}
                  </span>
                  <span className="text-gray-600 text-xs font-normal not-italic">
                    {/* {item?.company?.name} */}
                    {item?.department?.length >0? item?.department[0] + ' • ':""  } {"Total candidates : " + item?.totalCandidates }
                  </span>
                </div>
              </div>


              <div className="w-1/5 px-[2px]">
                <div
                  onClick={e => nav(`/job/active/${item?.id}/candidates?applied`)}
                  className={`py-[6px] text-sm  w-full bg-[#EBEBF4] cursor-pointer flex justify-center items-center rounded-l-lg h-8 ${String(item?.stages[0]?.value)?.split(" ")[0] != 0
                    ? ""
                    : "bg-bg1"
                    }`}
                >
                  {item?.stages[0]?.value > 0
                    ? item?.stages[0]?.value + " applicants"
                    : ""}
                </div>
              </div>

              <div className="w-1/5 px-[2px]">
                <div
                  onClick={e => nav(`/job/active/${item?.id}/candidates?process`)}
                  className={`py-[6px] text-sm w-full cursor-pointer flex justify-center h-8 ${calculateTotal(item?.stages) === 0
                    ? "bg-bg1"
                    : "bg-[#FFF5E6]"
                    }`}
                >
                  {calculateTotal(item?.stages) === 0
                    ? ""
                    : calculateTotal(item?.stages) + " applicants"}
                </div>
              </div>
              <div className="w-1/5 px-[2px]">
                <div
                  onClick={e => nav(`/job/active/${item?.id}/candidates?hired`)}
                  className={`py-[6px] text-sm w-full cursor-pointer flex justify-center rounded-r-lg h-8 ${item?.stages?.filter((stage) => stage?.name === "Hired")[0]
                    ?.value === 0
                    ? "bg-bg1"
                    : "bg-lightbluebg "
                    }`}
                >
                  {item?.stages?.filter((stage) => stage?.name === "Hired")[0]
                    ?.value > 0
                    ? item?.stages?.filter((stage) => stage?.name === "Hired")[0]
                      ?.value + " applicants"
                    : ""}
                </div>
              </div>
            </div>
          ))}
          {!loading &&
            <div className="h-[100px] flex justify-center items-center">
              <ScaleLoader color="#1369e9" loading={true} />
              {/* <img src={Svg} alt="SVG Image" /> */}
            </div>
          }
        </>
      )}
    </div>
  );
}

export default DashboardPipeline;
