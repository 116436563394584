import Skeleton from "react-loading-skeleton";
import { ScaleLoader } from "react-spinners";
import { useState, useEffect } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";
import { fetchJobPostList } from "../../../../redux/services";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";

const core = require("../../../../lib/core");

function JobListSidebar({ selectJob, params, setParams, ...props }) {
  const dispatch = useDispatch();

  const {
    jobList,
    activeCount,
    unprocessedCount,
    draftCount,
    archivedCount,
    closedCount,
  } = useSelector((state) => {
    return {
      jobList: state?.JobExpandSlice?.jobList?.content,
      activeCount: state?.JobExpandSlice?.jobList?.active,
      unprocessedCount: state?.JobExpandSlice?.jobList?.unprocessed,
      draftCount: state?.JobExpandSlice?.jobList?.draft,
      archivedCount: state?.JobExpandSlice?.jobList?.archived,
      closedCount: state?.JobExpandSlice?.jobList?.closed,
    };
  });

  const [jobType, setJobType] = useState("active");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [openMoveMenu, setOpenMoveMenu] = useState(false);
  const [showCandidates, setShowCandidates] = useState({});

  // List of jobs
  const [jobs, setJobs] = useState([]);
  const [unprocessedJobs, setUnprocessedJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  // Has more jobs
  const [activeHasMore, setActiveHasMore] = useState(true);
  const [unprocessedHasMore, setUnprocessedHasMore] = useState(true);
  const [draftHasMore, setDraftHasMore] = useState(true);
  const [archivedHasMore, setArchivedHasMore] = useState(true);
  const [closedHasMore, setClosedHasMore] = useState(true);
  // Loading state while pagination
  const [loading, setLoading] = useState("");
  const [reload, setReload] = useState(0);
  // Action - append or replace
  const [replace, setReplace] = useState(props?.replace ?? false);

  const barBelowItems = [
    {
      name: "Active",
      list: jobs,
      count: activeCount ?? 0,
      hasMore: activeHasMore,
      loading: loading == "active",
    },
    {
      name: "Closed",
      list: closedJobs,
      count: closedCount ?? 0,
      hasMore: closedHasMore,
      loading: loading == "closed",
    },
    {
      name: "Unprocessed",
      list: unprocessedJobs,
      count: unprocessedCount ?? 0,
      hasMore: unprocessedHasMore,
      loading: loading == "unprocessed",
    },
    {
      name: "Draft",
      list: draftJobs,
      count: draftCount ?? 0,
      hasMore: draftHasMore,
      loading: loading == "draft",
    },
    {
      name: "Archived",
      list: archivedJobs,
      count: archivedCount ?? 0,
      hasMore: archivedHasMore,
      loading: loading == "archived",
    },
  ];

  const jobActions =
    // actions for draft
    jobType == "private" || jobType == "draft"
      ? [
          { name: "Publish job", tag: "active" },
          { name: "Close job", tag: "closed" },
          { name: "Archive job", tag: "archived" },
          { name: "Delete job", tag: "deleted" },
        ]
      : // actions for archived
        jobType == "closed"
        ? [
            { name: "Publish job", tag: "active" },
            { name: "Unpublish job", tag: "draft" },
            { name: "Archive job", tag: "archived" },
            { name: "Delete job", tag: "deleted" },
          ]
        : // actions for unprocessed
          jobType == "archived"
          ? [
              { name: "Publish job", tag: "active" },
              { name: "Unpublish job", tag: "draft" },
              { name: "Close job", tag: "closed" },
              { name: "Delete job", tag: "deleted" },
            ]
          : // actions for unprocessed
            jobType == "unprocessed"
            ? [
                { name: "Activate job", tag: "active" },
                { name: "Unpublish job", tag: "draft" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ]
            : // actions for active
              [
                { name: "Unpublish job", tag: "draft" },
                { name: "Archive job", tag: "archived" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ];
  const appendJobList = () => {
    if (jobList?.length > 0 && jobList[0]?.status == "active") {
      setJobs((prev) => [...new Set([...prev, ...jobList])]);
    } else if (jobList?.length > 0 && jobList[0]?.status == "unprocessed") {
      setUnprocessedJobs((prev) => [...new Set([...prev, ...jobList])]);
    } else if (jobList?.length > 0 && jobList[0]?.status == "draft") {
      setDraftJobs((prev) => [...new Set([...prev, ...jobList])]);
    } else if (jobList?.length > 0 && jobList[0]?.status == "archived") {
      setArchivedJobs((prev) => [...new Set([...prev, ...jobList])]);
    } else if (jobList?.length > 0 && jobList[0]?.status == "closed") {
      setClosedJobs((prev) => [...new Set([...prev, ...jobList])]);
    }
  };

  const replaceJobList = () => {
    if (jobList.length > 0 && jobList[0]?.status == "active")
      setJobs([...jobList]);
    else if (jobList.length > 0 && jobList[0]?.status == "unprocessed")
      setUnprocessedJobs([...jobList]);
    else if (jobList.length > 0 && jobList[0]?.status == "draft")
      setDraftJobs([...jobList]);
    else if (jobList.length > 0 && jobList[0]?.status == "archived")
      setArchivedJobs([...jobList]);
    else if (jobList.length > 0 && jobList[0]?.status == "closed")
      setClosedJobs([...jobList]);
  };

  useEffect(() => {
    // If joblist exists
    if (jobList) {
      // If joblist has some data
      if (jobList?.length > 0) {
        if (replace) replaceJobList();
        else appendJobList();
      }
      // If joblist has no more data
      else if (jobList?.length == 0) {
        if (jobType === "active") setActiveHasMore(false);
        else if (jobType === "draft") setDraftHasMore(false);
        else if (jobType === "archived") setArchivedHasMore(false);
        else if (jobType === "unprocessed") setUnprocessedHasMore(false);
        else if (jobType === "closed") setClosedHasMore(false);
      }
    }
    setLoading("");
    // Select and open job from list
    if (!params.jobId && jobList != null) {
      setParams({
        jobType: jobList[0]?.status ?? "active",
        jobId: jobList[0]?.id,
        tab: "description",
      });
      selectJob(jobList[0]?.id, jobList[0]?.status);
      $("#job_details_page .list-item")[0]?.classList?.add("active");
    } else {
      // selectJob(params?.jobId, params?.jobType, params?.tab ?? "description");
    }
  }, [jobList]);

  useEffect(() => {
    // console.log(params?.jobType);
    setJobType(params?.jobType);
    getActiveJobsbyPage(params?.jobType ?? "active");
  }, [reload]);

  const getActiveJobsbyPage = async (status) => {
    setLoading(status);
    try {
      let jobsLength =
        status === "active"
          ? jobs?.length
          : status === "closed"
            ? closedJobs?.length
            : status === "unprocessed"
              ? unprocessedJobs?.length
              : status === "draft"
                ? draftJobs?.length
                : archivedJobs?.length;
      if (jobsLength % 20 != 0) {
        setLoading("");
        if (status === "active") setActiveHasMore(false);
        else if (status === "draft") setDraftHasMore(false);
        else if (status === "archived") setArchivedHasMore(false);
        else if (status === "unprocessed") setUnprocessedHasMore(false);
        else if (status === "closed") setClosedHasMore(false);
        return;
      }
      dispatch(
        fetchJobPostList({
          status: status,
          page: Math.round(jobsLength / 20) + 1,
        }),
      );

      // setLoading("");
    } catch (error) {
      console.error(error);
      setLoading("");
    }
  };

  const jobSelection = (id) => {
    if (selectedJobs.includes(id)) {
      setSelectedJobs((prev) => prev.filter((jobId) => jobId != id));
      return;
    }
    setSelectedJobs((prev) => [...prev, id]);
  };

  const changeStatus = async (status) => {
    setJobs([]);
    setUnprocessedJobs([]);
    setDraftJobs([]);
    setClosedJobs([]);
    setArchivedJobs([]);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_JOB_STATUS,
        1,
        {
          jobIds: selectedJobs,
          status: status,
        },
      );
      if (json.data) {
        setReplace(true);
        setSelectedJobs([]);
        setOpenMoveMenu(false);
        dispatch(
          fetchJobPostList({
            status: jobType,
            page: 1,
          }),
        );
        setReload((prev) => prev + 1);
        toast.success(
          status == "deleted"
            ? "Job(s) is successfully deleted"
            : status == "archived"
              ? "Job(s) now successfully moved to archive"
              : status == "closed"
                ? "Job(s) now successfully closed"
                : status == "active"
                  ? "Job(s) now successfully actively published"
                  : "Job(s) now made private and moved to drafts",
        );
        selectJob(jobList[0]?.id, status);
      }
    } catch (error) {
      setOpenMoveMenu(false);
      setReload((prev) => prev + 1);
      console.error(error);
    }
  };

  return (
    <div className="mt-4 h-[calc(100vh - 104px)] grow flex flex-col">
      <ul
        id="menu-list"
        className={`relative block w-full left-0 divide-y grow`}
      >
        {barBelowItems?.map((item, i) => (
          <>
            <li
              style={{ bottom: 42 * (4 - i) - 21 }}
              className={`block sticky border-gray-400 bg-gray-100`}
              onClick={() => {
                setSelectedJobs([]);
                if (jobType === String(item?.name).toLowerCase()) {
                  setJobType(null);
                } else {
                  setJobType(String(item?.name).toLowerCase());
                  getActiveJobsbyPage(String(item?.name).toLowerCase());
                }
              }}
            >
              <button
                className={`py-[4px] px-[8px] min-h-[42px]  w-full flex items-center text-left justify-between`}
              >
                <span className="flex items-center gap-2">
                  <span
                    className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                  >
                    {item?.name}
                  </span>
                  <span
                    className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                  >
                    {"(" + item.count + ")"}
                  </span>
                </span>

                <span
                  className={
                    jobType === String(item?.name).toLowerCase()
                      ? "-scale-y-100"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.02346 8.70379L7.9348 10.8039L9.10185 12.0927C9.59606 12.6358 10.3999 12.6358 10.8941 12.0927L13.9784 8.7038C14.3833 8.25891 14.0916 7.5 13.5259 7.5L10.1855 7.5L6.47599 7.5C5.90438 7.5 5.61857 8.25891 6.02346 8.70379Z"
                      fill="#8D98B1"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <div
              className={`transition-all duration-300 ${
                jobType === String(item?.name).toLowerCase()
                  ? selectedJobs?.length > 0
                    ? "h-auto max-h-[calc(100vh-320px)]"
                    : "h-auto max-h-[calc(100vh-260px)]"
                  : "h-0"
              } scrollbar-hide overflow-y-scroll divide-y`}
            >
              {jobType === String(item?.name).toLowerCase() &&
              item?.list?.length < 10 &&
              loading == String(item?.name).toLowerCase() ? (
                [0, 0].map(() => (
                  <li
                    className={`grid grid-rows-2 grid-cols-[40px_1fr] py-[6px] mb-2`}
                  >
                    <span className="row-span-2 w-8 h-8">
                      <Skeleton width={"32px"} height={"32px"} />
                    </span>

                    <span className="w-full h-3">
                      <Skeleton width={"200px"} height={10} />
                    </span>
                    <span className="w-full h-3">
                      <Skeleton width={"100px"} height={10} />
                    </span>
                  </li>
                ))
              ) : item?.list?.length == 0 ? (
                <h2 className="text-base text-center mt-2">No jobs found</h2>
              ) : (
                <>
                  {item?.list?.map((job, i) => (
                    <li
                      key={`job${i}`}
                      className={`flex items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2  border-gray-300 group gap-2 ${
                        params?.jobId === job?.id ? "bg-[#F2F4F8]" : ""
                      }`}
                      onClick={() =>
                        selectJob(job?.id, String(item?.name).toLowerCase())
                      }
                    >
                      <input
                        id="moveSelect"
                        name="moveSelect"
                        type="checkbox"
                        value={job?.id}
                        checked={[...selectedJobs]?.includes(job?.id)}
                        onChange={(e) => {
                          e.preventDefault();
                          jobSelection(job?.id);
                        }}
                        className={`w-4 h-4 group-hover:!block ${
                          [...selectedJobs]?.includes(job?.id)
                            ? "!block"
                            : "hidden"
                        }`}
                      />
                      <div className="flex flex-col w-[200px] grow">
                        <span
                          className={`inline-block text-sm not-italic  text-gray-800 truncate ${
                            params?.jobId === job?.id
                              ? "font-semibold"
                              : "font-medium"
                          }`}
                          title={job?.position}
                        >
                          {job?.position}
                        </span>
                        <span className=" items-center gap-2 justify-start">
                          {/* {job?.company?.name && (
                              <span
                                className={`inline-block text-sm text-[#5F6989] truncate  ${
                                  params?.jobId === job?.id
                                    ? "font-medium"
                                    : "font-normal"
                                }`}
                              >
                                {job?.company?.name}
                              </span>
                            )} */}
                          <a
                            onClick={() =>
                              selectJob(
                                job?.id,
                                String(item?.name).toLowerCase(),
                                "pipeline",
                              )
                            }
                            className={`inline-block text-sm text-[#5F6989] truncate`}
                            data-tooltip-id="candidateListTooltip"
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                              <div>
                                {job?.stages?.map(
                                  (stage, ix) =>
                                    stage.value != 0 && (
                                      <span
                                        className="w-full block"
                                        key={i + "_" + ix}
                                      >
                                        {stage.name}- <b>{stage.value}</b>
                                      </span>
                                    ),
                                )}
                              </div>,
                            )}
                          >
                            {`Total candidates :  ${job?.candidates}`}
                          </a>
                        </span>
                      </div>

                      <Tooltip
                        id="candidateListTooltip"
                        noArrow={false}
                        style={{
                          zIndex: 9999,
                          background: "#5F6989",
                          fontSize: "12px",
                          padding: "4px 8px",
                          fontWeight: "400",
                          color: "white",
                        }}
                        place="bottom"
                      />
                    </li>
                  ))}

                  {item?.hasMore &&
                    item?.list?.length % 10 == 0 &&
                    (item?.loading ? (
                      <div className="flex items-center justify-center">
                        <ScaleLoader color="#1369e9" loading={true} />
                      </div>
                    ) : (
                      <button
                        className="px-[16px] mt-[20px] mb-[20px] flex items-center"
                        onClick={() => {
                          setReplace(false);
                          getActiveJobsbyPage(String(item?.name).toLowerCase());
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5.41797 7.5L10.0013 12.0704L14.5846 7.5"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-gray-800 ml-[8px] leading-[28px] text-[14px] not-italic font-normal">
                          See more
                        </span>
                      </button>
                    ))}
                </>
              )}
            </div>
          </>
        ))}
      </ul>
      {selectedJobs?.length > 0 && (
        <>
          <button
            onClick={() => setOpenMoveMenu((prev) => !prev)}
            className="btn-primary btn-sm h-10 relative w-full z-20 right-0"
          >
            Move to
          </button>
          {openMoveMenu && (
            <ul className="absolute right-4 rounded-lg w-[240px] h-auto bg-white shadow-md bottom-[66px] border border-gray-300 z-20 list-none">
              {jobActions?.map((action, idx) => (
                <li
                  className={`px-[8px] py-[7px] text-gray-800 text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] ${
                    action?.name === "Delete job"
                      ? "text-[#FF475D] text-[14px] border-t border-t-[#EFF0F2]"
                      : ""
                  }`}
                  key={idx}
                  onClick={() => changeStatus(action.tag)}
                >
                  {action.name}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
}

export default JobListSidebar;
