import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import HamburgerSvg from "../../assets/recruiter/DragIcon.svg";

// import Modal from "../../candidate/profile/Modal";

const core = require("../../../lib/core");

export default function HiringPipeline({ classTxt, skills, setSkills, setShowModal }) {
  const [reload, setReload] = useState(0);



  function showKeySkills(id) {
    setReload(reload + 1);
    if (id) {
      var data = skills?.filter((d) => d.label == id)[0];
    }
  }

  function reorder(array, from, to) {
    // Remove item from array
    let [removed] = array.splice(from, 1);
    // Add item to desired position in array
    array.splice(to, 0, removed);
  }

  function dragEnd(result) {
    const { source, destination, type } = result;
    // If the candidate is dropped outside of a Droppable, return
    if (!destination) {
      toast("Dragged item out of range");
      return;
    }
    // If the candidate is dropped in the same Droppable and position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      toast("Drag item further, it is in the same position");
      return;
    }
    if (type == "criteria") {
      if (
        destination.index != 0 &&
        destination.index != skills?.length - 1 &&
        destination.index != skills?.length - 2
      ) {
        reorder(skills, source.index, destination.index);
      } else {
        toast.error("Cannot move this stage to this  position");
      }
    }
    if (type == "skill") {
      let arr_idx = +source.droppableId.split("_")[0];
      reorder(skills[arr_idx].subStage, source.index, destination.index);
    }
  }

  const [reloads, setReloads] = useState(0);

  return (
    // analytics != null && (
    <section
      className={classTxt}
      id="Hiring-pipeline-section assessment-invitees"
    >
      <div className="col-xs-12 data-section no-padding">
        <div className="col-xs-12 no-padding input-data-section">
          <div className="col-xs-12 no-padding" id="Hiring-pipeline-section">
            <DragDropContext onDragEnd={dragEnd}>
              <Droppable
                droppableId={"criteria_drop"}
                type="criteria"
                className="col-xs-12 no-padding"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="col-xs-12 no-padding"
                  >
                    {skills?.map((skill, it) => (
                      <Draggable
                        draggableId={
                          String(skill.stage).split(" ").join("_") + "_" + it
                        }
                        index={it}
                        key={skill?.stage}
                        // isDragDisabled={true}
                        isDragDisabled={it === 0 || skill?.stage === "Hired" || skill?.stage === "Rejected"}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="section-skills col-xs-12 no-padding"
                          >
                            <div className="col-xs-12 section-header no-padding">
                              <div className="col-xs-10 no-padding label-input">
                                {" "}
                                <span
                                  {...provided.dragHandleProps}
                                  data-tooltip={`${it == 0 ||
                                    skill?.stage === "Hired" ||
                                    skill?.stage === "Rejected"
                                    ? "Not dragable"
                                    : "Hold and drag"
                                    }`}
                                  className="icon drag"
                                >
                                  <img
                                    src={HamburgerSvg}
                                    className="icon img-responsive"
                                    alt="dragger"
                                  />
                                </span>
                                <div className="text-sextion">
                                  <span className="text">{skill.stage}</span>
                                  <span className="text-detail">
                                    {skill.text}
                                  </span>
                                </div>
                              </div>
                              {!(
                                it == 0 ||
                                skill?.stage === "Hired" ||
                                skill?.stage === "Rejected"
                              ) && (
                                  <div
                                    className="icon cursor-pointer"
                                    onClick={() => {
                                      setSkills(
                                        skills.filter(
                                          (d) => d.stage != skill?.stage
                                        )
                                      );
                                      // reload.func(reload.var + 1);
                                      setReloads(reloads + 1);
                                    }}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g id="left icon">
                                        <path
                                          id="Vector"
                                          d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                          stroke="#FF475D"
                                          strokeLinecap="round"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                )}
                            </div>
                            <div className="col-xs-12 no-padding">
                              <Droppable
                                droppableId={it + "_drop"}
                                type="skill"
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list-section col-xs-12 no-padding"
                                  >
                                    {skill?.subStage?.map((ks, i) => (
                                      <Draggable
                                        draggableId={it + "_" + i}
                                        index={i}
                                        key={it + "_" + i}
                                      // isDragDisabled={true}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="col-xs-12 no-padding line"
                                          >
                                            <div
                                              className="col-xs-12 no-padding name"
                                              {...provided.dragHandleProps}
                                            >
                                              <img src={HamburgerSvg} alt="" />
                                              <input
                                                type="text"
                                                required
                                                value={ks}
                                                onKeyDown={e=>{
                                                  if(e.key==="Enter"){
                                                    e.preventDefault()
                                                  }
                                                  
                                              }     
                                              }
                                                className="col-xs-12 no-padding"
                                                placeholder="Type here"
                                                onChange={(e) => {
                                                  setSkills((prevSkills) => {
                                                    const updatedSkills = [...prevSkills];
                                                    const updatedKeySkills = [...updatedSkills[it].subStage]; // Make a copy of subStage
                                                    updatedKeySkills[i] = e.target.value; // Modify the copy
                                                    updatedSkills[it] = {
                                                      ...updatedSkills[it],
                                                      subStage: updatedKeySkills, // Update subStage in the copy
                                                    };
                                                    return updatedSkills; // Update the state with the copy
                                                  });
                                                }}
                                                
                                              />
                                            </div>

                                            <button
                                              type="button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                skill.subStage =
                                                  skill?.subStage.filter(
                                                    (d) => d != ks
                                                  );
                                                // reload.func(reload.var + 1);
                                                setReloads(reloads + 1);
                                              }}
                                            >
                                              {" "}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g id="left icon">
                                                  <path
                                                    id="Vector"
                                                    d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                                    stroke="#FF475D"
                                                    strokeLinecap="round"
                                                  />
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                            {!(
                              skill.stage === "New Applicant" ||
                              skill.stage == "Hired" ||
                              skill.stage == "Rejected"
                            ) && (
                                <button
                                  className="add-skills-button"
                                  type="button"
                                  onClick={() => {
                                    setSkills((prevSkills) => {
                                      const updatedSkills = prevSkills.map((prevSkill, index) => {
                                        if (index === it) {
                                          return {
                                            ...prevSkill,
                                            subStage: [...prevSkill.subStage, ""],
                                          };
                                        }
                                        return prevSkill;
                                      });
                                      return updatedSkills;
                                    });
                                    setReloads(reloads + 1);
                                  }}
                                >
                                  + Add
                                </button>
                              )}
                          </div>
                        )}

                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <button
            className="add-skills-button mt-[10px]"
            type="button" onClick={()=>setShowModal(true)}
          >
            <span className="text-[#1369E9] text-[16px] font-normal not-italic leading-[26px]">+ Add new stage</span>

          </button>
        </div>

      </div>

    </section>
    // )
  );
}
