import React from "react";

function SelectBox({
  value,
  placeholder,
  required,
  defaultValue,
  onChange,
  type,
  width,
  size,
  options,
}) {
  return (
    <div>
      <select
        className={`w-[${width ? width : "100%"}]  h-[${size === "medium" ? "40px" : size === "small" ? "32px" : "48px"}]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
        type={type ?? "text"}
        name="title"
        required={required ?? false}
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue}
      >
        <option value={""} disabled>
          {"Select "}
        </option>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectBox;
