import React, { useEffect, useMemo, useState } from "react";
import EducationLineGantt from "./EducationLineGantt";
import ExperienceGantt from "./ExperienceGantt";
import MultiLineChart from "../../../../analytics/MultipleLineChart";
import MatchPercentChart from "./MatchPercentChart";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ApplicantAccordionV2 = ({ data, loading, educationMatchWeight }) => {
  useEffect(() => {}, [data]);
  const [activeTab, setActiveTab] = useState("skill");
  const openResumeLink = () => {
    window.open(data?.resumeLink, "_blank");
  };
  const maxYears =
    data?.work_experience?.length > 0
      ? data?.work_experience?.map((data) => data?.end).filter((d) => d != null)
      : [];
  const totalYrs =
    data?.work_experience?.length > 0
      ? new Date(maxYears.sort()[maxYears?.length - 1]).getFullYear() -
        new Date(
          data?.work_experience?.map((data) => data?.start).sort()[0],
        ).getFullYear()
      : 0;

  const skillList = [];
  data?.graph?.forEach((iter) => {
    skillList.push(...iter?.candidateSkill);
  });
  // console.log(skillList);

  const tab = useMemo(() => {
    if (activeTab === "skill") {
      return (
        <>
          {skillList?.length > 0 &&
            skillList.map((data, key) => (
              <>
                <span
                  key={key}
                  className="text-[#252E4B] text-[13px] not-italic font-normal inline-block py-[0.25rem] px-[1rem] mr-2 mb-[0.5rem]"
                >
                  {data?.value === 0 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="red"
                      className="w-[18px] h-[18px] inline-block mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : data?.value < 5 ? (
                    <svg
                      className="w-4 h-4 inline-block mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.81596 1.43012C5.3326 0.523294 6.67001 0.523292 7.18664 1.43012L11.662 9.28557C12.1642 10.1669 11.5106 11.25 10.4767 11.25H1.5259C0.491986 11.25 -0.161554 10.1669 0.34056 9.28557L4.81596 1.43012ZM6.5846 8.91667C6.5846 9.23883 6.32343 9.5 6.00127 9.5C5.6791 9.5 5.41793 9.23883 5.41793 8.91667C5.41793 8.5945 5.6791 8.33333 6.00127 8.33333C6.32343 8.33333 6.5846 8.5945 6.5846 8.91667ZM6.43877 4.25C6.43877 4.00838 6.24289 3.8125 6.00127 3.8125C5.75964 3.8125 5.56377 4.00838 5.56377 4.25V7.16667C5.56377 7.40829 5.75964 7.60417 6.00127 7.60417C6.24289 7.60417 6.43877 7.40829 6.43877 7.16667V4.25Z"
                        fill="#FF9500"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4 inline-block mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
                        fill="#00AC85"
                      />
                      <path
                        d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}

                  {data?.skill}
                </span>
              </>
            ))}
          <div className="w-auto max-w-[300px] relative float-right mt-2 bg-white shadow border border-gray-300 rounded-lg p-4 grid grid-cols-[16px_1fr] items-center gap-x-3 gap-y-1">
            <span className="inline-block w-2.5 h-2.5 rounded-full border-2 border-white bg-[#ff7324] outline outline-1 outline-[#ff7324]" />
            <span className="inline-block text-sm text-gray-800 font-normal not-italic">
              Job requirement
            </span>
            <span className="inline-block w-2.5 h-2.5 rounded-full border-2 border-white bg-[#1369E9] outline outline-1 outline-[#1369E9]" />
            <span className="inline-block text-sm text-gray-800 font-normal not-italic">
              Candidate skill
            </span>
          </div>
          <div className="w-full max-w-full overflow-x-auto scrollbar-candidate">
            <MultiLineChart
              data={data?.graph}
              chartHeight={300}
              color={"#1369E9"}
            />
          </div>
        </>
      );
    }
    if (activeTab === "education") {
      return (
        <>
          {data?.education?.length > 0
            ? data?.education?.map(
                (data, key) =>
                  (data?.Degree || data?.Institute) && (
                    <span
                      key={key}
                      className="text-gray-800 text-sm not-italic font-normal inline-block mb-2 mr-3 px-2 border border-gray-300 rounded-lg"
                    >
                      <svg
                        style={{
                          display: "inline-block",
                          marginRight: "0.5rem",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
                          fill="#00AC85"
                        />
                        <path
                          d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {data?.Degree +
                        (data?.Institute && " from ") +
                        data?.Institute}
                    </span>
                  ),
              )
            : null}
          {/* <EducationLineGantt
            className="w-full"
            colors={["#FF7324", "#00AC85", "#80BB01", "#1369E9"]}
            dataList={data?.education}
            barGap={2}
            dualAxis={true}
          /> */}
        </>
      );
    }
    if (activeTab === "experience") {
      return (
        <>
          {totalYrs > 0 && (
            <p className="text-gray-800 text-base not-italic font-semibold inline-block mb-2 mr-3 px-2 border border-gray-300 rounded-lg">
              <svg
                className="inline-block mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
                  fill="#00AC85"
                />
                <path
                  d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {"Total Experience : " + totalYrs + " years"}
            </p>
          )}
          <br />
          {data?.work_experience?.length > 0 && (
            <ExperienceGantt
              colors={["#FF7324", "#00AC85", "#80BB01", "#1369E9"]}
              dataList={data?.work_experience}
            />
          )}
        </>
      );
    }
  }, [activeTab, data?.graph, data]);

  // data?.graph is added in the dependency, because graph data was not visible when we first loading. it can one soln.

  return !loading ? (
    <div
      className="flex flex-wrap relative p-[1.5rem] h-auto bg-[#F9FAFB] gap-4"
      id="accordion"
    >
      <div className="w-full flex items-center justify-between">
        <h4 className=" text-md font-medium mb-2 not-italic">
          Profile Highlights
        </h4>
        {data?.resumeLink && data?.resumeLink !== null && (
          <button
            onClick={openResumeLink}
            className="border-button rounded-lg border h-[26px] border-gray-500 bg-white py-1 px-2.5 ml-3 flex items-center justify-center"
          >
            <span className="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                  fill="#5F6989"
                />
                <path
                  d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                  fill="#5F6989"
                />
              </svg>
            </span>
            Resume
          </button>
        )}
      </div>
      {data?.matchProfile?.matchSummary && (
        <span className="text-gray-800 w-full text-sm not-italic font-normal inline-block py-[0.25rem] px-[1rem] mr-2 mb-[0.5rem] border border-[#E1E1EE] bg-[#FFF] rounded-[1rem] ">
          <svg
            className="inline-block mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
              fill="#00AC85"
            />
            <path
              d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {data?.matchProfile?.matchSummary}
        </span>
      )}
      {data?.matchProfile?.location_match && (
        <span className="text-gray-800  text-sm not-italic font-normal inline-block py-[0.25rem] px-[1rem] mr-2 mb-[0.5rem] border border-[#E1E1EE] bg-[#FFF] rounded-[1rem] ">
          <svg
            className="inline-block mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
              fill="#00AC85"
            />
            <path
              d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {`Location : ${data?.matchProfile?.location_match}`}
        </span>
      )}
      <div className="w-full flex items-stretch justify-between border-b border-b-gray-300">
        <button
          onClick={() => setActiveTab("skill")}
          style={
            activeTab == "skill"
              ? { borderColor: "#1369E9", background: "#E8F0FD" }
              : {}
          }
          className="text-base font-semibold text-gray-800 hover:text-blue-800 hover:bg-gray-300 border-b-2 border-b-transparent px-5 py-2"
        >
          <p>Skill</p>{" "}
          <MatchPercentChart value={data?.matchProfile?.skillsMatch * 100} />
        </button>
        <button
          onClick={() => setActiveTab("experience")}
          style={
            activeTab == "experience"
              ? { borderColor: "#1369E9", background: "#E8F0FD" }
              : {}
          }
          className="text-base font-semibold text-gray-800 hover:text-blue-800 hover:bg-gray-300 border-b-2 border-b-transparent px-5 py-2"
        >
          <p>Experience</p>
          <MatchPercentChart
            value={data?.matchProfile?.experienceMatch * 100}
          />
        </button>

        <button
          onClick={() => setActiveTab("education")}
          data-tooltip-id={educationMatchWeight === 0 ? "my-tooltip-7" : ""}
          data-tooltip-content={
            "Education not factored into overall score calculation based on your preferences."
          }
          style={
            activeTab == "education"
              ? { borderColor: "#1369E9", background: "#E8F0FD" }
              : {}
          }
          className="text-base font-semibold text-gray-800 hover:text-blue-800 hover:bg-gray-300 border-b-2 border-b-transparent px-5 py-2 max-w-[25%]"
        >
          <p>Education</p>
          {educationMatchWeight === 0 ? (
            <MatchPercentChart />
          ) : (
            <MatchPercentChart
              value={data?.matchProfile?.educationMatch * 100}
            />
          )}
        </button>
        <ReactTooltip
          id="my-tooltip-7"
          style={{
            zIndex: 9999,
            fontSize: "14px",
            borderRadius: "8px",
            boxShadow: "0px 4px 9px 0px rgba(62, 72, 84, 0.26)",
            fontWeight: "500",
            backgroundColor: "#FFFFFF",
            color: "#252E4B",
            padding: "8px 16px",
            fontWeight: "400",
          }}
          place="top"
        />
        <button
          // onClick={() => setActiveTab("industry")}
          style={
            activeTab == "industry"
              ? { borderColor: "#1369E9", background: "#E8F0FD" }
              : {}
          }
          className="text-base font-semibold text-gray-800 cursor-auto
           border-b-2 border-b-transparent px-5 py-2"
        >
          <p>Industry</p>
          <MatchPercentChart
            value={data?.matchProfile?.jobPositionMatch * 100}
          />
          {/* <MatchScore data={data?.jobPositionMatch} showPercent={true} /> */}
        </button>
      </div>
      <div className="w-full px-4 pt-5 pb-4 rounded-lg bg-white shadow border border-gray-300 relative">
        {tab}
      </div>
    </div>
  ) : (
    <div
      className="flex flex-wrap relative p-[1.5rem] h-auto bg-[#F9FAFB] gap-4"
      id="accordion"
    >
      <h4 className="w-full text-md font-medium mb-2 not-italic">
        Profile Highlights
      </h4>
      <SkeletonTheme highlightColor="#ffffff">
        <span className="min-w-full">
          <Skeleton className="mb-4" height={30} />
        </span>
        <div className="flex justify-between w-full gap-10">
          <div className="w-1/4">
            <Skeleton className="w-full" height={50} />
          </div>
          <div className="w-1/4">
            <Skeleton className="w-full" height={50} />
          </div>
          <div className="w-1/4">
            <Skeleton className="w-full" height={50} />
          </div>
          <div className="w-1/4">
            <Skeleton className="w-full" height={50} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default ApplicantAccordionV2;
