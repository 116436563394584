import { useState, useEffect } from "react";
import SavedJobPost from "../../../components/candidate/dashboard/SavedJobPost";
import Header from "../../../components/all/header/Header";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import HeaderNew from "../../../components/all/header/HeaderNew";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
const core = require("../../../lib/core");

function SavedJobs() {
  const [saveJobs, setSaveJobs] = useState([]);
  const headerList = ["Company", "Match with job", "Location", "Saved on", ""];
  useEffect(() => {
    fetchSavedJobs();
  }, []);


  const fetchSavedJobs = async () => {
    const jobList = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_SAVED_JOBS,
      1,
      {}
    );
    console.log(jobList);
    if (jobList.data) {
      setSaveJobs(jobList.data.jobs);
      console.log(jobList.data)
    } else {
      console.log(jobList.error);
    }
  };


  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="job_details_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <CandidateSidebar active="Saved" open={false} />
        <div className="w-full">
          <HeaderNew />
          <JobContent
          saveJobs={saveJobs}
          fetchSavedJobs={fetchSavedJobs}
          headerList={headerList}

          />
        </div>
      </div>
    </div>
  )

  

}

export default SavedJobs;


const JobContent = ({
  fetchSavedJobs,
  saveJobs,
  headerList,
  ...props
}) => {
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"

    >
      <span className="font-semibold text-[28px] not-italic text-[#16182F]">
        Saved jobs
      </span>
     
    <div className="page candidate-pages mt-[20px]" id="saved-jobs">
      <main className="main-content">
        <div id="job-list" className="col-xs-12 main-section no-padding">
          <div className="col-xs-12 header-row">
            {headerList.map((header, i) => (
              <h4 className="header col-md-2 no-padding" key={i}>
                {header}
              </h4>
            ))}
          </div>
          {saveJobs.map((job, i) => (
            <SavedJobPost key={i} job={job} reload_function={fetchSavedJobs}/>
          ))}
        </div>
      </main>
    </div>   
    </main>
  );
};