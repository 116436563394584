import React from "react";
import CopyImg from "../../assets/copy.png";
import GHLogo from "../../assets/GREENHOUSE_WORDMARK_GREEN.png";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import Modal from "react-modal";
import {
  getWebhooksAtsTokenService,
  validateAtsTokenService,
} from "../../../redux/services";
import { useSelector } from "react-redux";
import Modal from "../../modal/Modal";
import TabLayout from "../../../layouts/TabLayout";
import { ScaleLoader } from "react-spinners";

const core = require("../../../lib/core");

export default function GreenhouseModal({ showModal, setShowModal, id }) {
  const [atsToken, setAtsToken] = useState("");
  const [buttonColor, setButtonColor] = useState("blue");
  const [endpoints, setEndpoints] = useState("");

  const { isTokenValid, atsTokenData, error, loading } = useSelector(
    (state) => {
      return {
        isTokenValid: state?.atsIntegrationSlice?.isTokenValid,
        atsTokenData: state?.getWebhooksAtsIntegrationSlice?.atsToken,
        error: state?.getWebhooksAtsIntegrationSlice,
        loading: state?.getWebhooksAtsIntegrationSlice?.isLoading,
      };
    },
  );

  function myFunction(param) {
    // Get the text field
    var copyText = document.getElementById(param);

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    alert("Copied the text: " + copyText.value);
  }

  const dispatch = useDispatch();
  const validateAtsToken = () => {
    if (atsToken) {
      dispatch(
        validateAtsTokenService({
          greenhouseToken: atsToken,
        }),
      );
    }
  };

  useEffect(() => {
    if (window.location.hostname.includes("localhost")) {
      setEndpoints("https://dev.api.sproutsai.com/webhooks/ats/" + id);
    } else if (window.location.hostname.includes("test.")) {
      setEndpoints("https://dev.api.sproutsai.com/webhooks/ats/" + id);
    } else if (window.location.hostname.includes("beta.")) {
      setEndpoints("https://api.sproutsai.com/webhooks/ats/" + id);
    } else if (window.location.hostname.includes("natera")) {
      setEndpoints("https://natera.api.sproutsai.com/webhooks/ats/" + id);
    } else {
      setEndpoints("https://api.sproutsai.com/webhooks/ats/" + id);
    }

    dispatch(getWebhooksAtsTokenService());
  }, [id]);

  useEffect(() => {
    if (atsTokenData?.data) {
      setAtsToken(atsTokenData?.data?.greenhouseToken);
    }
  }, [atsTokenData, error]);

  useEffect(() => {
    if (isTokenValid?.code === 200) {
      setButtonColor("green");
    } else if (isTokenValid?.code === 500) {
      setButtonColor("red");
    }
  }, [atsTokenData, error, isTokenValid]);

  useEffect(() => {
    if (isTokenValid?.data?.message === "Token updated successfully") {
      setButtonColor("green");
    } else if (isTokenValid?.error) {
      setButtonColor("red");
    }
  }, [isTokenValid]);

  const webhooks = [
    { name: "Job post created - SproutsAI", when: "Job post created" },
    { name: "Job Created - SproutsAI", when: "Job Created" },
    { name: "Job Post Updated - SproutsAI", when: "Job Post Updated" },
    { name: "Job Updated - SproutsAI", when: "Job  Updated" },
    { name: "Job Post Deleted - SproutsAI", when: "Job Post Deleted" },
    {
      name: "Create Candidate- SproutsAI",
      when: "Candidate has submitted application",
    },
    {
      name: "Candidate stage changed - SproutsAI",
      when: "Candidate has changed stage",
    },
    {
      name: "Unreject candidate - SproutsAI",
      when: "Candidate or prospect unrejected",
    },
    {
      name: "Reject candidate - SproutsAI",
      when: "Candidate or prospect rejected",
    },
  ];

  const modalDismiss = () => {
    setShowModal(false);
  };

  const btnList = [
    {
      name: "Add api",
      id: "Api",
    },
    {
      name: "Check status",
      id: "Status",
    },
  ];

  const [view, setView] = useState("Api");
  const [webhookStatus, setWebhookStatus] = useState({});

  async function get_webhook_status() {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_GREENHOUSE_WEBHOOK_STATUS,
      1,
    );
    if (json?.data) {
      setWebhookStatus(json.data);
    } else {
    }
  }

  useEffect(() => {
    get_webhook_status();
  }, []);

  return (
    <Modal
      modalDismiss={modalDismiss}
      className={"md:max-w-[75%] scrollbar-hide"}
      noBtns={true}
    >
      <div className="rounded-md z-[10000] ">
        <div className="">
          <div className="bg-white px-8 pt-8">
            <img
              className="w-48 mx-auto object-cover"
              src={GHLogo}
              alt="Greenhouse integration steps"
            />
            <div className="mt-[20px] text-gray-800">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Add Harvest API</h2>
                <TabLayout
                  btnList={btnList}
                  className="my-4"
                  state={view}
                  setState={setView}
                />
              </div>
              {view === "Api" && (
                <>
                  <p className="mt-[8px] text-gray-600">
                    To establish a secure connection between SproutsAI and
                    Greenhouse, you'll need to create an API key. Here's how:
                  </p>
                  <ol className="list-decimal mt-[8px] ml-6 text-gray-600">
                    <li className="mb-1">
                      In Greenhouse, navigate to{" "}
                      <span className="font-medium">
                        Configure &gt; Dev Center &gt; API Credentials
                        Management
                      </span>{" "}
                      and click on{" "}
                      <span className="font-medium">Create New API Key</span>.
                    </li>
                  </ol>
                </>
              )}
            </div>

            {view === "Api" ? (
              <>
                <div className="mt-6 flex items-center">
                  <input
                    value={atsToken}
                    onChange={(e) => setAtsToken(e.target.value)}
                    type="text"
                    id="rounded-email"
                    className=" rounded-lg  flex-1 appearance-none border border-gray-500 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-600 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 "
                    placeholder="Please enter Harvest API Token here"
                  />
                  <button
                    onClick={validateAtsToken}
                    type="button"
                    className={`ml-2 py-2 px-4 rounded-lg text-white text-center text-base focus:outline-none ${
                      buttonColor === "blue"
                        ? "bg-[#1369E9] hover:bg-blue-600 focus:ring-blue-200 focus:ring-offset-blue-200"
                        : buttonColor === "red"
                          ? "bg-rose-500 hover:bg-rose-600 focus:ring-rose-200 focus:ring-offset-rose-200"
                          : "bg-emerald-500 hover:bg-emerald-600 focus:ring-emerald-200 focus:ring-offset-emerald-200"
                    }`}
                  >
                    {/* {loading ? (
                      <ScaleLoader height={14} color="#ffffff" loading={true} />
                    ) : (
                      <> */}
                    {buttonColor === "red"
                      ? "Failed"
                      : buttonColor === "green"
                        ? "Validated"
                        : "Validate"}
                    {/* </>
                    )}  */}
                  </button>
                </div>
                {webhooks.map((webhook, index) => (
                  <div key={index} className="mt-6 text-gray-700">
                    <div className="font-medium text-base mb-2 text-gray-800">
                      {`Webhook ${index + 1}: ${webhook.name}`}
                    </div>
                    <ul>
                      <li>
                        Name: <span>{webhook.name}</span>
                      </li>
                      <li>
                        When: <span>{webhook.when}</span>
                      </li>
                      <li className="flex align-middle">
                        <p className="col-span-1">Endpoints: </p>
                        <input
                          id={`myInput${index}`}
                          className="col-input-4 ml-2 w-10/12 disabled"
                          value={endpoints}
                        />
                        <img
                          onClick={() => myFunction(`myInput${index}`)}
                          className="w-4 h-4 ml-2 mt-1 cursor-pointer col-span-1"
                          src={CopyImg}
                        />
                      </li>
                    </ul>
                  </div>
                ))}
              </>
            ) : (
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-[#F2F4F8] text-[#252E4B]  text-[12px]">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left  font-medium   tracking-wider"
                            >
                              Event
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left  font-medium   tracking-wider"
                            >
                              Last sync
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {Object?.entries(webhookStatus)?.map(
                            ([key, value]) => (
                              <tr key={key}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {key?.replace(/_/g, " ")}
                                </td>
                                <td
                                  className={`px-6 py-4 whitespace-nowrap text-sm ${value !== null ? "text-gray-600 " : "text-[red]"} `}
                                >
                                  {value !== null ? value : "false"}
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="">
            {/* <button
              onClick={() => setShowModal(false)}
              type="button"
              className="px-4 py-2 border-2 border-gray   rounded-lg text-gray-600 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
            >
              Close
            </button> */}
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className=" absolute top-[5px] right-[5px] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M8.75195 8.75L21.252 21.25"
                  stroke="#252E4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.75 21.25L21.25 8.75"
                  stroke="#252E4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
