import { gapi } from "gapi-script";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginPageLogo from "../../components/assets/candidate/SproutsaiLogo.svg";
import LoginPageImg from "../../components/assets/candidate/LogInPageImg.png";
import LandingText from "../../components/auth/LandingText";
import { ScaleLoader } from "react-spinners";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useEffect } from "react";
import GoogleLogin from "react-google-login";
const core = require("../../lib/core");

export default function Login() {
  const { setUser } = useContext(core.UserContext);
  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginText, setLoginText] = useState("Sign in");
  const [passwordShown, setPasswordShown] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    // core.hardRefresh();
    // // Google Auth
    // const initGoogleClient = () => {
    //   gapi.client.init({
    //     clientId: core.googleClientId,
    //     scope: "",
    //   });
    // };
    // gapi.load("client:auth2", initGoogleClient);
  }, []);

  async function handleSignIn(event) {
    setLoginText(<ScaleLoader height={14} color="#ffffff" loading={true} />);
    event.preventDefault();
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_AUTH,
      0,
      {
        username: username,
        password: password,
      },
    );
    // console.log(json);
    if (json?.data) {
      core.setData(core.data.ACCESS_TOKEN, json.data.token);
      core.setData(core.data.UID, json.data.user);
      core.setData(core.data?.MY_RESOURCES, json.data?.resources);
      core.setData(core.data?.ORGANIZATION, json.data?.organization);
      core.setData(core.data?.MY_ROLE, json.data?.roles);
      setLoginText("Next");
      if (loc?.state !== null) {
        nav(loc?.state + (loc?.search !== null ? loc?.search : ""), {
          replace: true,
        });
      } else {
        nav("/", { replace: true });
      }
      window.location.reload();
    } else {
      setErrorMessage(json?.message);
      setLoginText("Sign in");
    }
  }

  // Google Auth Success
  const onSuccess = async (res) => {
    const gid = res?.googleId;
    const email = res?.profileObj?.email;
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_GOOGLE_AUTH,
      0,
      {
        email: email,
        gid: gid,
      },
    );
    if (json.data) {
      core.setData(core.data.ACCESS_TOKEN, json.data.token);
      core.setData(core.data.UID, json.data.user);
      // setLoading(false);
      nav("/");
      window.location.reload();
    } else {
      // setLoading(false);
    }
  };

  // Google Auth Failure
  const onFailure = (err) => {
    console.error(err);
    setLoginText("Continue");
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile w_member_social email",
    onSuccess: (code) => {
      // console.log(code);
      getUserToken(code);
      setErrorMessage("");
    },
    onError: (error) => {
      console.error(error);
      if (error.error != "user_closed_popup")
        setErrorMessage(error?.errorMessage);
    },
  });

  const getUserToken = async (code) => {
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_LINKEDIN_AUTH,
      0,
      {
        redirect_uri: `${window.location.origin}/linkedin`,
        authorization_code: code,
      },
    );
    if (json?.data) {
      core.setData(core.data.ACCESS_TOKEN, json.data.token);
      core.setData(core.data.UID, json.data.user);
      setUser({
        id: json.data.user,
        account: json.data.account,
        stage: json.data.stage,
        email: json.data.email,
      });
      nav("/", { replace: true });
    }
  };

  return (
    <main className="w-screen md:h-screen min-h-screen h-full flex md:flex-row flex-col py-5 md:py-0 bg-defaultBg overflow-scroll scrollbar-hide">
      <div className="w-full md:w-7/12 h-auto md:h-full flex items-center justify-center flex-col">
        <LandingText />
        <img
          src={LoginPageImg}
          className="max-w-full h-auto -mt-16"
          alt="AI based decision engine"
        />
      </div>
      <div className="shadow-[0px_4px_32px_#043dab14] w-full md:w-5/12 h-auto md:h-full rounded-none md:rounded-l-lg md:bg-white bg-defaultBg px-4 md:px-0 mt-4 md:mt-0">
        <form
          autoComplete="false"
          onSubmit={handleSignIn}
          className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-4"
        >
          <img
            src={LoginPageLogo}
            className="max-w-full h-8 mb-5"
            alt="SproutsAI"
          />
          <h1 className="w-full text-black text-4xl font-semibold">
            Welcome back!
          </h1>
          <h4 className="w-full text-slate-500 text-base">
            Enter your details to sign in to your account
          </h4>

          <input
            type="email"
            placeholder="Enter Email"
            className="w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            value={username}
            required
            onChange={(event) => {
              setUserName(() => event.target.value);
            }}
          />
          <div className="w-full relative">
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Password"
              className="w-full border border-gray-400 px-4  py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              value={password}
              required
              onChange={(event) => setPassword(() => event.target.value)}
            />
            <span
              className="absolute right-4 top-4 cursor-pointer"
              onClick={() => setPasswordShown((prev) => !prev)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
              >
                <path
                  d="M7.01874 16.9839C4.86624 15.4555 3.1629 13.4675 2.2004 12.2122C1.93126 11.8657 1.78516 11.4394 1.78516 11.0006C1.78516 10.5619 1.93126 10.1356 2.2004 9.78904C3.94224 7.51521 8.12124 2.83337 13.0002 2.83337C15.1889 2.83337 17.2352 3.77487 18.9852 5.01971"
                  stroke={passwordShown ? "#33C7AC" : "#535353"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4853 8.53485C15.1613 8.20547 14.7753 7.94348 14.3496 7.76402C13.9238 7.58456 13.4668 7.49116 13.0048 7.48923C12.5427 7.48729 12.0849 7.57685 11.6577 7.75274C11.2304 7.92863 10.8422 8.18737 10.5155 8.51403C10.1888 8.8407 9.92994 9.22882 9.75395 9.65601C9.57796 10.0832 9.48829 10.541 9.49012 11.003C9.49195 11.4651 9.58523 11.9221 9.76459 12.3479C9.94396 12.7737 10.2058 13.1598 10.5352 13.4839M3.66699 20.3334L22.3337 1.66669M10.667 18.8214C11.4244 19.0468 12.2101 19.1631 13.0003 19.1667C17.8793 19.1667 22.0583 14.4849 23.8002 12.211C24.0692 11.8642 24.2152 11.4376 24.215 10.9987C24.2148 10.5597 24.0684 10.1333 23.799 9.78669C23.1868 8.98809 22.5301 8.22466 21.832 7.50002"
                  stroke={passwordShown ? "#33C7AC" : "#535353"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
          <a
            className="w-full text-sm text-right cursor-pointer"
            onClick={(e) => nav("/forgot-password")}
          >
            Forgot password?
          </a>
          {String(errorMessage)?.length > 0 && (
            <p className={`w-full flex text-red-900 text-sm`}>{errorMessage}</p>
          )}
          <button className="btn-primary btn-md h-12 min-w-[120px]">
            {loginText}
          </button>
          <div className="w-full relative flex justify-center">
            <hr className="w-full h-0 border-gray-600 my-4" />
            <span className="absolute px-3 py-1 bg-white top-0 text-slate-500 text-base">
              Or sign in with
            </span>
          </div>
          <span className="w-full flex gap-4 flex-row-reverse justify-evenly">
            <button
              type="button"
              className="w-auto btn-sec btn-lg cursor-pointer items-start"
              onClick={linkedInLogin}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-[#0077b5]"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
              LinkedIn
            </button>
            <GoogleLogin
              type="submit"
              clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="w-auto btn-sec btn-lg cursor-pointer flex-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                  Google
                </button>
              )}
              // buttonText="Sign in with Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              isSignedIn={false}
            />
          </span>
          <h4 className="text-gray-700 text-sm w-full mt-8">
            New to SproutsAI?
            <Link to="/register" className="text-blue-800 ml-2">
              Join now!
            </Link>
          </h4>
        </form>
      </div>
    </main>
  );
}
