import React from "react";
import Modal from "../../modal/Modal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FormAutocomplete from "../createJob/Autocomplete";
const core = require("../../../lib/core");

function ShareJobModal({ jobId, modalDismiss }) {
  const [inputEmail, setInputEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [AssessmentId, setAssessmentId] = useState("");
  const [warning, setWarning] = useState("");
  const [expiryHrs, setExpiryHrs] = useState(60);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();

  const { jobDetails } = useSelector((state) => {
    return {
      jobDetails: state?.JobExpandSlice?.assessment,
    };
  });

  useEffect(() => {
    if (jobDetails) {
      console.log("jobDetails", jobDetails);
      setAssessmentDetails(jobDetails?.assessments);
    }
  }, [jobDetails]);

  // GET_ALL_RECRUITER

  const [allUser, setAllUser] = useState([]);
  const [sharedID, setSharedID] = useState([]);

  const GET_ALL_RECRUITER = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RECRUITER,
      1,
    );
    if (json.data) {
      setAllUser(json?.data);
    } else {
      console.log("error");
      // Error
    }
  };

  const GET_SHARED_JOB_IDS = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_SHARE_JOBS_ID + "/" + jobId,
      1,
    );
    if (json?.data?.data) {
      setSharedID(json?.data?.data);
    } else {
      console.log("error");

      // Error
    }
  };

  const [sharedStatus, setSharedStatus] = useState(false);

  const UPDATE_SHARED_JOB_IDS = async () => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.RECRUITER_API.GET_SHARE_JOBS_ID,
      1,
      {
        jobId: jobId,
        shareIds: sharedID?.map((item) => item?.id),
        hostname: window.location.hostname,
      },
    );
    if (json?.data) {
      // onCancel();
      setSharedStatus(true);
      // setSharedID(json?.data)
    } else {
      console.log("error");
      // Error
    }
  };

  useEffect(() => {
    GET_SHARED_JOB_IDS();
  }, [jobId]);

  useEffect(() => {
    GET_ALL_RECRUITER();
  }, []);

  function handleEnter(email) {
    if (/\S+@\S+\.\S+/.test(email)) {
      if (emailList.includes(email)) {
        setWarning("Email already added");
        setInputEmail("");
      } else {
        setWarning("");
        setEmailList([...emailList, email]);
        setInputEmail("");
      }
    } else {
      setWarning("Invalid format for email. Try again");
    }
  }

  const onCancel = () => {
    setEmailList([]);
    setInputEmail("");
    setAssessmentId("");
    setWarning("");
    setExpiryHrs(60);
    modalDismiss();
  };

  const modalElements = {
    heading: "Share job ",
    subheading: "Add emails to share the job with ",
    noBtns: sharedStatus,
    modalDismiss: () => onCancel(),
    onSave: () => {
      UPDATE_SHARED_JOB_IDS();
    },
    defaultButtons: {
      primaryLabel: "Share ",
      primaryDisabled: !(sharedID?.length > 0),
    },
  };

  const [SearchusersData, setSearchusersData] = useState([]);

  function searchListJobs(keyword) {
    // Empty array to store results
    let results = [];

    // Iterate through the array
    allUser.forEach((item) => {
      // Check if the keyword is present in the item (case insensitive)
      if (item?.email?.toLowerCase()?.includes(keyword.toLowerCase())) {
        results.push(item);
      }
    });
    setSearchusersData(results);
  }

  const [value, setvalue] = useState();

  return (
    <Modal {...modalElements}>
      {!sharedStatus ? (
        <div className="w-full flex flex-col">
          {sharedID?.length > 0 && (
            <div className="w-full tags flex flex-wrap gap-[10px] mt-[10px] mb-[20px]">
              {sharedID?.map((item) => (
                <span
                  className="flex items-center bg-[#00AC85] px-[10px] py-[5px] rounded-[8px]"
                  key={item?.id}
                >
                  <span className="text-[#fff] text-[14px] font-normal not-italic">
                    {item?.email}
                  </span>
                  <button
                    className="remove"
                    onClick={(e) => {
                      e.preventDefault();
                      setSharedID(sharedID?.filter((d) => d?.id != item?.id));
                    }}
                  >
                    <svg
                      className="ml-[4px] cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M4.66797 4.66669L11.3346 11.3334"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.66927 11.3334L11.3359 4.66669"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}

          <h3
            className="w-full text-black text-sm font-normal"
            htmlFor="assessment-dropdown"
          >
            Email
          </h3>
          <FormAutocomplete
            type="text"
            className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            idName="position"
            name="position"
            value={value}
            data={SearchusersData}
            required={true}
            searchFunction={searchListJobs}
            selectionFunction={(name, value) => {
              setvalue(value);
            }}
            onSelectOption={(e) => {
              setSharedID((prevSharedID) => [...prevSharedID, e]);
              setvalue("");
            }}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-center mt-[40px] mb-[40px]">
            <span className="text-lg font-medium text-[green] ">
              Job shared successfully
            </span>
          </div>
        </>
      )}
    </Modal>
  );
}

export default ShareJobModal;
