import { useState, useRef } from "react";
import { useEffect } from "react";
import Header from "../../../components/all/header/Header";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import { Link } from "react-router-dom";
import AppliedJobPost from "../../../components/candidate/dashboard/AppliedJobPost";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
const core = require("../../../lib/core");

export default function AppliedJobs() {
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    fetchAppliedJobs();
  }, []);

  const fetchAppliedJobs = async () => {
    const jobList = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_APPLIED_JOBS,
      1,
      {}
    );
    console.log(jobList);
    if (jobList.data) {
      setAppliedJobs(jobList.data.jobs);
    } else {
      console.log(jobList.error);
    }
  };

  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="job_details_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <CandidateSidebar active="Applied" open={false} />
        <div className="w-full">
          <HeaderNew />
          <JobContent
          appliedJobs={appliedJobs}
          fetchAppliedJobs={fetchAppliedJobs}

          />
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className="page candidate-pages" id="applied-jobs">
  //     <Header showOnlyLogo={false} menu={<Menu />} />
  //     <SideBar />
  //     <main className="main-content">
  //       <div className="page-container col-xs-12 ">
  //         <h1 className="headingText">Applied Jobs</h1>
  //         {appliedJobs.length != 0 && (
  //           <div>
  //             {appliedJobs.map((job, i) => (
  //               <AppliedJobPost
  //                 key={i}
  //                 job={job}
  //                 reload_function={fetchAppliedJobs}
  //               />
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //     </main>
  //   </div>
  // );


}

const JobContent = ({
  fetchAppliedJobs,
  appliedJobs,
  ...props
}) => {
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"

    >
      <span className="font-semibold text-[28px] not-italic text-[#16182F]">
        Applied jobs
      </span>

     
    <div className="page candidate-pages" id="applied-jobs">
      <main className="main-content">
        <div className="page-container col-xs-12 ">
          {appliedJobs.length != 0 && (
            <div>
              {appliedJobs.map((job, i) => (
                <AppliedJobPost
                  key={i}
                  job={job}
                  reload_function={fetchAppliedJobs}
                />
              ))}
            </div>
          )}
        </div>
      </main>
    </div>
    </main>
  );
};