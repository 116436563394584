import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApplicantsList,fetchApplicantDetails } from '../services';

const core =require("../../lib/core")

const initialState = {
  isLoading: true,
  candidateList: [],
  profile: null,
  overallEvalScore: {
    matchScore: 0,
    intevalScore: 0,
    criteriaScore: 0,
  },
};



const jobMatchCandidatesSlice = createSlice({
  name: 'candidateProfile',
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplicantsList.fulfilled, (state, action) => {
        console.log(state,action)
        state.candidateList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchApplicantsList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchApplicantDetails.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchApplicantDetails.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

// Export actions
export const { } = jobMatchCandidatesSlice.actions;

export default jobMatchCandidatesSlice.reducer;
