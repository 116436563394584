import { Link } from "react-router-dom";
import InitialsLogo from "../../all/InitialsLogo";
import locationSvg from "../../assets/recruiter/LocationSvg.svg"

const moment = require("moment");
const now = moment();

export default function AppliedJobPost({ job, reload_function }) {

  const timeDifference = (difference) => {

    // Format the time difference
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`
    }
  }

  return (
    <Link to={`/jobpost/${job?.id}`} className="col-xs-12 job-post no-padding">
      <div className="col-xs-3 no-padding position">
        <div className="col-xs-3 logo-section no-padding">
          <InitialsLogo
            className="img-responsive"
            str={job.company?.name}
            width={60}
            height={60}
            bgRounded="12px"
            fontSize={28}
          />
        </div>
        <div className="col-xs-9 text-section no-padding">
          <h4 className="col-xs-12 no-padding position">{job?.position}</h4>
          <span className="company_name">{job?.company?.name}</span>
        </div>
      </div>
      <div className="col-xs-3 no-padding status">
        <span className="job-status-text">{job?.status}</span>
      </div>
      <div className="col-xs-2 no-padding location">
        <h5 className="col-xs-12 no-padding location">
          <img
            src={locationSvg}
            alt="location"
            className="img-responsive"
          />
          {job?.location}
        </h5>
      </div>
      <div className="col-xs-2 no-padding datePosted">
        <h5 className="col-xs-12 no-padding datePosted">
        {timeDifference(new Date - new Date(job?.createdAt))}
        </h5>
      </div>
      <div className="col-xs-1 no-padding">
        <button
          className="message-button"
        // onClick={send_message}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M13.9987 14C14.643 14 15.1654 13.4777 15.1654 12.8333C15.1654 12.189 14.643 11.6667 13.9987 11.6667C13.3544 11.6667 12.832 12.189 12.832 12.8333C12.832 13.4777 13.3544 14 13.9987 14Z"
              fill="#001B4B"
            />
            <path
              d="M18.6667 14C19.311 14 19.8333 13.4777 19.8333 12.8333C19.8333 12.189 19.311 11.6667 18.6667 11.6667C18.0223 11.6667 17.5 12.189 17.5 12.8333C17.5 13.4777 18.0223 14 18.6667 14Z"
              fill="#001B4B"
            />
            <path
              d="M9.33464 14C9.97897 14 10.5013 13.4777 10.5013 12.8333C10.5013 12.189 9.97897 11.6667 9.33464 11.6667C8.6903 11.6667 8.16797 12.189 8.16797 12.8333C8.16797 13.4777 8.6903 14 9.33464 14Z"
              fill="#001B4B"
            />
            <path
              d="M22.1654 3.5H5.83203C4.90377 3.5 4.01353 3.86875 3.35716 4.52513C2.70078 5.1815 2.33203 6.07174 2.33203 7V24.5C2.33239 24.7067 2.38767 24.9096 2.49222 25.088C2.59676 25.2663 2.74682 25.4137 2.92703 25.515C3.1014 25.6138 3.29828 25.6661 3.4987 25.6667C3.7081 25.6666 3.91362 25.6102 4.0937 25.5033L9.33203 22.33C9.52566 22.2147 9.74852 22.158 9.9737 22.1667H22.1654C23.0936 22.1667 23.9839 21.7979 24.6402 21.1415C25.2966 20.4852 25.6654 19.5949 25.6654 18.6667V7C25.6654 6.07174 25.2966 5.1815 24.6402 4.52513C23.9839 3.86875 23.0936 3.5 22.1654 3.5ZM23.332 18.6667C23.332 18.9761 23.2091 19.2728 22.9903 19.4916C22.7715 19.7104 22.4748 19.8333 22.1654 19.8333H9.9737C9.33649 19.8328 8.71124 20.0063 8.16536 20.335L4.66536 22.435V7C4.66536 6.69058 4.78828 6.39383 5.00707 6.17504C5.22587 5.95625 5.52261 5.83333 5.83203 5.83333H22.1654C22.4748 5.83333 22.7715 5.95625 22.9903 6.17504C23.2091 6.39383 23.332 6.69058 23.332 7V18.6667Z"
              fill="#001B4B"
            />
          </svg>
        </button>
      </div>
    </Link>
  );
}
