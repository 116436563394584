import React, { useState } from "react";
import CodeEditors from "./code";
import StartIntModal from "./StartIntModal";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import BeatLoader from "react-spinners/BeatLoader";
import AudioSvg from "../../assets/candidate/Audio.gif";

const core = require("../../../lib/core");

function AutonomusInterview({
  currentQuestion,
  questions,
  handleSkipQuestion,
  videoRef,
  navigation,
  endTest,
  setCountSkipQsn,
  countSkipQsn,
}) {
  const [startInt, setStartInt] = useState(false);
  const loc = useLocation();
  const [intQsn, setIntQsn] = useState([]);
  const [curQsn, setCurQsn] = useState(0);
  const nav = useNavigate();
  const [buttonName, setButtonName] = useState("Next");

  useEffect(() => {
    getIntdeatails(getCookie("assessmentId"));
  }, []);

  useEffect(() => {}, [countSkipQsn]);

  console.log(countSkipQsn);

  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  const [reload, setReload] = useState(0);
  async function getIntdeatails(id) {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_CANDIDATE_INTERVIEW_DETAILS + id,
        1
      );
      if (json?.data) {
        setIntQsn(json.data.interview?.interviewQuestions?.beginner[0]);
        console.log(json?.data);
        setReload(reload + 1);
      } else {
      }
    } catch (err) {}
  }

  useEffect(() => {
    intialPostCurrentQuestion();
  }, []);

  async function intialPostCurrentQuestion() {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CURRENT_QUESTION,
        1,
        {
          question: intQsn[curQsn].question,
          interviewId: getCookie("assessmentId"),
        }
      );
      if (json?.data) {
      } else {
      }
    } catch (err) {}
  }

  const [loader, setLoader] = useState(false);

  async function PostCurrentQuestion(status) {
    setLoader(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_NEXT_QUESTION + getCookie("assessmentId"),
        1,
        {
          question: intQsn,
        }
      );
      if (json?.data) {
        // if(curQsn!=0){
        setIntQsn(json?.data?.question);
        setCurQsn(curQsn + 1);
        setLoader(false);
        if (status === "next") {
          setCountSkipQsn(countSkipQsn + 1);
        }
        // }
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  }

  console.log(intQsn);
  return startInt ? (
    <section id="mcqSection" className="col-xs-12 no-padding">
      <div id="job-list" className="col-xs-8  left-section no-padding">
        <div className="question-section col-xs-12 no-padding">
          <div className="question-container  col-xs-12 no-padding">
            <div className="question col-xs-12 no-padding">
              <span className="questionNumber col-xs-12 no-padding">{`Question ${
                curQsn + 1
              }`}</span>
              <span className="col-xs-12 question-text no-padding">{`${intQsn}`}</span>
              <span className="col-xs-12 under-text no-padding">{`Carefully read and answer the question. Your screen, audio and face is been recorded`}</span>
            </div>
            <div className="col-xs-12 no-padding audio-gif">
              <img src={AudioSvg} alt="" />
              {/* <img src="https://sproutsai-staging.s3.amazonaws.com/assets/audio_gif.gif" alt="" /> */}
            </div>
            <div className="col-xs-12 interview-instruction-section">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1576_117348)">
                    <path
                      d="M9 6V6.75M9 8.625V12M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                      stroke="#252E4B"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1576_117348">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                How to answer the question?
              </span>

              <li>Understand the question and respond with confidence.</li>
              <li>
                Speak your answer while keeping your attention solely on the
                screen.
              </li>
            </div>
          </div>
        </div>
        <div className="button-section col-xs-12 no-padding">
          <div></div>
          <div className="right-button-sec">
            <button
              className="skip"
              onClick={(e) => {
                if (curQsn == 4) {
                  endTest("");
                } else {
                  if (curQsn == 3) {
                    setButtonName("Submit");
                    PostCurrentQuestion("skip");
                  } else {
                    PostCurrentQuestion("skip");
                  }
                  // nextQuestionClick()
                }
              }}
            >
              Skip
            </button>
            <button
              className="next"
              onClick={(e) => {
                if (curQsn == 4) {
                  endTest("");
                } else {
                  if (curQsn == 3) {
                    setButtonName("Submit");
                    PostCurrentQuestion("next");
                    setCountSkipQsn(countSkipQsn + 1);
                  } else {
                    PostCurrentQuestion("next");
                    setCountSkipQsn(countSkipQsn + 1);
                  }
                  // nextQuestionClick()
                }
              }}
            >
              {loader ? (
                <BeatLoader
                  color="white"
                  loading={loader}
                  //cssOverride={override}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <>{buttonName}</>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* <div className=" right-section">
        <div
          className="question-index-section  col-xs-12 no-padding"
          style={{ background: "black" }}
        >
          <div className="col-xs-12 no-padding video-section">
            <video
              ref={videoRef}
              style={{
                width: "100%",
                height: "auto",
                marginTop: "16px",
                borderRadius: "12px",
              }}
              autoPlay
              muted
            />
          </div>
        </div>
      </div> */}
      {/* <CodeEditors/> */}
    </section>
  ) : (
    <StartIntModal
      modalDismiss={() => setStartInt(true)}
    />
  );
}
export default AutonomusInterview;
