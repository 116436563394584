import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const core = require("../../../lib/core");

export default function Filter({
  sort_option,
  filter_options,
  filter_function,
}) {
  const location = useLocation()
  const [filter, setFilter] = useState(location.pathname.split("/").at(-1).split("%20").join(" "));
  const[sortfilter,setSortfilter]=useState()
  const nav = useNavigate();
  useEffect(() => {
    // setFilter(location.pathname.split("/").at(-1).split("%20").join(" "))
  }, [sort_option]);


  console.log(filter)



  const CheckFilterStatus = (item) => {
    // console.log(item)
    // console.log(location.pathname)
    var status = location.pathname.split("/").at(-1).split("%20").join(" ").split("&").includes(item)
    if (status == true) {
      return "checked"
    }
    else {
      return ""
    }

  }

  async function filterOp() {
    var filterStr = filter.charAt(0) === "&" ? filter.slice(1) : filter;
    console.log(filterStr);
    // nav("/", { state: "?" + filterStr });
    filter_function("?" + filterStr)

    if (filterStr.length > 0) {
      window.history.replaceState("", filterStr, filterStr)
    }
    else {
      window.history.replaceState("", "", "&")
    }


  }



  async function clearAllFilters() {
    nav("/")
  }




  return (
    <div className="filter col-xs-4 no-padding">
      <h3>Filter</h3>
      <div className="col-xs-12 no-padding no-scroller">
        <div className="filter-option col-xs-12 no-padding" id="sort-option">
          <h4>Sort By</h4>
          <ul className="col-xs-12 no-padding">
            {sort_option.map((attrValue, attrValueKey) => (
              <li className="col-xs-12 no-padding" key={attrValueKey}>
                <input
                  type="radio"
                  className="checker"
                  value={attrValue.split(" ").join("_")}
                  name={"sort"}
                  defaultChecked={CheckFilterStatus("sort=" + attrValue)}
                  onChange={(e) => {
                      (console.log(attrValue))

                    // if (e.target.checked == true) {
                    //   setFilter(filter + "&sort=" + attrValue);
                    // }
                    // else {
                      setFilter(((filter.split("&")).filter((d) => d != ("sort=Date posted") && d !=("sort=Location nearby") && d !=("sort=Default") ).join("&"))+"&sort=" + attrValue)
                    // }
                  }}
                />
                <label>{attrValue}</label>
              </li>
            ))}
          </ul>
        </div>
        {filter_options.length > 0 &&
          filter_options.map((attr, key) => (
            <div
              className="filter-option col-xs-12 no-padding"
              id={String(attr.type).toLowerCase().split(" ").join("_")}
              key={key}
            >
              <h4>{attr.type}</h4>
              <ul className="col-xs-12 no-padding">
                {attr.values.map((attrValue, attrValueKey) => {
                  const label =
                    attr.type === "Workplace type"
                      ? "workplace"
                      : attr.type === "Job type"
                        ? "type"
                        : "level";
                  return (
                    <li className="col-xs-12 no-padding" key={attrValueKey}>
                      <input
                        type="checkbox"
                        className="checker"
                        value={attrValue.split(" ").join("_")}
                        name={attrValue.split(" ").join("_")}
                        defaultChecked={CheckFilterStatus(label + "=" + attrValue)}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            console.log(attrValue)
                            setFilter(filter + "&" + label + "=" + attrValue);
                          }
                          else {
                            setFilter((filter.split("&")).filter((d) => d != (label + "=" + attrValue)).join("&"))
                          }
                        }}
                      />
                      <label>{attrValue}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </div>
      <div className="button-section">
        <button
          className="clear-btn col-xs-6 no-padding"
          onClick={clearAllFilters}
        >
          {" "}
          Clear{" "}
        </button>
        <button className="apply-btn col-xs-6 no-padding" onClick={filterOp}>
          {" "}
          Apply{" "}
        </button>
      </div>
    </div>
  );
}
