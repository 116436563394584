import { createAsyncThunk } from '@reduxjs/toolkit';
const core = require('../../lib/core');

export  const fetchApplicantsList = createAsyncThunk(
  'candidateProfile/fetchApplicantsList',
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_CANDIDATES + payload?.job_id,
        1
      );
      const data = list?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);

export const fetchApplicantDetails = createAsyncThunk(
  'candidateProfile/fetchApplicantDetails',
  async (payload, { rejectWithValue }) => {
    console.log(payload)
    try {
      console.log(payload)
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_JOB_MATCHPROFILE_DETAILS + payload.candId,
        1
      );
      const data = json?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);
