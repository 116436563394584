import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import JobSidebar from "../../../components/all/JobSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import JobListSidebar from "../../../components/recruiter/job/list/JobListSidebar";
import JobContent from "../../../components/recruiter/job/JobPageContent";

export default function JobDetailPage() {
  // To check the parameters
  const [params, setParams] = useState(useParams());
  // Reload page on change
  const [reload, setReload] = useState(0);

  const selectJob = (id, status, tab = params?.tab) => {
    // setJob()
    setParams((prev) => {
      let temp = prev;
      temp.jobId = id;
      temp.jobType = status;
      temp.tab = tab;
      return temp;
    });
    // fetchJob()
    window.history.replaceState(
      {},
      "",
      "/job/" + status + "/" + id + "/" + tab,
    );
    setReload((prev) => prev + 1);
  };
  // *********************************************

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Job"
          CandidateFilter={
            <JobListSidebar
              selectJob={selectJob}
              params={params}
              setParams={setParams}
              replace={true}
            />
          }
        />
        <div className="w-[calc(100%-350px)] grow">
          <HeaderNew />
          <JobContent
            jobId={params?.jobId}
            params={params}
            setParams={setParams}
            reload={reload}
            setReload={setReload}
            // setJobType={setJobType}
          />
        </div>
      </div>
      <Outlet />
    </div>
  );
}
