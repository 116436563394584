import React, { useEffect, useState } from "react";
import { fetchApplicantDetails } from "../../../redux/services";
import { useDispatch } from "react-redux";

import TopBanner from "./TopBanner";
import ProfileSection from "./Profile";
import { ScaleLoader } from "react-spinners";
import { CandidatePageContext } from "../../../lib/context";
import ScheduleInterview from "../applicant/ScheduleInterview";
import InviteModal from "../job/assessment/InviteModal";

const ResumeTab = React.lazy(() => import("./Resume"));
const InterviewTab = React.lazy(() => import("./interview/InterviewTestTab"));

const CandidateDrawer = ({ source, candId, setShowCandidate }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(<ProfileSection />);
  const [reload, setReload] = useState(0);
  const [showModal, setShowModal] = useState(false);
  // const candId = params?.candidateId;
  useEffect(() => {
    getApplicantsDetails();
  }, [dispatch, reload]);

  const getApplicantsDetails = async () =>
    dispatch(fetchApplicantDetails({ candId: candId }));

  const btnList = [
    {
      name: "Details",
      component: <ProfileSection />,
    },
    { name: "Resume", component: <ResumeTab /> },
    // { name: "Test & Interview", component: <InterviewTab reload={reload} /> },

    // {
    //   name: "Analytics",
    //   component: <CandidateAnalytics />,
    // },
  ];

  return (
    <CandidatePageContext.Provider value={{ setReload, reload, setShowModal }}>
      <section
        id="candidateDrawer"
        className="relative block z-20 w-screen"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        <div className="pointer-events-none fixed inset-y-0 right-0 flex w-3/4 max-w-full pl-10">
          <div className="pointer-events-auto relative w-full">
            <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-16 sm:pr-4">
              <button
                onClick={() => setShowCandidate(false)}
                type="button"
                className="relative rounded-full p-4 bg-white text-gray-800 group focus:outline-none focus:ring-2 focus:ring-white"
              >
                <svg
                  className="h-6 w-6 group-hover:stroke-blue"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 shadow-xl">
              <TopBanner
                setActiveTab={setActiveTab}
                btnList={btnList}
                reload={setReload}
              />
              <React.Suspense
                fallback={
                  <div className="w-full h-full flex grow item-center justify-center">
                    <ScaleLoader color="#2563EB" loading={true} />
                  </div>
                }
              >
                {activeTab}
              </React.Suspense>
            </div>
          </div>
        </div>
        {showModal &&
          (showModal?.type == "interview" ? (
            <ScheduleInterview
              edit={showModal?.edit}
              interviewDetails={showModal?.edit ? showModal?.data : {}}
            />
          ) : (
            <InviteModal
              modalDismiss={closeModal}
              assessmentDetails={activeAssessments}
            />
          ))}
      </section>
    </CandidatePageContext.Provider>
  );
};

export default CandidateDrawer;
