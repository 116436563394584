import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Range, getTrackBackground } from "react-range";
import HamburgerSvg from "../../assets/recruiter/DragIcon.svg";
import { TypesButtons, getTypeColor, getTypeName } from "./TypesButton";
import FormAutocomplete from "../createJob/Autocomplete";
import errorIcon from "../../assets/error.svg";
import { minAlphaNumericVal } from "./ValidateValues";
const core = require("../../../lib/core");

export default function Skills({ analytics, classTxt, skills, setSkills }) {
  const { errorList, setErrorList } = useContext(core?.JobFormContext);
  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  const levels = [
    "No experience",
    "Novice",
    "Beginner",
    "Competent",
    "Proficient",
    "Expert",
  ];

  const setLevels = (value) => {
    if (value === 0) return levels[0];
    else if (value <= 3) return levels[1];
    else if (value < 5) return levels[2];
    else if (value <= 7) return levels[3];
    else if (value <= 10) return levels[4];
  };

  const handleNewCriteria = (data) => {
    try {
      if (data.criteria == "") throw "Empty value, please type a category";
      skills.map((d) => {
        if (checkLabel(d.criteria, d.label) == data.criteria) {
          throw "Criteria already exists";
        }
      });
      setSkills((prev) => [data, ...prev]);
      setErrorList((current) => {
        const { criteria, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, criteria: error });
    }
  };
  const handleNewSkill = (parentObj) => {
    try {
      let updatedSkills = skills.map((d) => {
        if (d != parentObj) return d;
        let updatedKeySkills = [
          ...checkLabel(d?.skills, d?.keySkills),
          {
            label: "",
            value: 5,
            type: 2,
          },
        ];
        return { ...d, skills: updatedKeySkills };
      });
      setSkills(updatedSkills);
      return;
    } catch (error) {
      console.error(error);
      // error == "Criteria exists" || "Empty value, please type a category"
      //   ? toast.error(error)
      //   : toast.error("Please try again");
    }
  };
  function handleCriteriaChange(newStr, parentIdx) {
    try {
      skills.map((d) => {
        if (d.criteria == newStr) {
          throw "Criteria already exists";
        }
      });
      let us = [...skills];
      us[parentIdx].criteria = newStr;
      setSkills(us);
      setErrorList((current) => {
        const key = "criteria" + parentIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });
      if (newStr == "") throw "Empty value, please type a category";
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, ["criteria" + parentIdx]: error });
    }
  }
  const handleChildValueChange = (newObj, [parentIdx, childIdx]) => {
    try {
      let updatedSkills = skills.map((parent, pId) => {
        if (pId === parentIdx) {
          let updatedKeySkills = parent?.skills?.map((child, cId) => {
            if (cId != childIdx) return child;
            return { ...child, ...newObj };
          });
          let values = updatedKeySkills?.map((child) => child?.value);
          let averageValue = values.reduce((a, b) => a + b) / values.length;
          return { ...parent, skills: updatedKeySkills, value: averageValue };
        }
        return parent;
      });

      setSkills(updatedSkills);
      setErrorList((current) => {
        const key = "criteria" + parentIdx + "skill" + childIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });

      minAlphaNumericVal(newObj?.label);
    } catch (error) {
      console.error(error);
      setErrorList({
        ...errorList,
        ["criteria" + parentIdx + "skill" + childIdx]: error,
      });
    }
  };
  const handleCriteriaDelete = (idx) => {
    try {
      let temp = skills.filter((d, id) => {
        if (id != idx) return d;
      });
      setSkills(temp);
      // setErrorList((current) => {
      //   const key = "criteria" + idx;
      //   const { [key]: val, ...rest } = current;
      //   return rest;
      // });
      setErrorList((current) =>
        Object.keys(current).filter((d) => !d.includes("criteria" + idx)),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSkillDelete = ([pIdx, cIdx]) => {
    try {
      let updatedSkills = skills.map((parent, pId) => {
        if (pId != pIdx) return parent;

        let updatedKeySkills = checkLabel(
          parent.skills,
          parent.keySkills,
        ).filter((child, cId) => {
          if (cId != cIdx) return child;
        });
        let averageValue;
        if (updatedKeySkills?.length > 0) {
          let values = updatedKeySkills.map((child) => child.value);
          averageValue = values.reduce((a, b) => a + b) / values.length;
        } else {
          averageValue = 0;
        }
        return { ...parent, skills: updatedKeySkills, value: averageValue };
      });
      setSkills(updatedSkills);
      setErrorList((current) => {
        const key = "criteria" + pIdx + "skill" + cIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const reorderCriteria = (oldIndex, newIndex) => {
    setSkills((prevSkills) => {
      // Create a deep copy of the skills array to avoid mutation
      const updatedCriteriaList = JSON.parse(JSON.stringify(prevSkills));

      // Get the skill item to move
      const skillToMove = updatedCriteriaList[oldIndex];

      // Remove the skill from its current position
      updatedCriteriaList.splice(oldIndex, 1);

      // Insert the skill at the new position
      updatedCriteriaList.splice(newIndex, 0, skillToMove);
      setIsDragging(false);

      return updatedCriteriaList;
    });
  };

  const reorderSameCriteria = (parentIndex, oldIndex, newIndex) => {
    setSkills((prevSkills) => {
      // Create a deep copy of the skills array to avoid mutation
      const updatedSkills = JSON.parse(JSON.stringify(prevSkills));

      // Get the skill item to move
      const skillToMove = updatedSkills[parentIndex].skills[oldIndex];

      // Remove the skill from its current position
      updatedSkills[parentIndex].skills.splice(oldIndex, 1);

      // Insert the skill at the new position
      updatedSkills[parentIndex].skills.splice(newIndex, 0, skillToMove);
      setIsDragging(false);
      return updatedSkills;
    });
  };

  const reorderDiffCriteria = (
    sourceCriteriaIndex,
    destinationCriteriaIndex,
    sourceSkillIndex,
  ) => {
    setSkills((prevSkills) => {
      // Create a deep copy of the skills array to avoid mutation
      const updatedSkills = JSON.parse(JSON.stringify(prevSkills));

      // Get the skill to move
      const skillToMove =
        updatedSkills[sourceCriteriaIndex].skills[sourceSkillIndex];

      // Remove the skill from the source criteria
      updatedSkills[sourceCriteriaIndex].skills.splice(sourceSkillIndex, 1);

      // Insert the skill into the destination criteria at the end
      updatedSkills[destinationCriteriaIndex].skills.push(skillToMove);

      let temp = updatedSkills.map((skill, i) => {
        if (i == sourceCriteriaIndex) {
          if (skill?.skills?.length <= 0) return { ...skill, value: 0 };
          let newValue = skill.skills.map((d) => d.value);
          return {
            ...skill,
            value: newValue.reduce((a, b) => a + b) / newValue.length,
          };
        } else if (i == destinationCriteriaIndex) {
          let newValue = skill.skills.map((d) => d.value);
          return {
            ...skill,
            value: newValue.reduce((a, b) => a + b) / newValue.length,
          };
          setIsDragging(false);
        } else {
          setIsDragging(false);
          return skill;
        }
      });
      return temp;
    });
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  function dragEnd(result) {
    const { source, destination, type } = result;
    // If the candidate is dropped outside of a Droppable, return
    if (!destination) {
      toast("Dragged item out of range");
      setIsDragging(false);
      return;
    }
    // If the candidate is dropped in the same Droppable and position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      toast("Drag item further, it is in the same position");
      setIsDragging(false);
      return;
    }

    if (type == "criteria") {
      reorderCriteria(source.index, destination.index);
    }
    if (type == "skill") {
      const sourceCriteriaIndex = +source.droppableId.split("_")[0];
      const destinationCriteriaIndex = +destination.droppableId.split("_")[0];

      if (sourceCriteriaIndex === destinationCriteriaIndex) {
        // Reorder within the same criteria
        let arr_idx = +source.droppableId.split("_")[0];
        // skills[arr_idx].skills = reorder(checkLabel(skills[arr_idx].skills, skills[arr_idx].keySkills), source.index, destination.index);
        reorderSameCriteria(arr_idx, source.index, destination.index);
      } else {
        // Move skill to another criteria
        let arr_idx = +source.droppableId.split("_")[0];
        let arr_idx2 = +destination.droppableId.split("_")[0];
        reorderDiffCriteria(arr_idx, arr_idx2, source.index);

        // let values = skills[arr_idx2].skills.map((d) => d.value);
        // skills[arr_idx2].value = values.reduce((a, b) => a + b) / values.length;
        // skills[arr_idx].value =
        //   values2.reduce((a, b) => a + b) / values2.length;
        // setSkills(temp);
        // console.log(temp);
      }
    }
  }

  const [listpreferSkill, setListpreferSkill] = useState([]);

  // functions for search the skills
  function searchPreferSkillList(term) {
    try {
      if (term?.length < 1) {
        setListpreferSkill([]);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_ALL_SKILLS + "?name=" + term,
            1,
            {},
          );
          if (response?.data?.skills?.length > 0) {
            setListpreferSkill(response?.data.skills.map((d) => d.name));
          } else {
            setListpreferSkill([]);
          }
        }, 300);
      }
    } catch (err) {
      setListpreferSkill([]);
    }
  }

  const [allCriterias, setAllCriterias] = useState([]);
  function searchPreferCriteriaList(term) {
    try {
      if (term?.length < 1) {
        setAllCriterias([]);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_ALL_CRITERIA + "/" + term,
            1,
            {},
          );
          if (response?.data?.criterias) {
            setAllCriterias(response?.data.criterias.map((d) => d.name));
          } else {
            setAllCriterias([]);
          }
        }, 300);
      }
    } catch (err) {
      setAllCriterias([]);
    }
  }

  const [criteriaValue, setCriteriaValue] = useState();

  return (
    // analytics != null && (
    <section className={classTxt} id="Skills-section">
      <div className="w-full data-section no-padding">
        <div className="w-full header-section">
          <h4 className="w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="shapes">
                <path
                  id="Vector"
                  d="M13.4303 15.0014H4.40034C2.58034 15.0014 1.42034 13.0514 2.30034 11.4514L4.63034 7.21141L6.81034 3.24141C7.72034 1.59141 10.1003 1.59141 11.0103 3.24141L13.2003 7.21141L14.2503 9.12141L15.5303 11.4514C16.4103 13.0514 15.2503 15.0014 13.4303 15.0014Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M22 15.5C22 19.09 19.09 22 15.5 22C11.91 22 9 19.09 9 15.5C9 15.33 9.01 15.17 9.02 15H13.43C15.25 15 16.41 13.05 15.53 11.45L14.25 9.12C14.65 9.04 15.07 9 15.5 9C19.09 9 22 11.91 22 15.5Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Skills details
          </h4>
        </div>
        <div className="w-full input-data-section">
          <label htmlFor="" className=" text-label w-full">
            Categories{" "}
            {Object.keys(errorList).includes("criteria") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.criteria}
                </span>
              </>
            ) : null}
          </label>
          {/* <input
            className="w-full"
            type="text"
            placeholder="Press enter to add category"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const inputValue = e.target.value.trim();
                handleNewCriteria({
                  criteria: inputValue,
                  value: 0,
                  skills: [],
                });
                e.target.value = ""; // Clear the input field after adding the skill
              }
            }}
          /> */}

          <FormAutocomplete
            type="text"
            defaultValue="Type category and press enter "
            idName="Criterias"
            required={false}
            name="skill"
            value={criteriaValue}
            data={allCriterias}
            onSelectOption={(name) => {
              const inputValue = name.trim();
              handleNewCriteria({
                criteria: inputValue,
                value: 0,
                skills: [],
              });
              setCriteriaValue("");
            }}
            onEnterFunction={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const inputValue = e.target.value.trim();
                handleNewCriteria({
                  criteria: inputValue,
                  value: 0,
                  skills: [],
                });
                setCriteriaValue("");
                e.target.value = ""; // Clear the input field after adding the skill
              }
            }}
            searchFunction={searchPreferCriteriaList}
            selectionFunction={(name, value) => {
              setCriteriaValue(value);
            }}
            inputClassName={
              Object.keys(errorList).includes("criteria")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }
          />

          <div
            className={`w-full max-h-[600px] overflow-y-scroll scrollbar-candidate ${
              isDragging ? "" : ""
            }`}
            id="create-skills-section"
          >
            <DragDropContext onDragEnd={dragEnd} onDragStart={handleDragStart}>
              <Droppable
                droppableId={"criteria_drop"}
                type="criteria"
                className="w-full"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-full"
                  >
                    {skills?.map((skill, it) => (
                      <Draggable
                        draggableId={
                          String(skill?.criteria).split(" ").join("_") +
                          "_" +
                          it
                        }
                        index={it}
                        key={"criteria" + it}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="section-skills w-full"
                          >
                            <div className="w-full section-header no-padding">
                              <div className="w-4/5 no-padding label-input">
                                <span
                                  {...provided.dragHandleProps}
                                  data-tooltip="Hold and drag"
                                  className="icon drag"
                                >
                                  <img
                                    src={HamburgerSvg}
                                    className="icon img-responsive"
                                    alt="dragger"
                                  />
                                </span>
                                <input
                                  type={"text"}
                                  required
                                  onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                      e.preventDefault();
                                      handleNewSkill(skill);
                                    }
                                  }}
                                  className="w-full"
                                  value={checkLabel(
                                    skill.criteria,
                                    skill.label,
                                  )}
                                  onChange={(e) => {
                                    handleCriteriaChange(e.target.value, it);
                                  }}
                                />
                              </div>
                              <div
                                className="w-[18px] h-[18px] group cursor-pointer"
                                onClick={() => {
                                  handleCriteriaDelete(it);
                                }}
                              >
                                <svg
                                  className="w-[18px] h-[18px]"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g id="left icon">
                                    <path
                                      className="group-hover:stroke-[#910000]"
                                      id="Vector"
                                      d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                      stroke="#FF475D"
                                      strokeLinecap="round"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </div>

                            <Droppable droppableId={it + "_drop"} type="skill">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="list-section w-full divide-y"
                                >
                                  {checkLabel(
                                    skill.skills,
                                    skill.keySkills,
                                  )?.map((ks, i) => (
                                    <Draggable
                                      draggableId={it + "_" + i}
                                      index={i}
                                      key={it + "_" + i}
                                    >
                                      {(provided, snapshot) => (
                                        <>
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="w-full mb-2 flex justify-between items-center"
                                          >
                                            <div
                                              className="w-1/3 name flex justify-between items-center relative"
                                              {...provided.dragHandleProps}
                                            >
                                              <img src={HamburgerSvg} alt="" />
                                              {/* <input
                                              type="text"
                                              className="w-[calc(100%-20px)]"
                                              required
                                              value={ks.label}
                                              placeholder="Type here"
                                              onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                  e.preventDefault();
                                                }
                                              }}
                                              onChange={(e) => {
                                                handleChildValueChange(
                                                  { label: e.target.value },
                                                  [it, i]
                                                );
                                              }}
                                            /> */}
                                              <FormAutocomplete
                                                type="text"
                                                className="w-[calc(100%-20px)]"
                                                defaultValue="Type to search... "
                                                idName={"skill" + i + it}
                                                required={true}
                                                name="skill"
                                                value={ks?.label}
                                                data={listpreferSkill}
                                                inputClassName={
                                                  Object.keys(
                                                    errorList,
                                                  ).includes(
                                                    "criteria" +
                                                      it +
                                                      "skill" +
                                                      i,
                                                  )
                                                    ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                                                    : ""
                                                }
                                                onEnterFunction={(e) => {
                                                  if (e.key == "Enter") {
                                                    handleNewSkill(skill);
                                                  }
                                                }}
                                                searchFunction={
                                                  searchPreferSkillList
                                                }
                                                selectionFunction={(
                                                  name,
                                                  value,
                                                ) => {
                                                  handleChildValueChange(
                                                    { label: value },
                                                    [it, i],
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="w-1/3 flex justify-between gap-3 items-center">
                                              <Range
                                                step={1}
                                                min={
                                                  ks?.type
                                                    ? ks?.type < 0
                                                      ? -10
                                                      : 0
                                                    : 0
                                                }
                                                max={
                                                  ks?.type
                                                    ? ks?.type < 0
                                                      ? 0
                                                      : 10
                                                    : 10
                                                }
                                                values={[ks?.value]}
                                                onChange={(value) => {
                                                  handleChildValueChange(
                                                    { value: value[0] },
                                                    [it, i],
                                                  );
                                                }}
                                                renderTrack={({
                                                  props,
                                                  children,
                                                }) => (
                                                  <div
                                                    {...props}
                                                    style={{
                                                      ...props.style,
                                                      height: "8px",
                                                      width:
                                                        "calc(100% - 30px)",
                                                      borderRadius: "4px",
                                                      right: 0,
                                                      position: "relative",
                                                      background:
                                                        getTrackBackground({
                                                          values: [ks?.value],
                                                          colors: ks?.type
                                                            ? ks?.type < 0
                                                              ? [
                                                                  "#f2f2f2",
                                                                  getTypeColor(
                                                                    ks?.type,
                                                                  ),
                                                                ]
                                                              : [
                                                                  getTypeColor(
                                                                    ks?.type,
                                                                  ),
                                                                  "#f2f2f2",
                                                                ]
                                                            : [
                                                                "teal",
                                                                "#f2f2f2",
                                                              ],
                                                          min: ks?.type
                                                            ? ks?.type < 0
                                                              ? -10
                                                              : 0
                                                            : 0,
                                                          max: ks?.type
                                                            ? ks?.type < 0
                                                              ? 0
                                                              : 10
                                                            : 10,
                                                        }),
                                                    }}
                                                  >
                                                    {children}
                                                  </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                  <div
                                                    {...props}
                                                    style={{
                                                      ...props.style,
                                                      height: "19px",
                                                      width: "19px",
                                                      borderRadius: "33.043px",
                                                      background: "#FFF",
                                                      boxShadow:
                                                        "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                                                    }}
                                                  />
                                                )}
                                              />
                                              <span className="inline-block w-[75px] text-ellipsis grow-0 shrink-0">
                                                {setLevels(+ks?.value)}
                                              </span>
                                            </div>
                                            <div className="w-1/4 flex justify-between items-center">
                                              <div className="block w-[calc(100%-24px)]">
                                                <ul
                                                  className={
                                                    "w-[calc(100%-48px)] py-1 flex justify-between"
                                                  }
                                                >
                                                  <TypesButtons
                                                    data={ks?.type ?? 2}
                                                    clickFunction={(
                                                      e,
                                                      type,
                                                    ) => {
                                                      e.preventDefault();
                                                      if (
                                                        (ks?.value > 0 &&
                                                          type < 0) ||
                                                        (ks?.value < 0 &&
                                                          type > 0)
                                                      ) {
                                                        handleChildValueChange(
                                                          {
                                                            value: -ks.value,
                                                            type: type,
                                                          },
                                                          [it, i],
                                                        );
                                                      } else {
                                                        handleChildValueChange(
                                                          { type: type },
                                                          [it, i],
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </ul>
                                              </div>
                                              <button
                                                className="w-[14px] h-[14px] group cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  // skill.skills = checkLabel(
                                                  //   skill.skills,
                                                  //   skill.keySkills
                                                  // ).filter((_, x) => x != i);
                                                  handleSkillDelete([it, i]);
                                                  // reload.func(reload.var + 1);
                                                }}
                                              >
                                                <svg
                                                  className="w-[14px] h-[14px]"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                >
                                                  <g id="left icon">
                                                    <path
                                                      className="group-hover:stroke-[#910000]"
                                                      id="Vector"
                                                      d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                                      stroke="#FF475D"
                                                      strokeLinecap="round"
                                                    />
                                                  </g>
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                          {Object.keys(errorList).includes(
                                            "criteria" + it + "skill" + i,
                                          ) ? (
                                            <div className="w-full my-2">
                                              <img
                                                className="mr-2 w-3.5 h-3.5 inline"
                                                src={errorIcon}
                                              />
                                              <span className="text-red-800 text-sm">
                                                {
                                                  errorList[
                                                    "criteria" +
                                                      it +
                                                      "skill" +
                                                      i
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          ) : null}
                                        </>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            <button
                              className="add-skills-button"
                              type="button"
                              onClick={() => {
                                handleNewSkill(skill);
                              }}
                            >
                              + Add skills
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </section>
    // )
  );
}
