import React, { useEffect, useState } from "react";
import FormAutocomplete from "../createJob/Autocomplete";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import { minAlphaNumericVal } from "./ValidateValues";
const core = require("../../../lib/core");

function AddCompany({
  form,
  companyNew,
  isCompanyNew,
  addedCompanyList,
  reload,
  showCompanyPage,
  setShowCompanyPage,
  selected
}) {
  const [companyList, setCompanyList] = useState([]);
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorValue, setEditorValue] = useState("");

  useEffect(() => {}, [showCompanyPage]);

  useEffect(() => {
    if (form?.data?.aboutCompany?.length > 0) {
      if (typeof form?.data?.aboutCompany !== "string") {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(
                  form?.data?.aboutCompany
                    ?.split("\n•")
                    .join("\n·")
                    .split("\n·")
                    .map((str) => "<li>" + str + "</li>")
                    .join(" ")
                )
              )
            )
          )
        );
      } else {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String(getText(form?.data?.aboutCompany)))
            )
          )
        );
      }
    }
  }, [reload]);

  useEffect(() => {
    form?.setFunction(
      "aboutCompany",
      convertToHTML(editorStateDesc?.getCurrentContent())
    );
    setEditorValue(editorStateDesc?.getCurrentContent().getPlainText());
  }, [editorStateDesc]);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  function searchListCompany(term) {
    try {
      isCompanyNew(true);
      if (term?.length >= 1 && minAlphaNumericVal(term)) {
        setCompanyList(["loader"]);
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.RECRUITER_API.GET_COMPANIES + "?name=" + term,
            1,
            {}
          );
          if (response?.data?.companies.length > 0) {
            if (typeof response?.data?.companies[0] === "string") {
              setCompanyList(response?.data?.companies);
            } else {
              setCompanyList(response?.data?.companies.map((d) => d.name));
            }
          } else {
            setCompanyList([]);
          }
        }, 300);
      }
    } catch (err) {
      console.error(err);
      // toast.error(err);
    }
  }

  const createNewCompanyPost = async () => {
    const json = await core.API(
      core.API_METHODS.POST,
      core.COMPANY_API.POST_CREATE_COMPANY_PROFILE,
      1,
      {
        name: form.data.company,
        about: form.data.aboutCompany,
        links: form.data.companyUrl,
        industry: form.data.companyIndustryType,
      }
    );
    if (json.data) {
      toast.success("Company added successfully");
      addedCompanyList();
      setShowCompanyPage(false);
    } else {
      toast.error(json.error.message);
      // Error
    }
  };

  const industryTypes = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts and Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "E-Learning",
    "Education Management",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness and Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import and Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology and Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics and Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing and Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures and Film",
    "Museums and Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Non-Profit Organization Management",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging and Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations and Communications",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational Facilities and Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security and Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing and Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation and Interpretation",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine and Spirits",
    "Wireless",
    "Writing and Editing",
  ];

  const [searchList, setSearchList] = useState();

  const search = (e) => {
    const filteredItems = industryTypes?.filter((item) =>
      item?.toLowerCase()?.includes(e?.toLowerCase())
    );
    setSearchList(filteredItems);
  };

  return (
    <div id="add-company">
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding text-label required" htmlFor="">
          Company Name
        </label>
        <FormAutocomplete
          type="text"
          value={form?.data?.company ?? ""}
          defaultValue={"Search here"}
          idName="company"
          required={true}
          searchType="company"
          setShowCompanyPage={setShowCompanyPage}
          showCompanyPage={showCompanyPage}
          companyNew={companyNew}
          name="company"
          data={companyList}
          searchFunction={searchListCompany}
          selectionFunction={(name, value) => {
            form.setFunction(name, value);
          }}
        />
        {!companyNew && (
          <div className="col-xs-12 no-padding warning-section">
            {/* <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g id="Icon" clipPath="url(#clip0_2665_202209)">
                  <path
                    id="Vector"
                    d="M6.99996 4.6665V5.24984M6.99996 6.70817V9.33317M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                    stroke="#FF475D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2665_202209">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Company Details Required: Please fill in the necessary information
              about your company
            </span> */}
            {/* <button onClick={() => {
              setShowCompanyPage(true);
              isCompanyNew(true);
            }}>+ Add new company</button> */}
          </div>
        )}
      </div>

      
      {/* {showCompanyPage && ( */}
      {true && (
        <div className="col-xs-12 no-padding">


          {/* <div className="col-xs-6 no-padding left-input">
            <label className="col-xs-12 no-padding text-label" htmlFor="">
              Company url
            </label>
            <input
              type="text"
              className="col-xs-12 no-padding "
              placeholder="https://"
            />
          </div>
          <div className="col-xs-6 no-padding right-input">
            <label className="col-xs-12 no-padding  text-label" htmlFor="">
              Industry
            </label>
            <FormAutocomplete
              type="text"
              value={form?.data?.companyIndustryType ?? ""}
              idName="companyIndustryType"
              required={false}
              searchType="companyIndustryType"
              defaultValue={"Type here"}
              name="companyIndustryType"
              data={searchList}
              searchFunction={search}
              selectionFunction={(name, value) => {
                form.setFunction(name, value);
              }}
            />
          </div> */}



{selected === "option1" &&
          <>
          <label
            className="col-xs-12 no-padding text-label"
            htmlFor="description"
          >
            About company
          </label>

          <div className="col-xs-12 no-padding" id="description">
            <Editor
              editorState={editorStateDesc}
              onEditorStateChange={setEditorStateDesc}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </div>
</>}

          {/* <div className="col-xs-12 no-padding button-container">
            <button
              className="clear-button"
              type="button"
              onClick={() => {
                setShowCompanyPage(false);
              }}
            >
              Cancel
            </button>
            <button
              className="save-button"
              type="button"
              onClick={(e) => {
                if (form.data.company.length === 0) {
                  alert("Please enter company name");
                  return;
                }
                createNewCompanyPost();
              }}
            >
              Save company information
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default AddCompany;
