import { useEffect, useState } from "react";
import { useParams } from "react-router";
import recruiterLogo from "../../../components/assets/LogoWhite.png";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { PropagateLoader } from "react-spinners";
import SkillsColumnChart from "../../../components/analytics/SkillsColumnChart";
import $ from "jquery";
import GaugeChart from "react-gauge-chart";
import Modal from "../../../components/modal/Modal";
const core = require("../../../lib/core");

function roundUpto2Digit(number) {
  return Math.round(number * 100) / 100;
}

function AverageAnswerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
        fill="#FF9500"
      />
      <path
        d="M9.19531 14.2454V9.16535C9.19531 8.96535 9.25531 8.77035 9.36531 8.60535L10.7303 6.57535C10.9453 6.25035 11.4803 6.02035 11.9353 6.19035C12.4253 6.35535 12.7503 6.90535 12.6453 7.39535L12.3853 9.03035C12.3653 9.18035 12.4053 9.31535 12.4903 9.42035C12.5753 9.51535 12.7003 9.57535 12.8353 9.57535H14.8903C15.2853 9.57535 15.6253 9.73535 15.8253 10.0154C16.0153 10.2854 16.0503 10.6354 15.9253 10.9904L14.6953 14.7354C14.5403 15.3554 13.8653 15.8604 13.1953 15.8604H11.2453C10.9103 15.8604 10.4403 15.7454 10.2253 15.5304L9.58531 15.0354C9.34031 14.8504 9.19531 14.5554 9.19531 14.2454Z"
        fill="white"
      />
      <path
        d="M7.605 8.18945H7.09C6.315 8.18945 6 8.48945 6 9.22945V14.2595C6 14.9995 6.315 15.2995 7.09 15.2995H7.605C8.38 15.2995 8.695 14.9995 8.695 14.2595V9.22945C8.695 8.48945 8.38 8.18945 7.605 8.18945Z"
        fill="white"
      />
    </svg>
  );
}

function BestAnswerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
        fill="#00AC85"
      />
      <path
        d="M6.61719 10.9985L9.53562 13.917L15.3828 8.08008"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function BadAnswerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
        fill="#FF475D"
      />
      <path
        d="M12.8035 8.05055V13.1305C12.8035 13.3305 12.7435 13.5255 12.6335 13.6905L11.2685 15.7205C11.0535 16.0455 10.5185 16.2755 10.0635 16.1055C9.57354 15.9405 9.24854 15.3905 9.35354 14.9005L9.61354 13.2655C9.63354 13.1155 9.59354 12.9805 9.50854 12.8755C9.42354 12.7805 9.29854 12.7205 9.16354 12.7205H7.10854C6.71354 12.7205 6.37354 12.5605 6.17354 12.2805C5.98354 12.0105 5.94854 11.6605 6.07354 11.3055L7.30354 7.56055C7.45854 6.94055 8.13354 6.43555 8.80354 6.43555H10.7535C11.0885 6.43555 11.5585 6.55055 11.7735 6.76555L12.4135 7.26055C12.6585 7.45055 12.8035 7.74055 12.8035 8.05055Z"
        fill="white"
      />
      <path
        d="M14.3947 14.105H14.9097C15.6847 14.105 15.9997 13.805 15.9997 13.065V8.04C15.9997 7.3 15.6847 7 14.9097 7H14.3947C13.6197 7 13.3047 7.3 13.3047 8.04V13.07C13.3047 13.805 13.6197 14.105 14.3947 14.105Z"
        fill="white"
      />
    </svg>
  );
}

export default function CandidateEvaluation({}) {
  const params = useParams();
  const [data, setData] = useState({});
  const [chartWidth, setChartWidth] = useState();
  const [totalIntScore, setTotalIntScore] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const [sampleAnswer, setSampleAnswer] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getEvalData();
  }, []);

  function scoreStyle(num) {
    if (num >= 7) {
      return ["#00ac851a", <BestAnswerIcon />];
    }
    if (num >= 4) {
      return ["#FFF5E6", <AverageAnswerIcon />];
    }
    return ["#FFEDEF", <BadAnswerIcon />];
  }
  $(window).on("load resize", getWidth);

  function getWidth() {
    setTimeout(() => {
      setChartWidth(parseInt($("main").width()) - 80 ?? 400);
    }, 500);
  }

  async function getEvalData() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_PUBLIC_EVALUATION + "new/" + params.id,
        1,
      );
      if (json?.data) {
        setData(json?.data);
        getWidth();
        setTotalIntScore(
          roundUpto2Digit(
            getInterviewScore(
              json?.data?.interviews?.map((d) => d?.averageScore),
            ),
          ) * 10,
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  function getInterviewScore(arr) {
    return (
      arr.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0) / arr.length
    );
  }

  return data ? (
    <div className="page col-xs-12 no-padding" id="interview-report">
      <header className="col-xs-12 navbar">
        <img src={recruiterLogo} alt="" />
      </header>
      <main className="main-content col-xs-12">
        {Object.keys(data).length > 0 && (
          <>
            <div className="top-section col-xs-12 no-padding">
              <h1>Interview report</h1>
              <div className="col-xs-12 no-padding user">
                <div className="left flex gap-4">
                  <InitialsLogo
                    className="img-responsive"
                    str={data?.candidateName ?? "C"}
                    width={40}
                    height={40}
                    bgRounded="50%"
                    fontSize={14}
                  />
                  <div className="name w-full">
                    {data?.candidateName ?? "Name"}
                  </div>
                  {/* {data?.candidate?.email && (
                      <div className="email">{data?.candidate?.email}</div>
                    )} */}
                </div>
                {data?.candidate?.resume && (
                  <div className="right">
                    <a
                      href={data?.candidate?.resume}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button>View resume</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="main-card col-xs-12 no-padding overall">
              <div className="overall-section col-xs-12 no-padding">
                <h2>Evaluation Summary</h2>
                <div
                  className="col-xs-12 col-md-6 no-padding"
                  style={{ maxWidth: 500 }}
                >
                  <GaugeChart
                    id="gauge-chart"
                    colors={[
                      "#FFA4AF",
                      "#FFCA80",
                      "#FF8F50",
                      "#A7D04E",
                      "#00AC85",
                    ]}
                    nrOfLevels={5}
                    percent={+totalIntScore / 100}
                  />
                </div>
                <div className="col-xs-12 col-md-6 no-padding">
                  {data?.interviews?.map((iter, idx) => (
                    <div
                      className="col-xs-12 no-padding scores"
                      key={"interview" + idx}
                    >
                      <h3>{iter?.title}</h3>
                      <div className="score-bar">
                        <div className="full col-xs-12" />
                        <div
                          className="score"
                          style={{
                            width: iter?.averageScore * 10 + "% ",
                          }}
                        />
                      </div>
                      <span>
                        {roundUpto2Digit(iter?.averageScore) * 10 + "%"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            {data?.interviews?.length > 0 && (
              <>
                <h2>Interview Scoring</h2>
                {data?.interviews?.map((iter, idx) => (
                  <div
                    className="main-card col-xs-12 no-padding interview"
                    key={`interview_${idx}`}
                  >
                    <div
                      className="col-xs-12 no-padding header  py-4 items-center cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        getWidth();
                        if ([...expanded].includes(idx)) {
                          setExpanded((prev) => {
                            const arr = [...prev].filter((d) => d != idx);
                            return arr;
                          });
                        } else {
                          setExpanded([...expanded, idx]);
                        }
                        console.log(expanded);
                      }}
                    >
                      <span>
                        <h2>{iter?.title}</h2>
                        {/* Interviewer / Recruiter details */}
                        {/* <span className="interviewer">
                          <InitialsLogo
                            str={iter?.recruiter?.name}
                            width={22}
                            height={22}
                            fontSize={12}
                          />
                          <span className="interviewer-name">
                            {iter?.recruiter?.name}
                          </span>
                          <span className="interviewer-text">Interviewer</span>
                        </span> */}
                      </span>
                      <span className="header-right !mt-0 ">
                        <span className="date">
                          <div className="label-text">Interview Date</div>
                          <div className="value">
                            {new Date(iter?.date).toDateString()}
                          </div>
                        </span>
                        {[...expanded].includes(idx) ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                              stroke="#252E4B"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 9L12 14.4844L17.5 9"
                              stroke="#252E4B"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="header col-xs-12 no-padding bottom-header">
                      {/* {iter?.averageScore && (
                        <div className="col-xs-12 no-padding overall-score">
                          <h2>{roundUpto2Digit(iter?.averageScore, 2)}</h2>
                          <h3>Overall score</h3>
                        </div>
                      )} */}
                      <div className="w-full">
                        <video id="video" width="1280" height="720" controls>
                          Your browser does not support the video player.
                          <source src={iter?.videoUrl} type="video/mp4" />
                        </video>
                      </div>
                      {/* <div id={"chart_" + idx} className="col-xs-12 no-padding">
                        <SkillsColumnChart
                          id={"interview_" + idx}
                          data={iter?.eval}
                          chartWidth={chartWidth}
                          // chartWidth={chartWidth}
                          chartHeight={400}
                          barGap={20}
                          chartMargin={{ left: 30, bottom: 60 }}
                          barColor="teal"
                          rectRounded={8}
                        />
                      </div> */}
                    </div>
                    {expanded.length > 0 && expanded.includes(idx) && (
                      <div className="accordion col-xs-12">
                        <h4 style={{ width: "90%" }}>Interview Q/A</h4>
                        {/* <h4 style={{ width: "10%", textAlign: "right" }}>
                          Score
                        </h4> */}
                        {iter?.candidateAnswers?.map((qu, x) => (
                          <div
                            className="question-sec col-xs-12"
                            key={"qu" + x}
                          >
                            <p className="question">
                              {x + 1}. {qu?.question}
                            </p>
                            {/* <div
                              className="score"
                              style={{ background: scoreStyle(qu?.score)[0] }}
                            >
                              {scoreStyle(qu?.score)[1]}
                              <span>{roundUpto2Digit(qu?.score) ?? 0}</span>
                            </div> */}
                            <div className="tags col-xs-12 no-padding">
                              {qu?.level && (
                                <span className="tag">
                                  <span className="circle"></span> {qu?.level}
                                </span>
                              )}
                              {qu?.topicTag?.length > 0 &&
                                qu.topicTag.map((topic) => (
                                  <span className="tag" key={topic}>
                                    {topic}
                                  </span>
                                ))}
                            </div>
                            {/* {qu?.answer && (
                              <button
                                className="sample"
                                data-toggle="modal"
                                data-target="#sample-ans-modal"
                                onClick={() => {
                                  setSampleAnswer(qu);
                                  setShowModal(true);
                                }}
                              >
                                Sample Answer
                              </button>
                            )} */}
                            <div className="answer-section col-xs-12 no-padding">
                              <p className="label"> Candidate answer</p>
                              <p className="answer">{qu?.answer}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </main>
      {showModal && (
        <Modal heading={"Sample Answer"} noBtn={true}>
          <div className="w-full relative rounded-lg bg-gray-200">
            <div className="w-full flex flex-row justify-between bg-green-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
              <span>
                <BestAnswerIcon />
                Best answer
              </span>
              <span>
                Score
                <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                  7-10
                </span>
              </span>
            </div>
            <div className="w-full p-2  text-gray-800 text-sm font-normal">
              <span className="w-full inline-block relative">
                {sampleAnswer?.answerHigh}
              </span>
              <div className="w-full relative">
                <span className="mt-2 border border-gray-200 shadow p-2 mr-4 inline-block">
                  {sampleAnswer?.answerHighReason}
                </span>
              </div>
            </div>
          </div>
          {sampleAnswer.answerMid && (
            <div className="w-full relative rounded-lg bg-gray-200">
              <div className="w-full flex flex-row justify-between bg-orange-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                <span>
                  <AverageAnswerIcon />
                  Average answer
                </span>
                <span>
                  Score
                  <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                    4-6
                  </span>
                </span>
              </div>
              <div className="w-full p-2  text-gray-800 text-sm font-normal">
                <span className="w-full inline-block relative">
                  {sampleAnswer?.answerMid}
                </span>
                <div className="w-full relative">
                  <span className="mt-2 border border-gray-200 shadow p-2 mr-4 inline-block">
                    {sampleAnswer?.answerMidReason}
                  </span>
                </div>
              </div>
            </div>
          )}
          {sampleAnswer.answerLow && (
            <div className="w-full relative rounded-lg bg-gray-200">
              <div className="w-full flex flex-row justify-between bg-red-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                <span>
                  <BadAnswerIcon />
                  Bad answer
                </span>
                <span>
                  Score
                  <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                    0-3
                  </span>
                </span>
              </div>
              <div className="w-full p-2  text-gray-800 text-sm font-normal">
                <span className="w-full inline-block relative">
                  {sampleAnswer?.answerLow}
                </span>
                <div className="w-full relative">
                  <span className="mt-2 border border-gray-200 shadow p-2 mr-4 inline-block">
                    {sampleAnswer?.answerLowReason}
                  </span>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  ) : (
    <div
      className="loader-section col-xs-12 no-padding"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PropagateLoader color="blue" />
    </div>
  );
}
