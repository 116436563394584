import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import GoogleCal from "../GoogleCal";
import { toast } from "react-toastify";
import successLogo from "../../assets/recruiter/group-100710.png";
// import InitialsLogo from "../../all/InitialsLogo";
import Modal from "../../modal/Modal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CandidatePageContext } from "../../../lib/context";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");

const scheduleData = {
  title: "",
  date: "",
  link: "",
  description: "",
  candidate: "",
  recruiter: "",
  nameRecruiter: "",
  guests: "",
  time: "",
  stage: "",
};
export default function ScheduleInterview({
  edit = false,
  interviewDetails = {},
}) {
  const { setShowModal, setReload } = useContext(CandidatePageContext);
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [formdetails, setFormdetails] = useState(scheduleData);
  const [message, setMessage] = useState({});
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const setInput = (key, value) =>
    setFormdetails({ ...formdetails, [key]: value });

  useEffect(() => {
    if (edit) {
      autofillData();
    } else if (candidate?.applicant?.email) {
      setInput(
        "candidate",
        candidate?.applicant?.email[0] ?? candidate?.applicant?.email,
      );
    }
  }, []);

  const autofillData = () => {
    console.log(edit, interviewDetails);
    setFormdetails({
      ...formdetails,
      ...interviewDetails,
      candidate: candidate?.applicant?.email[0] ?? candidate?.applicant?.email,
      date: new Date(interviewDetails?.date).toISOString().split("T")[0],
      time: new Date(interviewDetails?.date).toTimeString().split(" ")[0],
    });
  };

  const scheduleMeet = async (e) => {
    setLoading(true);
    setChecked(false);
    const json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_SCHEDULE_INTERVIEW + candidate?.matchProfile?._id,
      1,
      {
        title: formdetails.title,
        date: new Date(formdetails?.date + " " + formdetails?.time),
        link: formdetails.link,
        job: jobId,
        endtime: new Date(formdetails?.date + " " + formdetails?.endtime),
      },
    );
    if (json.data) {
      setFormdetails(scheduleData);
      if (edit)
        setMessage({
          type: "success",
          message: "Meeting re-scheduled successfully",
        });
      else
        setMessage({
          type: "success",
          message: "Meeting scheduled successfully",
        });
      setReload((prev) => prev + 1);
      setLoading(false);
      setTimeout(() => setShowModal(false), 3000);
    } else {
      setLoading(false);
      setMessage({
        type: "error",
        message: json?.error?.message,
      });
    }
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <Modal
      modalDismiss={() => setShowModal(false)}
      heading="Schedule Interview"
      subheading="Select/propose date and time to the candidate for an interview."
      onSave={scheduleMeet}
      defaultButtons={{
        primaryDisabled: !checked || loading,
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
      }}
    >
      <form className="flex flex-col gap-4">
        <div className="w-full">
          <label
            htmlFor="title"
            className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
          >
            Title
          </label>
          <input
            className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            type="text"
            name="title"
            value={formdetails.title}
            required="required"
            placeholder="SproutsAI interview"
            onChange={(event) => {
              setInput("title", event.target.value);
            }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="candidate"
            className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
          >
            Candidate email
          </label>
          <input
            className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            type="email"
            required
            name="candidate"
            defaultValue={candidate?.email ?? ""}
            value={formdetails?.candidate}
            onChange={(event) => {
              setInput("candidate", event.target.value);
            }}
          />
        </div>
        <div className="w-full flex flex-row gap-4">
          <div className="w-1/3">
            <label
              htmlFor="date"
              className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
            >
              Date
            </label>
            <input
              className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              type="date"
              min={today}
              name="date"
              value={formdetails?.date}
              required="required"
              onChange={(event) => {
                setInput("date", event.target.value);
              }}
            />
          </div>
          <div className="w-1/3">
            <label
              htmlFor="time"
              className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
            >
              Start time
            </label>
            <input
              className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              type="time"
              name="time"
              // value={new Date(interviewDetails?.date).toString().split(" ")[4]}
              value={formdetails?.time}
              required="required"
              onChange={(event) => {
                setInput("time", event.target.value);
                if (
                  formdetails?.endtime &&
                  event.target.value > formdetails?.endtime
                )
                  setMessage({
                    type: "error",
                    message: "Invalid value for start time.",
                  });
                else setMessage({});
              }}
            />
          </div>
          <div className="w-1/3">
            <label
              htmlFor="time"
              className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
            >
              End time
            </label>
            <input
              className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              type="time"
              name="endtime"
              // value={new Date(interviewDetails?.date).toString().split(" ")[4]}
              value={formdetails?.endtime}
              required="required"
              onChange={(event) => {
                setInput("endtime", event.target.value);
                if (formdetails?.time && event.target.value < formdetails?.time)
                  setMessage({
                    type: "error",
                    message: "Invalid value for end time.",
                  });
                else setMessage({});
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="meetinglink"
            className="w-full text-gray-800 text-sm not-italic font-normal after:content-[*] after:text-red-900 after:ml-2"
          >
            Meeting link
          </label>
          <input
            className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            type="text"
            name="meetinglink"
            value={formdetails?.link}
            required="required"
            onChange={(event) => {
              setInput("link", event.target.value);
            }}
          />
        </div>
        {formdetails?.link?.length > 0 &&
          formdetails?.candidate?.length > 0 &&
          formdetails?.title?.length > 0 &&
          formdetails?.date?.length > 0 &&
          formdetails?.time?.length > 0 &&
          formdetails?.endtime?.length > 0 && (
            <div className="w-full flex items-start gap-4">
              <input
                type="checkbox"
                className="w-5 h-5"
                id="confirm"
                onChange={() => setChecked((prev) => !prev)}
              />
              <label
                htmlFor="confirm"
                className="grow text-gray-800 text-sm not-italic font-normal"
              >
                Please confirm that the meeting details are accurate before
                sending the invite.
              </label>
            </div>
          )}
        {Object.entries(message)?.length > 0 && (
          <span
            className={
              message.type == "success"
                ? "text-green-800 font-normal text-sm"
                : "text-red-800 font-normal text-sm"
            }
          >
            {message.message}
          </span>
        )}
      </form>
    </Modal>
  );
}
