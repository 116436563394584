import React from "react";
import { useLocation } from "react-router";
import EndIntSvg from "../../assets/candidate/End.png";
import Modal from "../../modal/Modal";
const core = require("../../../lib/core");
function StartIntModal({ modalDismiss }) {
  const loc = useLocation();

  async function startInterview() {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_INTERVIEW_CREATE,
        1,
        {
          interviewId: getCookie("assessmentId"),
        },
      );

      if (json?.data) {
        console.log(json?.data);
        // window.open(json.data.meeting.link, '_blank');
        modalDismiss();
      }
    } catch {}
  }

  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  const modalElements = {
    heading: null,
    subheading: null,
    modalDismiss: modalDismiss,
    customButtons: [
      {
        className: "btn-primary btn-md !bg-blue hover:!bg-blue-900",
        type: "submit",
        label: (
          <>
            <span>Start interview</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </>
        ),
        function: () => {
          startInterview();
          modalDismiss();
        },
      },
    ],
  };

  return (
    <Modal {...modalElements}>
      <div className="w-full grid grid-cols-[60px_1fr] gap-y-2 gap-x-4">
        <img className="row-span-2" src={EndIntSvg} alt="" />
        <h1 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
          Assessment submitted successfully
        </h1>
        <h2 className="w-full text-gray-700 text-sm not-italic font-normal">
          Your assessment test are submitted. Next step is to get interview with
          our Sprouts AI bot.
        </h2>
      </div>
      <div className="border border-gray-600 rounded-lg p-2 grid grid-cols-[44px_1fr] gap-y-1 gap-x-4 items-center">
        <span className="row-span-2 w-full p-2.5 bg-gray-200 rounded-lg shadow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M17 10.8333L18.5409 9.18967C19.781 7.86697 22 8.74448 22 10.5576V14.4424C22 16.2555 19.781 17.133 18.5409 15.8103L17 14.1667V10.8333Z"
              stroke="#252E4B"
              strokeWidth="1.5"
            />
            <path
              d="M2.00019 9.50019C2.00008 7.29098 3.79098 5.5 6.00019 5.5H13C15.2091 5.5 17 7.29086 17 9.5V15.5C17 17.7091 15.2091 19.5 13 19.5H6.00047C3.79141 19.5 2.00057 17.7093 2.00047 15.5002L2.00019 9.50019Z"
              stroke="#252E4B"
              strokeWidth="1.5"
            />
          </svg>
        </span>
        <span className="text-base font-normal not-italic">
          Interview for Data Scientist Intern
        </span>
        <span className="text-gray-700 text-xs font-normal not-italic">
          You and Sprouts AI
        </span>
      </div>
    </Modal>
  );
}

export default StartIntModal;
