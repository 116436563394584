import React, { useEffect } from 'react'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import axios from 'axios'

const core = require("../../lib/core")



export default function GoogleCal() {

    const clientId = core.googleClientId

    // const clientId = '111557630781-b5mlfjt436km38v25pfgob21gvnlbnrt.apps.googleusercontent.com'
  useEffect(() => {
    // Google Auth
    const initGoogleClient = () => {
        gapi.client.init({
            clientId: clientId,
            scope: 'openid email profile https://www.googleapis.com/auth/calendar'
        })
    }

    gapi.load('client:auth2', initGoogleClient)

  }, [clientId])

  const responseGoogle = async (response) => {
    const { code } = response
    const json = await core.API(core.API_METHODS.POST, core.GOOGLE_API.POST_GOOGLE_TOKENS, 1, {
      code: code
    })
    if(json.data) {
      console.log(json?.data)
    }
    else {
      console.log(json?.error)
    }
  }

  const responseError = (error) => {
    console.log(error)
  }
  return (
    <div className="google-cal" >
    {/* <h1>SproutsAI Calendar Bot</h1> */}
    <GoogleLogin
    
      clientId={clientId}
      buttonText='Login to Authorize Calendar'
      onSuccess={responseGoogle}
      onFailure={responseError}
      cookiePolicy='single_host_origin'
      responseType='code'
      accessType='offline'
      scope='https://www.googleapis.com/auth/calendar'
      plugin_name='chat'
      />
  </div>
  )
}
