import * as d3 from "d3";
import { useEffect, useRef } from "react";

export default function ExperienceGantt({ dataList, ...props }) {
  const ref = useRef();
  const validateData = () => {
    if (!dataList) return [];
    let temp = dataList.filter(({ start, end }) => {
      // Ensure end exists
      if (!end) return false;

      // Create date objects from start and end
      const startDate = new Date(start);
      const endDate = new Date(end);

      // Add one month to startDate
      startDate.setMonth(startDate.getMonth() + 1);

      // Check if endDate is later than the adjusted startDate
      return endDate >= startDate;
    });
    return temp;
  };

  const barSize = 5;
  const data = validateData();
  // console.log(data);
  let svg, tooltip, innerHeight, innerWidth;

  useEffect(() => {
    try {
      innerWidth = d3
        ?.select(ref?.current)
        ?.node()
        ?.getBoundingClientRect()?.width;
      innerHeight = 34 * 7;

      svg = d3.select(ref?.current).attr("height", innerHeight + 20);
      svg.selectAll("g").remove();
      svg.selectAll("rect").remove();

      const xScale = axis();
      graph(xScale);
    } catch (err) {
      console.error(err);
    }
  });

  function graph(xScale) {
    const graph = svg
      .append("g")
      .classed("linegraph", true)
      .attr("width", innerWidth - 12)
      .attr("height", innerHeight);

    const groups = graph
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("id", (d) => "group" + new Date(d?.start).getFullYear())
      .attr("transform", (d, i) => {
        let y = barSize + 55;
        if (
          i < data?.length - 1 &&
          new Date(d?.start).getFullYear() <
            new Date(data[i + 1]?.end).getFullYear()
        ) {
          y += barSize + 75; // move to the next line if there is a conflict
        }
        return `translate(0, ${y})`;
      })
      .on("mouseover", (e, it) => {
        tooltip = d3
          .select("body")
          .append("div")
          .attr(
            "class",
            "tooltipDiv cursor-pointer absolute bg-white p-3 rounded-lg border border-gray-500 shadow max-w-[600px]",
          )
          .style("display", "block")
          .style("opacity", "1")
          .style("top", () => {
            if (e.x + 250 > window.innerWidth) {
              return e.y - 25 + "px";
            } else {
              return e.y + "px";
            }
          })
          .style("left", () => {
            if (e.x + 250 > window.innerWidth) {
              return e.x - 100 + "px";
            }
            return e.x + 25 + "px";
          });
        const htmlContent = `<div class="w-full">
     ${it?.position ? '<p class="text-base font-medium text-gray-700">' + it?.position + "</p>" : ""}
     ${it?.company ? '<p class="text-base font-normal text-gray-800">' + it?.company + "</p>" : ""}
      <p class="text-sm font-medium text-gray-800">${new Date(
        it?.start,
      ).getFullYear()} - ${it?.ongoing ? "Present" : new Date(it?.end).getFullYear()}</p>`;
        tooltip.html(htmlContent);
      })
      .on("mousemove", (e) => {
        tooltip
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
      })
      .on("mouseout", function () {
        d3.select(this).select("rect").style("outline", null);
        d3.select(this).select("circle").style("outline", null);
        d3.selectAll(".tooltipDiv").remove();
      });

    groups
      .append("rect")
      .attr("x", (d) => xScale(new Date(d?.start)) + 8)
      .attr("y", (_, i) => barSize + 16 - 2)
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", (d, i) => {
        if (i == 0) {
          return xScale(new Date(d?.end)) - xScale(new Date(d?.start));
        } else
          return xScale(new Date(d?.end)) - xScale(new Date(d?.start)) - 24;
      })
      .attr("height", barSize)
      .attr("fill", (_, i) => "#1369E9");

    groups
      .append("circle")
      .attr("cx", (d) => xScale(new Date(d?.start)) + 1)
      .attr("cy", (_, i) => barSize + 16)
      .attr("r", 9)
      .style("cursor", "pointer")
      .style("fill", "white")
      .style("z-index", 2)
      .style("stroke", (_, i) => "#1369E9")
      .style("stroke-width", "4");

    const rects = groups
      .append("rect")
      .attr("x", (d) => xScale(new Date(d?.start)) - 10)
      .attr("y", (d, i) => {
        if (i % 2 == 0) {
          if (
            i < data?.length - 1 &&
            new Date(d?.start).getFullYear() <
              new Date(data[i + 1]?.end).getFullYear()
          ) {
            return barSize + 34;
          }
          return barSize - 56;
        } else return barSize + 34;
      })
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("height", 50)
      .attr("stroke", "#e5e7eb")
      .attr("fill", "white");

    const texts = groups
      .append("text")
      .attr("x", (d) => xScale(new Date(d?.start)) - 4)
      .attr("y", (d, i) => {
        if (i % 2 == 0) {
          if (
            i < data?.length - 1 &&
            new Date(d?.start).getFullYear() <
              new Date(data[i + 1]?.end).getFullYear()
          ) {
            return barSize + 52;
          }
          return barSize - 34;
        } else return barSize + 52;
      })
      .attr("font-size", 14)
      .style("cursor", "pointer")
      .style("padding", "0 2px")
      .style("color", "black")
      .call(wrap, xScale, data);

    texts
      .append("tspan")
      .text(
        (d) =>
          new Date(d?.start).getFullYear() +
          " - " +
          (d?.ongoing ? "Present" : new Date(d?.end).getFullYear()),
      )
      .attr("x", (d) => xScale(new Date(d?.start)))
      .call(wrap, xScale, data);

    texts
      .append("tspan")
      .text((d) => {
        if (d?.position && d?.company) return d?.position + " @ " + d?.company;
        else if (d?.position) return d?.position;
        else if (d?.company) return d?.company;
      })
      .attr("x", (d) => xScale(new Date(d?.start)))
      .attr("dy", "1.2em")
      .call(wrap, xScale, data);

    rects.attr("width", function (d, i) {
      const textWidth = texts.nodes()[i].getComputedTextLength();
      if (i <= 1) {
        return xScale(new Date(data[0]?.end)) - xScale(new Date(d?.start)) - 2;
      } else {
        return Math.min(
          textWidth + 16,
          xScale(new Date(data[i - 2]?.start)) - xScale(new Date(d?.start)),
        );
      }
    });
  }

  const wrap = (nodes, xScale) => {
    nodes.each(function (a, i) {
      let width = 100;

      if (i <= 1) {
        width = xScale(new Date(data[0]?.end)) - xScale(new Date(a?.start));
      } else {
        width =
          xScale(new Date(data[i - 2]?.start)) - xScale(new Date(a?.start));
      }
      const self = d3.select(this);
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      while (textLength > width - 2 * 10 && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    });
  };

  function axis() {
    const minDate = d3.min(data, (d) => new Date(d?.start));
    const maxDate = d3.max(data, (d) => new Date(d?.end));
    const xScale = d3
      .scaleTime()
      .domain([new Date(minDate), new Date(maxDate)])
      .range([12, innerWidth - 36]);

    return xScale;
  }

  return (
    <svg
      id="experience-gantt"
      data-testid="experience-gantt"
      ref={ref}
      className="w-full"
    />
  );
}
