import React, { useEffect, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import InputBox from "../../../../layouts/InputBox";

function ApplicantsFilter({
  setCandidates,
  filterData,
  setShowFilter,
  searchName,
  setSearchName,
  setSearchTitle,
  searchTitle,
  sortby,
  setSortby,
  sortBytime,
  setSortByTime,
  minValue,
  set_minValue,
  maxValue,
  set_maxValue,
  filterLocation,
  setFilterLocation,
  fetchCandidates,
  favouriteFilter,
  setFavouriteFilter,
  setFilterCount,
}) {
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const [resetFlag, setResetFlag] = useState(false);

  const handleReset = () => {
    setSearchName("");
    setSearchTitle("");
    setSortby("");
    setSortByTime("");
    setFavouriteFilter(false);
    set_minValue(0);
    set_maxValue(100);
    setFilterLocation("");

    // Set reset flag to true after resetting all values
    setResetFlag(true);
  };

  useEffect(() => {
    // Call fetchCandidates if all values are reset
    if (resetFlag) {
      fetchCandidates();
      setShowFilter(false);
      setFilterCount(0);
      // Reset the flag after calling fetchCandidates
      setResetFlag(false);
    }
  }, [resetFlag]); // useEffect dependency
  return (
    <section
      id="candidateDrawer"
      className="relative block z-50 w-screen"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="pointer-events-none fixed inset-y-0 right-0 flex w-1/3 max-w-full pl-10 rounded-[18px]">
        <div className="pointer-events-auto relative w-full">
          <div className="flex h-full rounded-[18px] flex-col overflow-y-auto bg-white py-[24px] px-[22px] shadow-xl">
            <h5 className="text-[22px] text-[#16182F] font-semibold not-italic leading-[34px]">
              Filters
            </h5>

            <div className="mt-[21px]">
              <InputBox
                placeholder={"Search candidates"}
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>

            {/* Sort */}
            <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
              <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                Sortby
              </h6>
              <div className="mt-[12px] flex justify-between">
                <div className="flex justify-between w-full">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      id="recentsort"
                      name="sort"
                      checked={sortby === "mostRecent"}
                      onChange={(e) => {
                        setSortby("mostRecent");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="recentsort"
                    >
                      Most recent
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="sort"
                      id="relevantsort"
                      checked={sortby === "mostRelevant"}
                      onChange={(e) => {
                        setSortby("mostRelevant");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="relevantsort"
                    >
                      Most relevant
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
              <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800"></h6>
              <div className="mt-[12px] flex justify-between">
                <div className="flex justify-between w-full">
                  <div className="flex items-center ">
                    <input
                      type="checkbox"
                      className=""
                      name="sort"
                      checked={favouriteFilter}
                      onChange={(e) => {
                        setFavouriteFilter(e.target.checked);
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="anytime"
                    >
                      Favorites candidates
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
              <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                Match score
              </h6>
              <div className="mt-[20px] ">
                <div>
                  <MultiRangeSlider
                    min={0}
                    max={100}
                    step={5}
                    minValue={minValue}
                    maxValue={maxValue}
                    label={true}
                    baseClassName="multi-range-slider"
                    className=".multi-range-slider .thumb::before"
                    ruler={false}
                    onChange={handleInput}
                    barInnerColor={"#00AC85"}
                    barLeftColor={"#EFF0F2"}
                    barLeftShadow={"none"}
                    barRightShadow={"none"}
                    barRightColor={"#EFF0F2"}
                    thumbLeftColor={"#FFF"}
                    thumbRightColor={"#FFF"}
                    style={{ boxShadow: "none", border: "none" }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span className="text-gray-800 text-sm font-normal not-italic">
                      {minValue}%
                    </span>
                    <span className="text-gray-800 text-sm font-normal not-italic">
                      {maxValue}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
              <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                Anytime
              </h6>

              <div className="flex justify-between">
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    name="duration"
                    id="anytime"
                    checked={sortBytime === "anyTime"}
                    onChange={(e) => {
                      setSortByTime("anyTime");
                    }}
                  />
                  <label
                    className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                    htmlFor="anytime"
                  >
                    Any time
                  </label>
                </div>
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    name="duration"
                    id="pastMonth"
                    checked={sortBytime === "pastMonth"}
                    onChange={(e) => {
                      setSortByTime("pastMonth");
                    }}
                  />
                  <label
                    className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                    htmlFor="pastMonth"
                  >
                    Past month
                  </label>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    name="duration"
                    id="pastWeek"
                    checked={sortBytime === "pastWeek"}
                    onChange={(e) => {
                      setSortByTime("pastWeek");
                    }}
                  />
                  <label
                    className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                    htmlFor="pastWeek"
                  >
                    Past week
                  </label>
                </div>
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    name="duration"
                    id="past2Hours"
                    checked={sortBytime === "past2Hours"}
                    onChange={(e) => {
                      setSortByTime("past2Hours");
                    }}
                  />
                  <label
                    className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                    htmlFor="past2Hours"
                  >
                    Past 2 hours
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
              <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                Location
              </h6>
              <InputBox
                placeholder={"Search location"}
                value={filterLocation}
                onChange={(e) => setFilterLocation(e.target.value)}
              />
            </div>

            <div className="flex justify-end py-[16px]">
              <button
                className="text-sm font-normal not-italic leading-[22px] text-gray-800 mr-[30px]"
                onClick={(e) => {
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                className="py-[4px] px-[8px] rounded-[8px]   shadow-jobPostButtonShadow bg-[#1369E9] border border-[#1369E9] text-sm font-normal not-italic leading-[24px] text-[#FFF]"
                onClick={(e) => {
                  fetchCandidates();
                  setShowFilter(false);
                }}
              >
                Show results
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ApplicantsFilter;
