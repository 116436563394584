import React, { useEffect, useState, useRef } from "react";

const Dropdown = ({
  removeDefaultBtnStyle = false,
  removeDefaultListStyle = false,
  removeDefaultDropdownStyle = false,
  buttonStyle,
  buttonLabel = "",
  buttonAction = () => {},
  dropdownItems,
  listAction = () => {},
  listAlign = "right",
  dropdownStyle = "",
  listItemStyle = "",
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropType, setDropType] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    buttonAction();
  };

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setMenuOpen(false);
      }

      if (menuRef.current && menuRef.current.contains(e.target)) {
        setDropType(
          window?.innerHeight - menuRef?.current?.getBoundingClientRect()?.y <=
            dropdownItems?.length * 40 + 50
        );
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="relative w-auto inline-flex flex-col" ref={menuRef}>
      <button
        className={
          removeDefaultBtnStyle
            ? buttonStyle
            : "btn-md btn-sec border-button rounded-lg text-sm font-semibold text-gray-900 group border border-gray-500 bg-white group " +
              buttonStyle +
              (toggleMenu && "active")
        }
        onClick={toggleMenu}
      >
        {buttonLabel}
      </button>
      {menuOpen && dropdownItems?.length > 0 && (
        <ul
          className={
            removeDefaultDropdownStyle
              ? dropdownStyle
              : `${listAlign}-0 w-48 z-[333] max-h-[400px] overflow-auto scrollbar-hide p-1 list-style-none bg-white shadow-menuOptionShadow rounded-lg absolute origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none ${
                  dropType ? "bottom-12 top-auto" : "top-12"
                } ${dropdownStyle}`
          }
        >
          {dropdownItems?.map((item, key) => (
            <li
              key={key}
              className={
                removeDefaultListStyle
                  ? listItemStyle
                  : `w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 group ${
                      item?.style ?? ""
                    }  ${listItemStyle ?? ""} ${
                      item?.activeCondition
                        ? "font-medium bg-blue-100 selected "
                        : "font-normal bg-transparent"
                    }`
              }
              onClick={(e) => {
                if (listAction) listAction(e, item, buttonLabel);
                if (item?.action) item?.action();
                setMenuOpen(false);
              }}
            >
              {item?.label ?? item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
