import React, { useEffect, useState } from "react";
import companyLogo from "../../assets/Enterprise.png";
import { getTypeColor, getTypeName } from "./TypesButton";
var parse = require("html-react-parser");
function ReviewPage({
  formData,
  bonus,
  benefits,
  skills,
  preferArr,
  pipeline,
  EditFunction,
  JobTypeArray,
  departments,
  jobLocation,
}) {
  const [experience, setExperience] = useState("");
  var categories = [];
  if (preferArr?.length > 0) {
    categories = [...new Set(preferArr.map((d) => d?.category))];
  }
  useEffect(() => {
    formatExperienceValue();
  }, []);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  const formatExperienceValue = () => {
    setExperience("");
    if (
      typeof formData?.experienceLevel == "string" &&
      String(formData?.experienceLevel)?.length > 0
    ) {
      let matches = String(formData?.experienceLevel)?.match(/\d+/g);
      let exp =
        matches?.length == 1
          ? String(formData?.experienceLevel?.min) + "+ years"
          : matches?.length >= 1
            ? "Upto " + String(formData?.experienceLevel?.min) + " years"
            : formData?.experienceLevel;
      setExperience(exp);
      return;
    }
    // min present but not max
    if (
      +formData?.experienceLevel?.min > -1 &&
      String(formData?.experienceLevel?.min)?.length > 0 &&
      !(
        +formData?.experienceLevel?.max > -1 &&
        String(formData?.experienceLevel?.max)?.length > 0
      )
    ) {
      setExperience(String(formData?.experienceLevel?.min) + "+ years");
      return;
    }
    // min present but not max
    if (
      +formData?.experienceLevel?.max > -1 &&
      String(formData?.experienceLevel?.max)?.length > 0 &&
      !(
        +formData?.experienceLevel?.min > -1 &&
        String(formData?.experienceLevel?.min)?.length > 0
      )
    ) {
      setExperience(
        "Upto " + String(formData?.experienceLevel?.min) + " years",
      );
      return;
    }
    // setExperience(String(formData?.experienceLevel?.min) + " years");
    if (
      +formData?.experienceLevel?.min > -1 &&
      String(formData?.experienceLevel?.max)?.length > 0 &&
      +formData?.experienceLevel?.max > -1
    ) {
      if (formData?.experienceLevel?.min == formData?.experienceLevel?.max) {
        setExperience(String(formData?.experienceLevel?.min) + " years");
      }
      setExperience(
        String(formData?.experienceLevel?.min) +
          " - " +
          String(formData?.experienceLevel?.max) +
          " years",
      );
    }
  };
  return (
    <div className="reviewpage col-xs-12 no-padding" id="review-page">
      <div className="review-page-section col-xs-12 no-padding job-position-detail">
        <div className="col-xs-12 no-padding review-page-section-header">
          {/* <h5>Do you offer any other compensation pay?</h5> */}
          <img
            src={companyLogo}
            alt=""
            style={{ width: "44px", height: "44px" }}
          />
          <button
            type="button"
            onClick={(e) => {
              EditFunction("jobDetailsSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        <h4 className="col-xs-12 no-padding" style={{ marginTop: "12px" }}>
          {formData?.position}
        </h4>
        <div className="col-xs-12 no-padding location-info">
          <span>{formData?.company}</span>
          {jobLocation
            ?.filter((item) => item?.status === true)
            ?.map((item, index) => (
              <span key={index}>{item?.name}</span>
            ))}
        </div>

        <div className="col-xs-12 no-padding job-detail-info">
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title whitespace-nowrap">
              Job Type :
            </span>
            <span
              className="job-title-info-value overflow-hidden text-ellipsis whitespace-nowrap pr-2"
              onMouseOver={(e) => {
                var overflowed = e.target.scrollWidth > e.target.clientWidth;
                e.target.title = overflowed ? e.target.textContent : "";
              }}
              onMouseOut={(e) => (e.target.title = "")}
            >
              {JobTypeArray?.filter((item) => item?.status === true)
                ?.map((item) => item?.type)
                ?.join(", ")}
            </span>
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title whitespace-nowrap">
              Department :
            </span>
            {/* <span className="job-title-info-value">{formData?.department}</span> */}
            <span
              className="job-title-info-value overflow-hidden text-ellipsis whitespace-nowrap pr-2"
              onMouseOver={(e) => {
                var overflowed = e.target.scrollWidth > e.target.clientWidth;
                e.target.title = overflowed ? e.target.textContent : "";
              }}
              onMouseOut={(e) => (e.target.title = "")}
            >
              {departments
                ?.filter((item) => item?.status === true)
                ?.map((item) => item?.name)
                ?.join(", ")}
            </span>
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title">Notice period :</span>
            <span className="job-title-info-value">
              {formData?.noticePeriod?.data}
            </span>
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title">Workplace type :</span>
            <span className="job-title-info-value">{formData?.workplace}</span>
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title">Experience :</span>
            {!experience?.includes("undefined") > 0 && (
              <span className="job-title-info-value">{experience}</span>
            )}
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title">Expected salary :</span>
            {formData?.salary &&
            !String(formData?.salary)?.includes("null") &&
            Object.entries(formData?.salary)?.length > 1 ? (
              <span className="job-title-info-value">{`${
                formData?.salary?.currency ? formData?.salary?.currency : "$"
              }${formData?.salary?.min} - ${
                formData?.salary?.currency ? formData?.salary?.currency : "$"
              }${formData?.salary?.max}`}</span>
            ) : (
              <span className="pl-2">-</span>
            )}
          </div>
          <div className="col-xs-6 no-padding title-row">
            <span className="job-detail-info-title">Expected Equity :</span>
            {formData?.equity &&
            Object.entries(formData?.equity)?.length > 1 ? (
              <span className="job-title-info-value">{`${formData?.equity?.min} - ${formData?.equity?.max} %`}</span>
            ) : (
              <span className="pl-2">-</span>
            )}
          </div>
        </div>
      </div>
      <div className="review-page-section section-border col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Do you offer any other compensation pay?</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("compensationDetailsSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        <div className="col-xs-12 no-padding review-page-compensation-detail">
          {bonus
            ?.filter((item) => item?.status === true)
            ?.map((item) => (
              <span>{item?.benifits}</span>
            ))}
        </div>
      </div>
      <div className="review-page-section section-border col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Benefits offered</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("compensationDetailsSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        <div className="col-xs-12 no-padding review-page-compensation-detail">
          {benefits
            ?.filter((item) => item.status === true)
            .map((item) => (
              <span>{item?.benifits}</span>
            ))}
        </div>
      </div>
      <div className="review-page-section section-border col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Skills</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("skillsSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>

        {skills?.length > 0 &&
          skills?.map((item, index) => (
            <div className="col-xs-12 no-padding review-page-criteria-detail mt-[12px]">
              <h6 className="col-xs-12 no-padding criteria-text mb-[5px]">
                {item?.criteria ? item?.criteria : item?.label}
              </h6>
              {item?.skills ? (
                <>
                  <div className="col-xs-12 no-padding review-page-criteria-detail-content ">
                    {item?.skills?.map((skill, index) => (
                      <div className="col-xs-12 no-padding skills-row">
                        <span className="col-xs-6 no-padding skills-text">
                          {skill?.label}
                        </span>
                        <div className="col-xs-5 no-padding range-section">
                          <span
                            style={{ width: `${skill?.value * 10}%` }}
                          ></span>
                        </div>
                        <span className="value-text">{skill?.value}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xs-12 no-padding review-page-criteria-detail-content">
                    {item?.keySkills?.map((skill, index) => (
                      <div className="col-xs-12 no-padding skills-row">
                        <span className="col-xs-6 no-padding skills-text">
                          {skill?.label}
                        </span>
                        <div className="col-xs-5 no-padding range-section">
                          <span
                            style={{ width: `${skill?.value * 10}%` }}
                          ></span>
                        </div>
                        <span className="value-text">{skill?.value}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
      <div className="review-page-section section-border col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Preferences</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("preferenceSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        {preferArr?.length > 0 &&
          categories.map((cat, i) => (
            <div
              key={i}
              className="col-xs-12 no-padding review-page-criteria-detail mt-[12px]"
            >
              <h6 className="col-xs-12 no-padding criteria-text mb-[5px]">
                {cat}
              </h6>
              {preferArr
                .filter((d) => d.category == cat)
                .map((preference, it) => (
                  <div
                    key={it}
                    className="col-xs-12 no-padding review-page-criteria-detail-content"
                  >
                    <div className="col-xs-12 no-padding skills-row">
                      <span className="col-xs-4 no-padding skills-text">
                        {preference?.label}
                      </span>
                      <div className="col-xs-4 no-padding range-section">
                        <span
                          style={{
                            width: `${Math.abs(preference?.value) * 10}%`,
                            background: getTypeColor(preference?.type),
                          }}
                        ></span>
                      </div>
                      <span className="value-text">{preference?.value}</span>
                      <span className="value-text w-[100px]">
                        {getTypeName(preference?.type)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
      <div className="review-page-section section-border col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Job details</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("jobDetailsSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        {/* <span className='paragraph-text'> */}
        <div className="">
          <p className="text-textColor1 text-sm not-italic font-normal job-description">
            {formData?.description ? (
              formData?.description?.includes("<body>") ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formData?.description?.split("&lt;")?.join("<"),
                  }}
                ></div>
              ) : (
                parse(formData?.description?.split("&lt;")?.join("<"))
              )
            ) : (
              "No Content"
            )}
          </p>

          {/* {parse(formData?.description?.split("&lt;")?.join("<"))} */}
        </div>

        {/* </span> */}
      </div>
      <div className="review-page-section">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Roles and responsibilities</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("candidateSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        {formData?.roles && (
          <span className="paragraph-text">{parse(formData?.roles)}</span>
        )}
      </div>
      <div className="review-page-section  col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Experience and Education</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("candidateSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        <span className="paragraph-text">
          {parse(formData?.experienceEducation)}
        </span>
      </div>

      <div className="review-page-section  col-xs-12 no-padding">
        <div className="col-xs-12 no-padding review-page-section-header">
          <h5>Hiring pipeline</h5>
          <button
            type="button"
            onClick={(e) => {
              EditFunction("hiringPipelineSection");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="left icon">
                <path
                  id="Vector"
                  d="M7.19324 2.91557L9.08449 4.80682M1.5 10.5L3.33639 10.1504C3.72576 10.0763 4.08384 9.88679 4.36412 9.60652L10.0919 3.87877C10.636 3.33459 10.636 2.45231 10.0919 1.90813C9.54769 1.36396 8.66541 1.36396 8.12123 1.90813L2.39348 7.63588C2.11321 7.91616 1.9237 8.27424 1.84958 8.66361L1.5 10.5Z"
                  stroke="#00AC85"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Edit
          </button>
        </div>
        <div className="col-xs-12 no-padding ">
          <div className="col-xs-12 data-section no-padding">
            <div className="col-xs-12 no-padding input-data-section">
              <div
                className="col-xs-12 no-padding Hiring-pipeline-section"
                id=""
              >
                <div className="col-xs-12 no-padding">
                  {pipeline?.map((skill, it) => (
                    <div className="section-skills col-xs-12 no-padding">
                      <div className="col-xs-12 section-header no-padding">
                        <div className=" label-input col-xs-12 no-padding">
                          <div className="text-sextion col-xs-12 no-padding">
                            <span className="text">{skill?.stage}</span>
                            <span className="text-detail mb-[8px]">
                              {skill?.text}
                            </span>
                            {skill?.subStage?.map((item, idx) => (
                              <div
                                key={idx}
                                className="w-full bg-bg1 border-t border-l border-r last-of-type:border-b-[1px] first-of-type:rounded-t-[8px] last-of-type:rounded-b-[8px] border-[#E1E1EE]  py-[8px] px-[11px]"
                              >
                                <span>{`${idx + 1}. ${item}`}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPage;
