import { useState } from "react";
import $ from "jquery";
import { PropagateLoader } from "react-spinners";
const core = require("../../../lib/core");

export default function Autocomplete({
  type,
  defaultValue,
  value,
  idName,
  name,
  data,
  searchFunction,
  onEnterFunction,
  addFunction,
  onSelectOption,
  required,
  selectionFunction,
  disabled,
  searchType,
  setShowCompanyPage,
  showCompany,
  companyNew,
  className,
  inputClassName,
  ...props
}) {
  const [hide, setHide] = useState([]);
  $(document).on("click", function (e) {
    if ($(e.target).is("#" + idName + " .auto-complete-input") === false) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  return (
    <div id={idName} className={`col-xs-12 no-padding auto-complete relative ${className}`}>
      <input
      // style={{border:(searchType == "company" && !companyNew ) ? "1px solid var(--red-red-9, #FF475D)":null}}
        className={"col-xs-12 no-padding auto-complete-input " + inputClassName}
        type={type}
        name={name}
        value={value}
        required={required}
        placeholder={defaultValue}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onKeyDown={onEnterFunction}
        onClick={(e) => {
          if (!disabled) {
            searchFunction(e.target.value);
          }
        }}
        disabled={disabled}
        onChange={(e) => {
          if (!disabled) {
            searchFunction(e.target.value);
            selectionFunction(name, e.target.value);
          }
        }}
      />
      {!hide && data?.length >= (searchType == "company" ? 0  : 1) && value?.length > 0 && (
        <ul className="auto-complete-list absolute bg-white  no-list-style py-[20px]  rounded-lg  shadow-menuOptionShadow left-0">
          {data[0] == "loader" ? (
            <span>
              <PropagateLoader color="blue" />
            </span>
          ) : (
            <>
              {data?.map((item, i) => (
                <li
                  key={i}
                  className="match-value px-[20px] py-[5px]"
                  onClick={() => {
                    selectionFunction(name, item);
                    if(searchType == "company"){
                      setShowCompanyPage(false)
                    }
                    onSelectOption(item?.name ?? item)
                    
                  }}
                >
                  {item?.name ? item?.name : item?.email ?  item?.email : item }
                </li>
              ))}
              {searchType == "company" && data?.length == 0  &&
                <li className="company-add-button" onClick={() => { setShowCompanyPage(true) }}>+ Add company</li>
              }
            </>
          )}
        </ul>
      )}
    </div>
  );
}
