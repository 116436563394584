import Editor from "@monaco-editor/react";
import { useState } from "react";
import AssessmentButtons from "./AssessmentButtons";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutputWindow from "../tempFolder/OutputWindow";
import { useEffect } from "react";
import RuncodeOutput from "../tempFolder/RuncodeOutput";
import { PropagateLoader } from "react-spinners";
const core = require("../../../lib/core");

export default function CodingSection({
  interviewId,
  codeQsn,
  setAnswers,
  navigation,
  setPassedCases,
  videoRef,
}) {
  const [value, setValue] = useState();
  const [qno, setQno] = useState(0);
  const [language, setLanguage] = useState(
    new Array(codeQsn?.length).fill('{ "id": 71, "name": "python" }')
  );
  const [inputValues, setInputValues] = useState(
    codeQsn.map((val, index) => val?.data?.skeletons[language[index]])
  );
  const [processing, setProcessing] = useState(null);
  const [testOutput, setTestOutput] = useState({});
  // const [PassedCases, setPassedCases] = useState(
  //   new Array(codeQsn?.length).fill(0) 
  // );


  // console.log(inputValues)



  useEffect(() => {
    if (codeQsn?.length > 0) {
      setLanguage(
        new Array(codeQsn?.length).fill('{ "id": 71, "name": "python" }')
      );

      setInputValues(codeQsn.map((val, index) => val?.data?.skeletons?.python));
    }
  }, [codeQsn]);

  function handleChangeInput(val) {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] = val;
      return newInputValues;
    });
    setAnswers(val, qno);
    setValue(val);
  }

  function handleChangeLanguage(val) {
    setLanguage((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] = val;
      return newInputValues;
    });
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] =
        codeQsn[qno]?.data?.skeletons[JSON.parse(val)?.name];
      return newInputValues;
    });
  }


  const [reload, setReload] = useState(0)


  function encode(str) {
    return btoa(unescape(encodeURIComponent(str || "")));
  }

  function decode(bytes) {
    var escaped = escape(atob(bytes || ""));
    try {
      return decodeURIComponent(escaped);
    } catch {
      return unescape(escaped);
    }
  }



  const runCode = async (api, qId, test) => {





    const requestData = {
      "output": "sourav",
      "testcase": "sourav"
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };

    // fetch('http://compiler.sproutsai.com/compare', requestOptions)
    //     .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //     })
    //     .then(data => {
    //         console.log(data);
    //         // Handle the response data as needed
    //     })
    //     .catch(error => {
    //         console.error('There has been a problem with your fetch operation:', error);
    //         // Handle errors
    //     });

    let json = await core.API(core.API_METHODS.POST, api, 1, {

      code: value,
      language: JSON.parse(language[qno])?.name,
      challenge: qId,
      interviewId: interviewId,
    });
    if (json?.data) {
      setProcessing(false);
      console.log(json?.data)
      if (test) {
        setPassedCases(json?.data?.testCases, qno)
        setTestOutput(json.data.testCases);
        setReload(reload + 1)
      } else {
        setTestOutput(json.data);
      }
    } else {
      toast.error(json?.error?.message);
      setProcessing(null);
      console.log(json?.error);
    }
  };





  function renderExpectedOutput(expectedOutput) {
    if (Array.isArray(expectedOutput)) {
      return "[" + expectedOutput.join(", ") + "]";
    } else if (typeof expectedOutput === "object") {
      return JSON.stringify(expectedOutput);
    } else {
      return String(expectedOutput);
    }
  }



  return (
    <section className="col-xs-10 " id="codingSection">
      <div className="col-xs-12 col-md-3 left-section">
        <div className="col-xs-12 no-padding question-sec">
          <h4>Question {qno + 1}</h4>
          <h3>{codeQsn[qno]?.problem}</h3>
          <div className="section-data col-xs-12 no-padding">
            {codeQsn[qno]?.data["function description"]}
          </div>
        </div>

        <div className="next-button-sec col-xs-12 no-padding">
          <AssessmentButtons
            questions={codeQsn}
            currentQuestion={qno}
            setCurrentQuestion={setQno}
            navigation={navigation}
            setTestOutput={setTestOutput}
          />
        </div>
      </div>
      <div className="col-xs-12 col-md-9 no-padding right-section">
        <div className="col-xs-12 editor-section ">
          <select
            onChange={(e) => handleChangeLanguage(e.target.value)}
            value={language[qno]}
          >
            <option value='{"id": 71, "name": "python"}'>Python 3</option>
            <option value='{"id": 63, "name": "javascript"}'>JS</option>
            <option value='{"id": 50, "name": "c"}'>C</option>
            <option value='{"id": 54, "name": "cpp"}'>C++</option>
          </select>
          <Editor
            language={
              language.length > 0 ? JSON.parse(language[qno])?.name : ""
            }
            value={inputValues[qno]}
            textAlign={"left"}
            onChange={(val) => handleChangeInput(val)}
            theme="vs-dark"
            options={{
              cursorStyle: "line",
              renderWhitespace: "all",
              formatOnPaste: false,
              formatOnType: true,
              wordWrap: true,
              autoIndent: "full",
              minimap: { enabled: false },
            }}
          />
          <div className="col-xs-12 no-padding button-section">
            <button
              onClick={() => {
                runCode(
                  core.CANDIDATE_API.POST_RUN_CODE,
                  codeQsn[qno].data.id,
                  false
                );
              }}
            >
              Run code
            </button>
            <button
              onClick={(e) => {
                runCode(
                  core.CANDIDATE_API.POST_TEST_CODE,
                  codeQsn[qno].data.id,
                  true
                );
              }}
            >
              Run tests
            </button>
          </div>
        </div>
        <div className="col-xs-12 no-padding output-section">
          {processing != null &&
            (processing ? (
              <div className="loader-section col-xs-12 no-padding">
                <PropagateLoader color="blue" />
              </div>
            ) : (
              <>
                <div className="col-xs-3 left-code-output no-padding">


                    {/* <div
                      className={
                        testOutput?.testcase
                          ? "list-item passed"
                          : "list-item failed"
                      }
                    >
                      {testOutput?.testcase ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1400_119898)">
                            <path
                              d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                              stroke="#80BB01"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1400_119898">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                            stroke="#FF475D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <h4>Sample input</h4>
                    </div> */}
               

                  
                  {Array.isArray(testOutput) ? (
                    testOutput.map((item, index) => (
                      <div
                        className={
                          item.testPassed
                            ? "list-item passed"
                            : "list-item failed"
                        }
                        key={index}
                      >
                        {item.testPassed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_1400_119898)">
                              <path
                                d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                                stroke="#80BB01"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1400_119898">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                              stroke="#FF475D"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <h4>{"Test " + (index + 1)}</h4>
                        {index > 0 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                          >
                            <path d="M 8 1 C 5.796875 1 4 2.796875 4 5 L 4 6 L 3.5 6 C 2.675781 6 2 6.675781 2 7.5 L 2 12.5 C 2 13.324219 2.675781 14 3.5 14 L 12.5 14 C 13.324219 14 14 13.324219 14 12.5 L 14 7.5 C 14 6.675781 13.324219 6 12.5 6 L 12 6 L 12 5 C 12 2.796875 10.203125 1 8 1 Z M 8 2 C 9.664063 2 11 3.335938 11 5 L 11 6 L 5 6 L 5 5 C 5 3.335938 6.335938 2 8 2 Z M 3.5 7 L 12.5 7 C 12.78125 7 13 7.21875 13 7.5 L 13 12.5 C 13 12.78125 12.78125 13 12.5 13 L 3.5 13 C 3.21875 13 3 12.78125 3 12.5 L 3 7.5 C 3 7.21875 3.21875 7 3.5 7 Z" />
                          </svg>
                        )}
                      </div>
                    ))
                  ) : (
                    <div
                      className={
                        testOutput.testPassed
                          ? "list-item passed"
                          : "list-item failed"
                      }
                    >
                      {testOutput.testPassed ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1400_119898)">
                            <path
                              d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                              stroke="#80BB01"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1400_119898">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                            stroke="#FF475D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <h4>Sample input</h4>
                    </div>
                  )}
                </div>
                <div className="col-xs-9 right-code-output">

                  {/* {testOutput?.stderr?.length <= 1 ? (
                    <>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Sample input</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.input)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Expected output</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.output)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Actual output</h4>
                        <span className="response-section col-xs-12">
                          {testOutput?.stdout}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="data-section col-xs-12 no-padding">
                      <h4 className="heading-section">Failed!</h4>
                      <span className="response-section col-xs-12">
                        {testOutput?.stderr}
                      </span>
                    </div>
                  )} */}



                  {!Array.isArray(testOutput) ? (
                     testOutput?.stderr==="" ? (
                      <>
                        <div className="data-section col-xs-12 no-padding">
                          <h4 className="heading-section">Sample input</h4>
                          <span className="response-section col-xs-12">
                            {JSON.stringify(testOutput?.input)}
                          </span>
                        </div>
                        <div className="data-section col-xs-12 no-padding">
                          <h4 className="heading-section">Expected output</h4>
                          <span className="response-section col-xs-12">
                            {JSON.stringify(testOutput?.expectedOutput)}
                          </span>
                        </div>
                        <div className="data-section col-xs-12 no-padding">
                          <h4 className="heading-section">Actual output</h4>
                          <span className="response-section col-xs-12">
                          {(testOutput?.output)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Failed!</h4>
                        <span className="response-section col-xs-12">
                          {testOutput?.stderr}
                        </span>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Input</h4>
                        <span className="response-section col-xs-12">
                          {
                            JSON.stringify(testOutput[testOutput?.findIndex((testCase) => Array?.isArray(testCase?.input))]?.input)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Expected output</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput[testOutput?.findIndex((testCase) => Array?.isArray(testCase?.input))]?.expectedOutput)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Actual output</h4>
                        <span className="response-section col-xs-12">
                          {(testOutput[testOutput?.findIndex((testCase) => Array?.isArray(testCase?.input))]?.output)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            ))}
        </div>
      </div>
    </section>
  );
}



