import React from "react";

function InputBox({ required, type, width, size, className, ...props }) {
  return (
    <>
      <input
        className={`w-[${width ? width : "100%"}] ${className} h-[${size === "medium" ? "40px" : size === "small" ? "32px" : "48px"}]  border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
        type={type ?? "text"}
        required={required ?? false}
        {...props}
      />
    </>
  );
}

export default InputBox;
