// Node Imports
import { useEffect, createContext } from "react";
import jwt_decode from "jwt-decode";
import EmailValidator from "email-validator";
import Cookie from "universal-cookie";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch);

// Is Production
export const production = () => {
  return (
    process.env.REACT_APP_ENV !== "local" &&
    process.env.REACT_APP_ENV !== "development" &&
    process.env.REACT_APP_ENV !== "demo"
  );
};

// Universal Cookie
const cookie = new Cookie();

// Get Data
export const getData = (name) => {
  return cookie.get(name);
};

// Set Data
export const setData = (name, value) => {
  cookie.set(name, value);
};

// Delete Data
export const deleteData = (name) => {
  cookie.remove(name);
};

// User Context
export const UserContext = createContext(null);
// Job Form Context
export const JobFormContext = createContext(null);

// Check Auth
export const checkAuth = () => {
  let jwt = getData(data.ACCESS_TOKEN);
  let uid = getData(data.UID);
  if (jwt && uid) {
    try {
      let decoded = jwt_decode(jwt);
      if (String(uid) === String(decoded.sub)) {
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      return false;
    }
  } else {
    return false;
  }
};

export const getResources = async (id) => {
  let resources = getData(data.MY_RESOURCES);
  let role = getData(data.MY_ROLE);
  if (resources && role) {
    return { resources: resources, role: role };
  }
};

export const local = () => process.env.REACT_APP_GRAFANA_ENV == "local";

// GRAFANA URL
export const GRAFANA_BASE =
  process.env.REACT_APP_GRAFANA_ENV === "local"
    ? "http://localhost:4000"
    : process.env.REACT_APP_GRAFANA_ENV === "development"
      ? "https://staging.grafana.sproutsai.com"
      : "https://natera.grafana.sproutsai.com";

// API BASE
const API_BASE =
  process.env.REACT_APP_ENV === "local"
    ? "http://localhost:3333"
    : process.env.REACT_APP_ENV === "development"
      ? "https://dev.api.sproutsai.com"
      : "https://natera.api.sproutsai.com";

// API Endpoints
const USER_EP = "/user";
const CANDIDATE_EP = "/candidate";
const RECRUITER_EP = "/recruiter";
const CHAT_EP = "/chat";
const JOB_EP = "/job";
const COMPANY_EP = "/company";
const GOOGLE_EP = "/google";
const BOT_EP = "/zoombot";
const CHIME_EP = "/chime";
const VOICE_EP = "/voice";
const WEBHOOKS = "/webhooks";
const UTILITY = "/utility";
const SEQUENCE_EP = "/sequence";
const TEMPLATE_EP = "/template";

// USER API
export const USER_API = {
  POST_AUTH: API_BASE + USER_EP + "/auth",
  POST_SEND_EMAIL_OTP: API_BASE + USER_EP + "/otp/send",
  POST_VERIFY_EMAIL_OTP: API_BASE + USER_EP + "/otp/verify",
  PUT_UPDATE_USER_PASSWORD: API_BASE + USER_EP + "/password",
  POST_GOOGLE_AUTH: API_BASE + USER_EP + "/googleauth",
  POST_LINKEDIN_AUTH: API_BASE + USER_EP + "/linkedin/oauth/access-token",
  GET_USER: API_BASE + USER_EP,
  PUT_UPDATE_USER: API_BASE + USER_EP,
  PUT_UPDATE_USER_ACCOUNT: API_BASE + USER_EP + "/enable_disable_user",
  POST_UPLOAD_USER_PIC: API_BASE + USER_EP + "/uploadpic",
  POST_ADD_EDUCATION: API_BASE + USER_EP + "/education",
  PUT_UPDATE_EDUCATION: API_BASE + USER_EP + "/education/",
  DELETE_EDUCATION: API_BASE + USER_EP + "/education/",
  POST_ADD_EXPERIENCE: API_BASE + USER_EP + "/experience",
  PUT_UPDATE_EXPERIENCE: API_BASE + USER_EP + "/experience/",
  DELETE_EXPERIENCE: API_BASE + USER_EP + "/experience/",
  POST_ADD_PROJECT: API_BASE + USER_EP + "/project",
  PUT_UPDATE_PROJECT: API_BASE + USER_EP + "/project/",
  DELETE_PROJECT: API_BASE + USER_EP + "/project/",
  POST_DELETE_USER: API_BASE + USER_EP + "/delete",
  POST_RESET_PSWD_SENDCODE: API_BASE + USER_EP + "/reset/email",
  POST_RESET_PSWD: API_BASE + USER_EP + "/reset/password",
  POST_FORGOT_PSWD_SEND_OTP: API_BASE + USER_EP + "/forgot/otp/send",
  POST_FORGOT_PSWD_VERIFY_OTP: API_BASE + USER_EP + "/forgot/otp/verify ",
  GET_ALL_RECRUITER: API_BASE + USER_EP + "/get_all_recruiters",
  GET_ALL_ROLES: API_BASE + USER_EP + "/roles",
  POST_CREATE_ROLES: API_BASE + USER_EP + "/role",
  GET_ALL_RESOURCES: API_BASE + USER_EP + "/resources/",
  PUT_ALL_RESOURCES: API_BASE + USER_EP + "/resources",
  GET_USERS_WITH_ROLE: API_BASE + USER_EP + "/users_to",
  GET_USERS_WITH_ROLE: API_BASE + USER_EP + "/users_to",
  GET_ORGANIZATION_LIST: API_BASE + "/organization/list",
  PUT_UPDATE_USER_ROLE: API_BASE + USER_EP + "/update_user_role",
  GET_USER_RESOURCES: API_BASE + USER_EP + "/my_resources",
  GET_MY_ROLE: API_BASE + USER_EP + "/my_role",
};

export const GOOGLE_API = {
  POST_GOOGLE_TOKENS: API_BASE + GOOGLE_EP + "/tokens",
  GET_EMAIL_AUTHENTICATION: API_BASE + GOOGLE_EP + "/authenticate",
  POST_EMAIL_CALLBACK: API_BASE + GOOGLE_EP + "/auth-callback",
};

// CANDIDATE API
export const CANDIDATE_API = {
  POST_CREATE_PROFILE: API_BASE + CANDIDATE_EP + "/profile",
  PUT_UPDATE_PROFILE: API_BASE + CANDIDATE_EP + "/profile",
  GET_PROFILE_DETAILS: API_BASE + CANDIDATE_EP + "/profile",
  GET_APPLIED_JOBS: API_BASE + CANDIDATE_EP + "/applied",
  GET_SAVED_JOBS: API_BASE + CANDIDATE_EP + "/saved",
  POST_UPLOAD_RESUME: API_BASE + CANDIDATE_EP + "/uploadresume",
  POST_UPDATE_RESUME: API_BASE + CANDIDATE_EP + "/updateresume",
  GET_CANDIDATE_DASHBOARD: API_BASE + CANDIDATE_EP + "/dashboard",
  POST_APPLY_JOB: API_BASE + CANDIDATE_EP + "/job/apply/",
  POST_CANDIDATE_INTERVIEW: API_BASE + CANDIDATE_EP + "/interview",
  GET_CANDIDATE_INTERVIEW_DETAILS: API_BASE + CANDIDATE_EP + "/interview/",
  PUT_CANDIDATE_INTERVIEW: API_BASE + CANDIDATE_EP + "/interview/",
  POST_VIDEO_STORAGE: API_BASE + CANDIDATE_EP + "/interview/screenrecording/",
  POST_RUN_CODE: API_BASE + CANDIDATE_EP + "/codingchallenge/run/code",
  POST_INTERVIEW_CREATE: API_BASE + "/google/link",
  POST_SCREEN_RECORDING_STORAGE:
    API_BASE + CANDIDATE_EP + "/interview/screenrecording/",
  POST_SAVE_JOB: API_BASE + CANDIDATE_EP + "/job/save/",
  GET_NOTIFICATIONS: API_BASE + CANDIDATE_EP + "/notifications",
  GET_RESUME_ANALYTICS: API_BASE + CANDIDATE_EP + "/resume/analytics",
  PUT_UPDATE_JOB_PREF: API_BASE + CANDIDATE_EP + "/jobpreferences",
  POST_RUN_CODE: API_BASE + CANDIDATE_EP + "/codingchallenge/run/code",
  POST_TEST_CODE: API_BASE + CANDIDATE_EP + "/codingchallenge/run/test",
  GET_ASSESSMENT_DASHBOARD:
    API_BASE + CANDIDATE_EP + "/interview/assessment/dashboard",
  POST_NEED_HELP: API_BASE + CANDIDATE_EP + "/interview/help",
  POST_CURRENT_QUESTION: API_BASE + CANDIDATE_EP + "/interview/answers",
  POST_NEXT_QUESTION:
    API_BASE + CANDIDATE_EP + "/interview/assessment/nextquestion/",
  POST_FEEDBACK: API_BASE + CANDIDATE_EP + "/interview/feedback/",
  GET_FULL_REPORT: API_BASE + CANDIDATE_EP + "/interview/report/",
};

// RECRUITER API
export const RECRUITER_API = {
  // *......New API......*
  GET_JOB_MATCHPROFILE_DETAILS:
    API_BASE + RECRUITER_EP + "/get_applicant_details/",
  GET_POSTED_JOBS: API_BASE + RECRUITER_EP + "/posted_jobs",
  GET_RECRUITER_DASHBOARD: API_BASE + RECRUITER_EP + "/dashboard_page",
  PUT_UPDATE_APPLICANT_STATUS:
    API_BASE + RECRUITER_EP + "/bulk_status_change_v2", // 1st param candidateId, 2nd param jobId,
  PUT_UPDATE_APPLICANT_STATUS_REJECTED:
    API_BASE + RECRUITER_EP + "/reject_candidate", // 1st param candidateId, 2nd param jobId,
  PUT_MATCHPROFILE_FEEDBACK: API_BASE + RECRUITER_EP + "/thumbs_up_down",
  DELETE_MATCH_PROFILE: API_BASE + RECRUITER_EP + "/delete_match_profile/",
  PUT_CANDIDATE_FEVORITE_STATUS:
    API_BASE + RECRUITER_EP + "/matchprofile/favourite",
  GET_PARSED_RESUME: API_BASE + RECRUITER_EP + "/applicant_search",
  GET_PARSED_CANDIDATE_DETAILS: API_BASE + RECRUITER_EP + "/candidate_details/",
  POST_PUSH_CANDIDATE_GREENHOUSE:
    API_BASE + RECRUITER_EP + "/push_candidates_to_gh",
  GET_TRANSCRIPTION_SUMMARY: API_BASE + RECRUITER_EP + "/instantmeeting/",
  GET_REJECTION_TEMPLATE: API_BASE + RECRUITER_EP + "/email_templates_gh",
  GET_REJECTION_REASON: API_BASE + RECRUITER_EP + "/reject_reason_list_gh",
  GET_HIRING_TEAM: API_BASE + RECRUITER_EP + "/get_hiring_team",
  GET_GREENHOUSE_WEBHOOK_STATUS:
    API_BASE + RECRUITER_EP + "/webhooks_sync_status",
  POST_SAVE_VIDEO_URL: API_BASE + RECRUITER_EP + "/save_video_url",

  // *......New API......*

  POST_CREATE_PROFILE: API_BASE + RECRUITER_EP + "/profile",
  PUT_UPDATE_PROFILE: API_BASE + RECRUITER_EP + "/profile",
  GET_PROFILE_DETAILS: API_BASE + RECRUITER_EP + "/profile",
  GET_PUBLIC_PROFILE_DETAILS: API_BASE + RECRUITER_EP + "/profile/", // param = recruiterId
  GET_RECRUITER_TASKS: API_BASE + RECRUITER_EP + "/tasks",
  PUT_DASHBOARD_TASK_UPDATE: API_BASE + RECRUITER_EP + "/updatetask/",
  POST_ADD_DASHBOARD_TASK: API_BASE + RECRUITER_EP + "/tasks",
  // GET_POSTED_JOBS: API_BASE + RECRUITER_EP + "/jobs",
  GET_APPLICANTS: API_BASE + RECRUITER_EP + "/applicants",
  GET_APPLICANT_DETAILS: API_BASE + RECRUITER_EP + "/applicants/", // 1st param candidateId, 2nd param jobId,
  GET_APPLICANT_ANALYTICS: API_BASE + RECRUITER_EP + "/match_visualization/", // 1st param candidateId, 2nd param jobId,
  GET_COMPANIES: API_BASE + RECRUITER_EP + "/companies",
  GET_UNIVERSAL_SEARCH: API_BASE + RECRUITER_EP + "/search",
  POST_SCHEDULE_INTERVIEW: API_BASE + RECRUITER_EP + "/interview/", // :id -> candidate id
  GET_INTERVIEW_DETAILS: API_BASE + RECRUITER_EP + "/interview/", // :id -> interview id
  POST_UPLOAD_CANDIDATE: API_BASE + RECRUITER_EP + "/uploadcandidate",
  POST_UPLOAD_RESUME: API_BASE + RECRUITER_EP + "/uploadresume",
  // POST_MULTIPLE_UPLOAD_RESUME: API_BASE + RECRUITER_EP + '/uploadmultipleresume',
  POST_MULTIPLE_UPLOAD_RESUME: API_BASE + RECRUITER_EP + "/resumes_mq",
  POST_PUBLIC_UPLOAD_RESUME: API_BASE + RECRUITER_EP + "/singleresumeparse",
  POST_MULTIPLE_UPLOAD_RESUME_NEW:
    API_BASE + RECRUITER_EP + "/uploadmultipleresume/v1",
  POST_MULTIPLE_LINKEDIN_CAN:
    API_BASE + RECRUITER_EP + "/uploadmultipleresumetest",
  POST_CREATE_TASK: API_BASE + RECRUITER_EP + "/tasks",
  POST_CREATE_INSTANT_MEETING: API_BASE + RECRUITER_EP + "/instantmeeting",
  GET_ALL_INSTANT_MEETINGS: API_BASE + RECRUITER_EP + "/instantmeeting",
  GET_INSTANT_MEETING_BY_ID: API_BASE + RECRUITER_EP + "/instantmeeting/", // :id -> meeting id
  PUT_END_MEETING_BY_ID: API_BASE + RECRUITER_EP + "/instantmeeting/", // :id -> meeting id

  POST_UPLOAD_CANDIDATE_PARSED:
    API_BASE + RECRUITER_EP + "/uploadresume/parsing",
  POST_CANDIDATE_NOTES: API_BASE + RECRUITER_EP + "/candidatenote",
  GET_MATCH_PROFILE_DETAILS: API_BASE + RECRUITER_EP + "/matchprofile/",
  PUT_MATCH_PROFILE_DETAILS: API_BASE + RECRUITER_EP + "/matchprofile/",
  PUT_UPDATE_EVALUATION: API_BASE + RECRUITER_EP + "/evaluation/",
  GET_PUBLIC_EVALUATION: API_BASE + RECRUITER_EP + "/evaluation/public/",
  PUT_UPDATE_SUBMITTED_APPLICANT_STATUS:
    API_BASE + RECRUITER_EP + "/submitted/", // 1st param candidateId, 2nd param jobId,
  GET_CANDIDATE_EVALUATION_PLAN:
    API_BASE + RECRUITER_EP + "/evaluationplan/candidate/",
  GET_JOB_EVALUATION_PLAN: API_BASE + RECRUITER_EP + "/evaluationplan/job/",
  PUT_CANDIDATE_EVALUATION_PLAN:
    API_BASE + RECRUITER_EP + "/evaluationplan/candidate/",
  PUT_JOB_EVALUATION_PLAN: API_BASE + RECRUITER_EP + "/evaluationplan/job/",
  POST_INVITE_CANDIDATE_ASSESSMENT:
    API_BASE + RECRUITER_EP + "/invite/candidate",
  GET_QUESTIONS_FROM_S3: API_BASE + RECRUITER_EP + "/get-question-s3",
  VALIDATE_ATS_TOKEN: API_BASE + RECRUITER_EP + "/ats-token/validate",
  GETWEBHOOKS_ATS_TOKEN: API_BASE + RECRUITER_EP + "/getwebhooks-details",
  POST_HIRING_STAGE_STATUS_GREENHOUSE: API_BASE + WEBHOOKS + "/movestage",
  GET_RESUME_UPLOAD_STATUS: API_BASE + RECRUITER_EP + "/resume_status_mq/",
  UPDATE_RESUME_UPLOAD_STATUS: API_BASE + RECRUITER_EP + "/resume_status_mq",
  GET_SPROUTS_CANDIDATE: API_BASE + RECRUITER_EP + "/filter-candidate",
  POST_FETCH_OUTREACH_CANDIDATES: API_BASE + RECRUITER_EP + "/outsource",
  PUT_BULK_UPDATE_STATUS: API_BASE + RECRUITER_EP + "/applicants/status-change",
  POST_EMAIL_CANDIDATE: API_BASE + "/email/email-templates/send-html-email",
  POST_EMAIL_TEMPLATE: API_BASE + "/email/email-templates/get-email-template",
  POST_ADD_LINKEDIN_URL: API_BASE + RECRUITER_EP + "/add-linkedin-url",
  POST_ATTACH_CANDIDATE_JOB: API_BASE + RECRUITER_EP + "/attach-candidate-job",
  GET_SHARE_JOBS_ID: API_BASE + RECRUITER_EP + "/share_jobs",
  PUT_SHARE_JOBS_ID: API_BASE + RECRUITER_EP + "/share_jobs",
  POST_RE_MATCH_CANDIDATES:
    API_BASE + RECRUITER_EP + "/reparse_match_profile_mq",
  GET_INTERVIEWS: API_BASE + RECRUITER_EP + "/interviews",
  POST_END_INTERVIEW: API_BASE + RECRUITER_EP + "/interview/transcript/", // :id -> bot id
  PUT_QUESTION_TIME: API_BASE + RECRUITER_EP + "/interview/timestamps/", // :id -> meeting id
};

export const ADMIN_API = {
  GET_RECRUITER_FOR_APPROVE: API_BASE + USER_EP + "/get-approve-recruiter",
  APPROVE_RECRUITER: API_BASE + USER_EP + "/approve-recruiter",
};
// JOB API
export const JOB_API = {
  // *......New API......*
  GET_MATCH_PROFILES: API_BASE + JOB_EP + "/get_match_profiles/",
  GET_JOB_QUESTIONS: API_BASE + JOB_EP + "/get_questions/",
  GET_JOB_MATCHPROFILE_DETAILS:
    API_BASE + JOB_EP + "/get_applicant_details/:applicantId/",
  GET_JOB_DETAILS: API_BASE + JOB_EP + "/job_details/",
  GET_DESCRIPTION: API_BASE + JOB_EP + "/details/",
  GET_PIPELINE: API_BASE + JOB_EP + "/pipeline_list/",
  POST_UPLOAD_JOB: API_BASE + JOB_EP + "/job_parse",
  POST_NEW_JOB: API_BASE + JOB_EP + "/create",
  PUT_UPDATE_JOB: API_BASE + JOB_EP + "/jobDetails/",
  REPARSE_GREENHOUSE_JOB: API_BASE + JOB_EP + "/reparse",
  POST_CLONE_JOB: API_BASE + JOB_EP + "/clone/",
  PUT_UPDATE_CANDIDATE_DETAILS: API_BASE + JOB_EP + "/add_info_applicant/",
  PUT_JOB_STATUS: API_BASE + JOB_EP + "/change_status",
  POST_QUESTIONS_GENERATE: API_BASE + "/questions/generate_question_mq",
  GET_GREENHOUSE_CANDIDATE_LINK: API_BASE + JOB_EP + "/open_greenhouse_link/",
  GET_JOB_SKILLS: API_BASE + JOB_EP + "/get-all-job-skills/",
  GET_QUESTIONS_BY_SKILLS: API_BASE + JOB_EP + "/get_questions_by_skill",
  GET_CANDIDATES_COUNT: API_BASE + JOB_EP + "/get_match_profiles_count/",

  // *......New API......*
  GET_JOBS_POSTS: API_BASE + JOB_EP + "/posts",
  GET_ALL_JOBS_POSITIONS: API_BASE + JOB_EP + "/get-all-job-positions",
  GET_ALL_JOBS_BY_COMPANY: API_BASE + JOB_EP + "/list-by-company/",
  GET_JOB_LOCATIONS: API_BASE + JOB_EP + "/locations",
  GET_JOB_TITLES: API_BASE + JOB_EP + "/titles",
  POST_JOB_TITLES: API_BASE + JOB_EP + "/titles",
  GET_ALL_CRITERIA: API_BASE + JOB_EP + "/criterias",
  GET_ALL_SKILLS: API_BASE + JOB_EP + "/skills",
  POST_NEW_SKILL: API_BASE + JOB_EP + "/skills",
  GET_ALL_CANDIDATES: API_BASE + JOB_EP + "/candidates/",
  GET_ASSESSMENT: API_BASE + JOB_EP + "/assessments/",
  GET_RESUMELIST: API_BASE + JOB_EP + "/resume/status/",
  // GET_RESUME_UPLOAD_STATUS: API_BASE + RECRUITER_EP + "/resume_status_mq/",
  // UPDATE_RESUME_UPLOAD_STATUS: API_BASE + RECRUITER_EP + "/resume_status_mq",
  GET_ALL_EVALUATIONS: API_BASE + JOB_EP + "/evaluation/",
  GET_SUBMITTED_CANDIDATES: API_BASE + JOB_EP + "/submitted/",
  GET_PUBLIC_JOBS_POST: API_BASE + JOB_EP + "/post/public/",
  POST_ASSESSMENT: API_BASE + JOB_EP + "/assessments",
  PUT_ASSESSMENT: API_BASE + JOB_EP + "/assessments/",
  POST_REMINDER: API_BASE + JOB_EP + "/assessments/reminder",
  POST_JD_GENERATE: API_BASE + JOB_EP + "/jdgenerate",
  GET_QUESTIONS_STATUS: API_BASE + "/questions/get_quest_status",
  GET_JD_TEMPLATES: API_BASE + JOB_EP + "/template_list",
  POST_JD_TEMPLATES: API_BASE + JOB_EP + "/jd_generate",
  GET_SOURCE_CANDIDATES: API_BASE + JOB_EP + "/sourceCandidates/",
};

// CHAT API
export const CHAT_API = {
  GET_ALL_CHATS: API_BASE + CHAT_EP,
  GET_ALL_SMSCHATS: API_BASE + CHAT_EP + "/list_chat_user",
  POST_ADD_SMSUSER: API_BASE + CHAT_EP + "/chatuser",
  GET_SEND_MESSAGE: API_BASE + CHAT_EP + "/send_message",
  GET_MESSAGE_LIST: API_BASE + CHAT_EP + "/getMessage",
  GET_CHAT_DETAILS: API_BASE + CHAT_EP + "/",
  POST_SEND_MESSAGE: API_BASE + CHAT_EP + "/message",
};

// COMPANY API
export const COMPANY_API = {
  POST_CREATE_COMPANY_PROFILE: API_BASE + COMPANY_EP + "/profile",
  PUT_UPDATE_COMPANY_PROFILE: API_BASE + COMPANY_EP + "/profile/",
  GET_COMPANY_PROFILE: API_BASE + COMPANY_EP + "/profile/",
  POST_ADD_COMPANY: API_BASE + COMPANY_EP + "/add/",
  REMOVE_ADD_COMPANY: API_BASE + COMPANY_EP + "/remove/",
  GET_COMPANIES_ADDED: API_BASE + COMPANY_EP + "/added",
  GET_COMPANIES_CREATED: API_BASE + COMPANY_EP + "/created",
  POST_UPLOAD_COMPANY_PIC: API_BASE + COMPANY_EP + "/uploadpic",
  GET_GLOBAL_COMPANY_ID: API_BASE + COMPANY_EP + "/search/",
  POST_COMPANY_EXISTS_CHECK: API_BASE + COMPANY_EP + "/check",
};

// BOT API
export const BOT_API = {
  POST_JOIN_MEETING: API_BASE + BOT_EP + "/join/",
  POST_LEAVE_MEETING: API_BASE + BOT_EP + "/leave/",
  GET_LIVE_MEETING_DATA: API_BASE + BOT_EP + "/livedata/",
  GET_LIVE_TRANSCRIPTION: API_BASE + BOT_EP + "/live/",
  SKIP_QUESTION: API_BASE + BOT_EP + "/skip",
  CREATE_ZOOM_MEET: API_BASE + BOT_EP + "/meeting",
  POST_ZOOM_MEET_USERTOKEN: API_BASE + BOT_EP + "/usertoken",
  POST_JOIN_ASSESSMENT_MEETING: API_BASE + BOT_EP + "/join/assessment",
};

export const CHIME_API = {
  POST_CREATE_MEETING: API_BASE + CHIME_EP + "/createmeeting",
  JOIN_MEETING: API_BASE + CHIME_EP + "/join",
  CREATE_MEETING: API_BASE + CHIME_EP + "/createmeeting",
  GET_MY_CHIME_LIST: API_BASE + CHIME_EP + "/mylist",
};

export const VOICE_API = {
  GENERATE_VOICE_TOKEN: API_BASE + VOICE_EP + "/token",
  POST_END_CALL: API_BASE + VOICE_EP + "/end_call",
};

export const UTILITY_API = {
  VERIFY_EMAIL: API_BASE + UTILITY + "/verify-email",
};

export const SEQUENCE_API = {
  GET_ALL_SEQUENCES: API_BASE + SEQUENCE_EP + "/all",
  GET_SEQUENCE_DATA: API_BASE + SEQUENCE_EP + "/get/",
  PUT_SEQUENCE_DATA: API_BASE + SEQUENCE_EP + "/update/",
  POST_ADD_SEQUENCE: API_BASE + SEQUENCE_EP + "/job/create",
};

export const TEMPLATE_API = {
  PUT_TEMPLATE_DATA: API_BASE + TEMPLATE_EP + "/update/",
};

// Is Valid Email
export const isValidEmail = (email) => {
  return EmailValidator.validate(email);
};

// API Methods
export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// Data
export const data = {
  ACCESS_TOKEN: "access_token",
  UID: "uid",
  LOGIN: "login",
  ACCOUNT_TYPE: "account_type",
  MY_RESOURCES: "my_resources",
  ORGANIZATION: "organization",
  MY_ROLE: "my_role",
};

// API
export const API = async (method, endpoint, token, body) => {
  let response = null;
  let options = null;
  if (token === 0) {
    options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      retries: 0,
      retryDelay: 3000,
    };
    if (method !== API_METHODS.GET) {
      options.body = JSON.stringify(body);
    }
    response = await fetch(endpoint, options);
  } else {
    options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getData(data.ACCESS_TOKEN)),
      },
      retries: 0,
      retryDelay: 3000,
    };
    if (method !== API_METHODS.GET) {
      options.body = JSON.stringify(body);
    }
    response = await fetch(endpoint, options);
  }
  return await response.json();
};

const clearCacheData = () => {
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      }),
    );
  });
};

export const hardRefresh = () => {
  localStorage.clear();
  if (Object.keys(cookie.getAll())?.length > 0) {
    Object.keys(cookie.getAll()).map((key) => cookie.remove(key));
    // window.location.reload();
  }
  // navigator.serviceWorker.getRegistrations().then((registrations) => {
  //     registrations.forEach((registration) => {
  //         registration.unregister();
  //     });
  // });
  clearCacheData();
};

// Logout
export const logout = () => {
  deleteData(data.ACCESS_TOKEN);
  deleteData(data.UID);
  deleteData(data.LOGIN);
  hardRefresh();
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

// Errors
export const errors = {
  INVALID_EMAIL: "Please enter a valid email",
  INVALID_USERNAME: "Please enter a valid username",
  INVALID_PASSWORD: "Please enter a valid password",
};
