import Avatar from "@mui/material/Avatar";
import { fontSize } from "styled-system";

const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];
function stringToColor(string) {
  if (!colorArray || colorArray.length === 0) {
    return null; // Handle the case when the colorArray is empty or undefined
  }

  let hash = 0;
  for (let i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colorArray.length; // Calculate the index based on the hash
  return colorArray[index];
}

function stringAvatar(name, width, height, borderRadius, fontSize, company) {
  if (name?.split(" ") > 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children:
        name?.split(" ")[0][0] && name?.split(" ")?.length > 2
          ? `${String(name?.split(" ")[0][0])?.toUpperCase()}${String(
              name?.split(" ")[1][0],
            ).toUpperCase()}`
          : "S",
    };
  }
  return {
    sx: {
      bgcolor: company === true ? "#04364A" : stringToColor(name),
      width: width,
      height: height,
      borderRadius: borderRadius,
      fontSize: fontSize,
      fontWeight: "bold",
    },
    children: `${String(name?.split(" ")[0][0]).toUpperCase()}`,
  };
}

export default function InitialsLogo({
  id = "initialsLogo",
  width = "40px",
  height = "40px",
  str = "",
  styleClass = "",
  borderRadius = "6px",
  fontSize = "16px",
  variant = "",
  company = "",
}) {
  if (!str) {
    str = "";
  }
  return (
    <>
      {str ? (
        <Avatar
          id={id}
          variant={variant}
          {...stringAvatar(str, width, height, borderRadius, fontSize, company)}
          className={styleClass}
          alt={str}
        />
      ) : (
        <Avatar
          id={id}
          variant={variant}
          sx={{
            bgcolor: "#04364A",
            width: width,
            height: height,
            borderRadius: borderRadius,
            fontSize: fontSize,
            fontWeight: "bold",
          }}
          className={styleClass}
          alt={str}
        >
          ?
        </Avatar>
      )}
    </>
  );
}
