import React, { useContext, useEffect, useState } from "react";
import LoginPageImg from "../../components/assets/candidate/LogInPageImg.png";
import Register from "../../components/auth/newUserRegister/SignUp";
import VerifyCode from "../../components/auth/newUserRegister/VerifyCode";
import SetPassword from "../../components/auth/newUserRegister/Password";
import AccountType from "../../components/auth/newUserRegister/AccountType";
import { useNavigate } from "react-router-dom";
import Personal from "../../components/auth/Personal";
import LandingText from "../../components/auth/LandingText";
import { UserContext, logout } from "../../lib/core";
import ActiveStatus from "../../components/auth/ActiveStatus";
const { AuthStageContext } = require("../../lib/context");
const { authStages } = require("../../lib/constants");

const NewUserRegister = () => {
  const { user, setUser } = useContext(UserContext);
  const nav = useNavigate();
  const [stage, setStage] = useState(user?.stage ?? "NEWUSER");
  const [email, setEmail] = useState(user?.email ?? "");
  const [stageTab, setStageTab] = useState(<Register />);
  useEffect(() => {
    if (user?.stage) setStage(user?.stage);
    if (user?.email) setEmail(user?.email);
  }, [user]);

  useEffect(() => {
    // console.log(stage)
    switch (stage) {
      case authStages.CREATED:
        setStageTab(<VerifyCode />);
        break;
      case authStages.VERIFIED:
        setStageTab(<SetPassword />);
        break;
      case authStages.SECURED:
        setStageTab(<AccountType />);
        break;
      case authStages.SELECTED:
        setStageTab(<Personal />);
        break;
      case authStages.SUBMITTED:
        setStageTab(<ActiveStatus rejected={false} />);
        break;
      case authStages.REJECTED:
        setStageTab(<ActiveStatus rejected={true} />);
        break;
      case authStages.COMPLETED:
        nav("/dashboard", { replace: true });
        break;
      default:
        setStageTab(<Register />);
    }
  }, [stage]);

  useEffect(() => {
    if (String(email)?.length <= 0) setStage("NEWUSER");
  }, [email]);
  // console.log(user, stage, email);

  return (
    <main className="w-screen md:h-screen min-h-screen h-full flex md:flex-row flex-col py-5 md:py-0 bg-defaultBg overflow-scroll scrollbar-hide">
      <div className="w-full md:w-7/12 h-auto md:h-full flex items-center justify-center flex-col">
        <LandingText />
        <img
          src={LoginPageImg}
          className="max-w-full h-auto -mt-16"
          alt="AI based decision engine"
        />
      </div>
      <AuthStageContext.Provider value={{ stage, setStage, email, setEmail }}>
        <div className="shadow-[0px_4px_32px_#043dab14] w-full md:w-5/12 h-auto md:h-full rounded-none md:rounded-l-lg md:bg-white bg-defaultBg px-4 md:px-0 mt-4 md:mt-0">
          {stageTab}
        </div>
      </AuthStageContext.Provider>
    </main>
  );
};

export default NewUserRegister;
