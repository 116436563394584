import { Link } from "react-router-dom";
import Bookmark from "../../assets/Bookmark";
import ApplyBtn from "./ApplyBtn";
import InitialsLogo from "../../all/InitialsLogo";
import locationSvg from "../../assets/recruiter/LocationSvg.svg"
import PercentDonut from "../../analytics/PercentDonut";

const moment = require("moment");
const now = moment();


const timeDifference = (difference) => {

  // Format the time difference
  if (difference < 1000) {
    return "Just now";
  } else if (difference < 60000) {
    return `${Math.floor(difference / 1000)} seconds ago`
  } else if (difference < 3600000) {
    return `${Math.floor(difference / 60000)} minutes ago`
  } else if (difference < 86400000) {
    return `${Math.floor(difference / 3600000)} hours ago`
  } else if (difference < 2592000000) {
    return `${Math.floor(difference / 86400000)} days ago`
  } else if (difference < 31536000000) {
    return `${Math.floor(difference / 2592000000)} months ago`
  } else {
    return `${Math.floor(difference / 31536000000)} years ago`
  }
}




export default function SavedJobPost({ job, reload_function }) {
  return (
    <Link to={`/jobpost/${job?.id}`} className="col-xs-12 job-post no-padding">
      <div className="col-xs-3 no-padding position">
        <div className="col-xs-3 logo-section no-padding">
          {/* {
                job?.company?.logo?.split("/")?.at(-1)=='undefined' ?
                
                <div className="laterLogo">
                  <span> {job.company?.name?.split(" ")[0]?.split("")[0]}</span>
                   </div>
                :
                <img alt='Logo' className="img-responsive logo no-padding" src={job?.company?.logo} style={{objectFit: 'cover'}}/> 
              } */}

          <InitialsLogo
            className="img-responsive"
            str={job.company?.name}
            width={60}
            height={60}
            bgRounded="12px"
            fontSize={28}
          />

        </div>
        <div className="col-xs-9 text-section no-padding">
          <h4 className="col-xs-12 no-padding position">{job?.position}</h4>
          <span className="company_name">{job?.company?.name}</span>
        </div>
      </div>
      <div className="col-xs-3 no-padding match">
        <PercentDonut
          id={"eval_match_" + job?.id}
          chartSize={68}
          radius={(68 - 10) / 2}
          width={5}
          cornerRadius={5}
          percentData={job?.matchPercent * 100}
          fontSize={16}
        />
      </div>
      <div className="col-xs-2 no-padding location">
        <img
          src={locationSvg}
          alt="location"
          className="img-responsive"
        />
        <h5>{job?.location}</h5>
      </div>
      <div className="col-xs-2 no-padding datePosted">
        <h5 className="col-xs-12 no-padding ">
          {timeDifference(new Date - new Date(job?.createdAt))}

        </h5>
      </div>
      <div className="col-xs-2 button-group no-padding">
        <div className="button-section no-padding">
          <ApplyBtn
            applied={false}
            job_id={job?.id}
            reload_function={reload_function}
          />
        </div>
        <div>
          <span className="bookmark">
            <Bookmark
              bookmarked={true}
              job_id={job?.id}
              reload_function={reload_function}
            />
          </span>
        </div>
      </div>
    </Link>
  );
}
