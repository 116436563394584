import React from "react";

function AssessmentButtons({
  currentQuestion,
  UpdateTestState,
  setCurrentQuestion,
  questions,
  navigation,
  setTestOutput,
}) {
  return (
    <div className="button-section col-xs-12 no-padding">
      <button
        className="previous"
        disabled={navigation.currentPage == 0 && currentQuestion == 0}
        onClick={(e) => {
          if (currentQuestion == 0) {
            if (navigation.currentPage > 0) {
              navigation?.changePage(navigation.currentPage - 1);
              setCurrentQuestion();
            }
          } else {
            setCurrentQuestion(currentQuestion - 1);
            if (navigation.allPages[navigation.currentPage] == "coding") {
              setTestOutput([]);
            }
          }
        }}
      >

        Back

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.97496 4.94141L2.91663 9.99974L7.97496 15.0581"
            stroke="#252E4B"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.0834 10H3.05835"
            stroke="#252E4B"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}

      </button>
      <div className="right-button-sec">
        <button
          className="next"
          onClick={() => {
            if (questions?.length == currentQuestion + 1) {
              navigation?.changePage(navigation.currentPage + 1);
              UpdateTestState("")
            } else {
              setCurrentQuestion(currentQuestion + 1);
              if (navigation.allPages[navigation.currentPage] == "coding") {
                setTestOutput([]);
              }
            }
          }}
        >
          {questions.length != currentQuestion + 1 ? "Next" : "Submit"}
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.0247 4.94141L17.0831 9.99974L12.0247 15.0581"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.91666 10L16.9417 10"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
        </button>
        {/* )} */}
      </div>
    </div>
  );
}

export default AssessmentButtons;
