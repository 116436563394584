import React, { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchBar from "./SearchBar";
import RecruiterSearchBar from "./RecruiterSerachBar";
import { UserContext } from "../../../lib/core";
import Logo from "../../assets/Logo";
import Menu from "./Menu";
import Notification from "../../assets/candidate/Notification.svg";
import Avatar from "../../assets/candidate/Avatar.png";
import RecruiterNewSearchBar from "./RecruiterNewSearchBar";
import InitialsLogo from "../InitialsLogo";
import Modal from "./GHModal";
import GreenhouseLogo from "../../assets/greenhouse_green.png";
import GreenhouseLogoRed from "../../assets/greenhouse_red.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
const core = require("../../../lib/core");

import { getWebhooksAtsTokenService } from "../../../redux/services";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LockedComponent from "../../../layouts/LockedComponent";

export default function HeaderNew(props, { open }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { user, resources } = useContext(UserContext);
  useEffect(() => {
    setShowNotif(false);
    setShowMenu(false);
  }, [user]);

  const toggleMenu = () => {
    setShowNotif(false);
    setShowMenu(!showMenu);
  };

  const toggleNtf = () => {
    setShowMenu(false);
    setShowNotif(!showNotif);
  };
  const jobPost = () => {
    nav("/create-job");
  };

  const [showNotification, setShowNotification] = useState(false);
  const [buttonColor, setButtonColor] = useState("red");

  const headermenuRef = useRef(null);
  const headernotifRef = useRef(null);

  const { isTokenValid, atsTokenData } = useSelector((state) => {
    return {
      isTokenValid: state?.atsIntegrationSlice?.isTokenValid,
      atsTokenData: state?.getWebhooksAtsIntegrationSlice?.atsToken,
    };
  });
  const [webhookStatus, setWebhookStatus] = useState({});
  async function get_webhook_status() {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_GREENHOUSE_WEBHOOK_STATUS,
      1,
    );
    if (json?.data) {
      setWebhookStatus(json.data);
    } else {
    }
  }

  useEffect(() => {
    get_webhook_status();
  }, []);

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (headermenuRef.current && !headermenuRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setShowMenu(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    dispatch(getWebhooksAtsTokenService());

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (atsTokenData?.data) {
      if (atsTokenData?.data?.isValidToken) {
        setButtonColor("green");
      } else {
        setButtonColor("red");
      }
    }
  }, [atsTokenData]);

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (
        headernotifRef.current &&
        !headernotifRef.current.contains(e.target)
      ) {
        // Clicked outside of the menu, close the menu
        setShowNotification(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [showSync, setShowSync] = useState(false);

  return (
    <div
      className={`flex justify-between items-center  mx-[8px] mt-[14px]  w-[calc(100%-16px)] pr-[18px]  h-[48px] `}
    >
      {user?.account === 0 ? (
        <div className="searchBar w-[60%]">
          <SearchBar dropdownText="Select Location" />
        </div>
      ) : (
        <div className="">
          <RecruiterNewSearchBar dropdownText="Select Location" />
        </div>
      )}

      <div className="flex items-center">
        {/* <Button
          marginRight={"25px"}
          onClick={() => {
            nav("/create-job");
          }}
          text={<span className="flex items-center">
            <svg
              className="mr-[8px]"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g id="plus">
                <path
                  id="Union"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.625 1C6.625 0.654822 6.34518 0.375 6 0.375C5.65482 0.375 5.375 0.654822 5.375 1V5.375H1C0.654822 5.375 0.375 5.65482 0.375 6C0.375 6.34518 0.654822 6.625 1 6.625H5.375V11C5.375 11.3452 5.65482 11.625 6 11.625C6.34518 11.625 6.625 11.3452 6.625 11V6.625H11C11.3452 6.625 11.625 6.34518 11.625 6C11.625 5.65482 11.3452 5.375 11 5.375H6.625V1Z"
                  fill="white"
                />
              </g>
            </svg>
            Post new job
          </span>

          }

        /> */}

        {user?.account != 0 && (
          <>
            <div className="w-[174px]">
              <LockedComponent
                className="w-[174px]"
                isLocked={
                  resources?.filter((item) => item === "Job_create")?.length > 0
                    ? false
                    : true
                }
              >
                <button
                  onClick={() => {
                    nav("/create-job");
                  }}
                  className="w-full solid-button-blue mr-[25px] h-[40px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center"
                >
                  <span className="mr-[8px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <g id="plus">
                        <path
                          id="Union"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.625 1C6.625 0.654822 6.34518 0.375 6 0.375C5.65482 0.375 5.375 0.654822 5.375 1V5.375H1C0.654822 5.375 0.375 5.65482 0.375 6C0.375 6.34518 0.654822 6.625 1 6.625H5.375V11C5.375 11.3452 5.65482 11.625 6 11.625C6.34518 11.625 6.625 11.3452 6.625 11V6.625H11C11.3452 6.625 11.625 6.34518 11.625 6C11.625 5.65482 11.3452 5.375 11 5.375H6.625V1Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text-[#FFF] text-[16px] not-italic font-normal">
                    Post new job
                  </span>
                </button>
              </LockedComponent>
            </div>

            <span className="mr-[25px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1"
                height="28"
                viewBox="0 0 1 28"
                fill="none"
              >
                <rect width="1" height="28" fill="#DADAE4" />
              </svg>
            </span>

            {showModal && (
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                id={user?.id}
              />
            )}

            <LockedComponent
              className="h-7 w-7 mr-[25px]"
              isLocked={
                resources?.filter((item) => item === "Greenhouse_read")
                  ?.length > 0
                  ? false
                  : true
              }
            >
              {buttonColor === "red" ? (
                <>
                  <span
                    onClick={() => setShowModal(true)}
                    className="mr-[25px] cursor-pointer h-7 w-7"
                  >
                    <img
                      className="h-7 w-7 rounded-md"
                      src={GreenhouseLogoRed}
                    />
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() => setShowModal(true)}
                    className="mr-[25px] cursor-pointer relative"
                    data-tooltip-id="my-tooltip-3"
                    data-tooltip-content={`Last sync: ${webhookStatus?.ping}`}
                  >
                    <img className="h-7 w-7 rounded-md" src={GreenhouseLogo} />

                    {showSync && (
                      <div className="absolute mt-[10px] z-20 shadow-menuOptionShadow  right-[0px] text-center w-[170px] rounded-[8px] bg-[#FFF]">
                        Last sync: {webhookStatus?.ping}
                      </div>
                    )}
                  </span>
                  <ReactTooltip
                    id="my-tooltip-3"
                    style={{
                      zIndex: 9999,
                      backgroundColor: "#00AC85",
                      color: "white",
                    }}
                    place="bottom"
                  />
                </>
              )}
            </LockedComponent>
          </>
        )}

        {/* <span
          className="mr-[25px] cursor-pointer relative"
          ref={headernotifRef}
          onClick={(e) => setShowNotification(!showNotification)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M12.5857 19.25C12.4246 19.5278 12.1932 19.7584 11.9149 19.9187C11.6366 20.079 11.3211 20.1634 10.9999 20.1634C10.6787 20.1634 10.3632 20.079 10.0849 19.9187C9.80654 19.7584 9.57522 19.5278 9.41406 19.25"
              stroke="#252E4B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.1654 15.5859H1.83203C2.56138 15.5859 3.26085 15.2962 3.77657 14.7805C4.2923 14.2648 4.58203 13.5653 4.58203 12.8359V8.2526C4.58203 6.5508 5.25807 4.91869 6.46143 3.71534C7.66479 2.51198 9.29689 1.83594 10.9987 1.83594C12.7005 1.83594 14.3326 2.51198 15.536 3.71534C16.7393 4.91869 17.4154 6.5508 17.4154 8.2526V12.8359C17.4154 13.5653 17.7051 14.2648 18.2208 14.7805C18.7365 15.2962 19.436 15.5859 20.1654 15.5859Z"
              stroke="#252E4B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {showNotification && (
            <div className="absolute mt-[10px] z-20 shadow-menuOptionShadow w-[390px] right-[0px] rounded-[8px] bg-[#FFF]">
              <div className="p-[16px] border-b border-b-[#E1E1EE]">
                <span className="text-[16px] text-[#16182F] font-medium not-italic">
                  Notification
                </span>
                <span></span>
              </div>
              <div className="py-[22px] flex flex-col items-center justify-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="65"
                    viewBox="0 0 64 65"
                    fill="none"
                  >
                    <path
                      d="M31.9816 64.4554C49.2412 64.4554 63.2328 50.4638 63.2328 33.2043C63.2328 15.9447 49.2412 1.95312 31.9816 1.95312C14.7221 1.95312 0.730469 15.9447 0.730469 33.2043C0.730469 50.4638 14.7221 64.4554 31.9816 64.4554Z"
                      fill="#F1F3F9"
                      fillOpacity="0.8"
                    />
                    <path
                      d="M32.0007 2.49917C48.9646 2.49917 62.7093 16.2439 62.7093 33.2078C62.7093 50.1717 48.9646 63.9164 32.0007 63.9164C15.0369 63.9164 1.29214 50.2078 1.29214 33.2078C1.29214 16.2077 15.0369 2.49917 32.0007 2.49917ZM32.0007 1.41406C14.4943 1.41406 0.207031 15.7013 0.207031 33.2078C0.207031 50.7142 14.4943 65.0015 32.0007 65.0015C49.5072 65.0015 63.7945 50.7142 63.7945 33.2078C63.7945 15.7013 49.5434 1.41406 32.0007 1.41406Z"
                      fill="#E1E1EE"
                    />
                    <path
                      d="M58.1983 49.8978H5.83789C5.95057 49.8229 6.0257 49.7479 6.10082 49.673C12.3736 44.2777 15.6038 39.1073 17.219 34.9859C17.3316 34.6861 17.4443 34.4239 17.5194 34.1241V34.0867C18.1955 31.464 18.5712 28.6914 18.5712 25.769C18.5712 25.4692 18.5712 25.1695 18.5712 24.8697C18.5712 24.2703 18.5336 23.6333 18.4585 23.0339C18.5712 16.8143 22.8907 11.6064 28.7127 10.1452C29.0132 10.0702 29.1634 9.80795 29.1259 9.50821C29.1259 9.43328 29.1259 9.32088 29.1259 9.24594C29.1259 8.45913 29.4639 7.74726 29.9898 7.22272C30.4781 6.73564 31.2293 6.39844 32.0181 6.39844C32.8069 6.39844 33.5206 6.73564 34.0464 7.26018C34.5723 7.78472 34.9103 8.4966 34.9103 9.28341C34.9103 9.35834 34.9103 9.47074 34.9103 9.54568C34.8728 9.84542 35.0606 10.1077 35.3611 10.1826C41.2206 11.6438 45.6153 16.9642 45.6153 23.2961C45.6153 23.6708 45.6153 23.8956 45.6153 24.1204C45.6153 24.3827 45.5777 24.6824 45.5777 24.9447L45.5402 25.4318C45.5402 25.6566 45.5777 25.9188 45.5777 26.1436C45.6904 36.9342 50.7612 46.0762 57.8603 49.6355C58.0481 49.8229 58.1983 49.8978 58.1983 49.8978Z"
                      fill="white"
                      stroke="#E1E1EE"
                      strokeWidth="1.05923"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M38.2512 50.3106C38.2512 53.3079 35.8098 55.7058 32.8424 55.7058C29.8375 55.7058 27.4336 53.2705 27.4336 50.3106C27.4336 50.1607 27.4336 50.0483 27.4336 49.8984H38.2512C38.2512 50.0483 38.2512 50.1982 38.2512 50.3106Z"
                      fill="white"
                      stroke="#E1E1EE"
                      strokeWidth="1.05923"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M22.5898 27.0781C22.5898 27.0781 26.3689 32.4806 30.5153 27.0781"
                      stroke="#5F6989"
                      strokeWidth="1.4123"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M34.1367 27.0781C34.1367 27.0781 37.9157 32.4806 42.0621 27.0781"
                      stroke="#5F6989"
                      strokeWidth="1.4123"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M32.3709 38.8354C33.3458 38.8354 34.1362 38.0451 34.1362 37.0701C34.1362 36.0951 33.3458 35.3047 32.3709 35.3047C31.3959 35.3047 30.6055 36.0951 30.6055 37.0701C30.6055 38.0451 31.3959 38.8354 32.3709 38.8354Z"
                      fill="#5F6989"
                    />
                    <path
                      d="M53.7271 1.68069V0H45.6907V2.13018H50.5563L45.4355 8.8725V10.5923H53.9094V8.4621H48.6793L53.7271 1.68069Z"
                      fill="#1369E9"
                    />
                    <path
                      d="M55.9207 15.8365V14.8281H51.2328V16.1062H54.0711L51.084 20.1516V21.1835H56.027V19.9054H52.9762L55.9207 15.8365Z"
                      fill="#1369E9"
                    />
                  </svg>
                </span>

                <span className="mt-[12px] text-[#252E4B] text-[16px] font-medium not-italic">
                  No new notifications
                </span>
                <span className="text-[#5F6989] text-[12px] font-normal not-italic">
                  Stay tuned! Notifications about your{" "}
                </span>
                <span className="text-[#5F6989] text-[12px] font-normal not-italic">
                  activity will show up here.
                </span>
              </div>
            </div>
          )}
        </span> */}

        <div className="flex items-center" ref={headermenuRef}>
          {user && user?.pic != "" && (
            <div
              className="p-[10px] cursor-pointer flex rounded-md hover:bg-gray-300"
              onClick={() => setShowMenu(!showMenu)}
            >
              <InitialsLogo
                id={"job?.id"}
                str={user?.name ?? user?.email}
                styleClass={" width:'50px',height:'50px'"}
                width={"43px"}
                height={"43px"}
                borderRadious={"50%"}
              />
              <div className="flex flex-col ml-[10px]">
                <span className="inline-block text-sm not-italic  text-gray-800 truncate font-semibold">
                  {user?.name}
                </span>
                <span className="inline-block text-sm text-[#5F6989] truncate">
                  {" "}
                  {user?.organization?.name}
                </span>
              </div>
            </div>
          )}
          {showMenu && <Menu headermenuRef={headermenuRef} />}
        </div>
      </div>
    </div>
  );
}
