import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import GHLogo from "../../../assets/greenhouse_green.png";
var parse = require("html-react-parser");
export default function Description({
  job,
  classTxt,
  paraStyle = "",
  headingStyle = "",
}) {
  const [experience, setExperience] = useState("");
  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }
  useEffect(() => {
    formatExperienceValue();
  }, [job]);

  const formatExperienceValue = () => {
    setExperience("");
    if (
      typeof job?.experience == "string" &&
      String(job?.experience)?.length > 0
    ) {
      let matches = String(job?.experience)?.match(/\d+/g);
      let exp =
        matches?.length == 1
          ? String(job?.experience?.min) + "+ years"
          : matches?.length >= 1
            ? "Upto " + String(job?.experienceLevel?.min) + " years"
            : job?.experienceLevel;
      setExperience(exp);
      return;
    }
    // min present but not max
    if (
      +job?.experience?.min > -1 &&
      String(job?.experience?.min)?.length > 0 &&
      !(+job?.experience?.max > -1 && String(job?.experience?.max)?.length > 0)
    ) {
      setExperience(String(job?.experience?.min) + "+ years");
      return;
    }
    // min present but not max
    if (
      +job?.experience?.max > -1 &&
      String(job?.experience?.max)?.length > 0 &&
      !(+job?.experience?.min > -1 && String(job?.experience?.min)?.length > 0)
    ) {
      setExperience("Upto " + String(job?.experience?.min) + " years");
      return;
    }
    // setExperience(String(job?.experience?.min) + " years");
    if (
      +job?.experience?.min > -1 &&
      String(job?.experience?.max)?.length > 0 &&
      +job?.experience?.max > -1
    ) {
      if (job?.experience?.min == job?.experience?.max) {
        setExperience(String(job?.experience?.min) + " years");
      }
      setExperience(
        String(job?.experienceLevel?.min) +
          " - " +
          String(job?.experienceLevel?.max) +
          " years",
      );
    }
  };

  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  return (
    <div className={classTxt}>
      {(job?.departments ||
        job?.workplace ||
        (job?.job_type && job?.job_type?.length > 0) ||
        (job?.location && job?.location?.length > 0) ||
        job?.experience ||
        (job?.equity && Object.entries(job?.equity)?.length > 1) ||
        (job?.salary &&
          Object.entries(job?.salary)?.length > 1 &&
          +job?.salary?.min + +job?.salary?.max > 1)) && (
        <div className="flex flex-wrap mb-5">
          {job?.location && job?.location?.length > 0 && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Location</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {job?.location
                  ?.map((d) => capitalize(d?.name ?? d))
                  ?.join(", ")}
              </span>
            </div>
          )}
          {job?.departments &&
            job?.departments?.filter((item) => item?.status)?.length > 0 && (
              <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                <span className="text-[#5F6989] text-sm min-w-max">
                  Departments
                </span>
                <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                  {/* If new format - {name: '', status: true/false} */}
                  {Object?.keys(job?.departments[0])?.includes("status")
                    ? job?.departments
                        ?.filter((item) => item?.status === true)
                        ?.map((item) => item?.name)
                        ?.join(",") || <Skeleton />
                    : job?.departments
                        ?.map((item) => capitalize(item))
                        ?.join(",")}
                </span>
              </div>
            )}
          {job?.workplace && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">
                Workplace type
              </span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {job?.workplace}
              </span>
            </div>
          )}
          {job?.job_type?.length > 0 &&
            (typeof job?.job_type[0] == "string" ||
            (job?.job_type?.length > 0 &&
              Object?.keys(job?.job_type[0])?.includes("status"))
              ? job?.job_type?.filter((item) => item?.status === true)?.length >
                0
              : true) && (
              <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                <span className="text-[#5F6989] text-sm min-w-max">
                  Job type
                </span>
                <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                  {/* If new format - {type: '', status: true/false} */}
                  {Object?.keys(job?.job_type[0])?.includes("status")
                    ? job?.job_type
                        ?.filter((item) => item?.status === true)
                        .map((d) => capitalize(d?.type))
                        ?.join(", ")
                    : job?.type.map((d) => capitalize(d))?.join(", ")}
                </span>
              </div>
            )}
          {job?.experienceLevel && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">
                Experience
              </span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {experience}
              </span>
            </div>
          )}
          {job?.salary &&
          Object.entries(job?.salary)?.length > 1 &&
          +job?.salary?.min + +job?.salary?.max > 1 ? (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Salary</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {typeof job?.salary === "string"
                  ? job?.salary
                  : (job?.salary?.currency ?? "$") +
                    job?.salary?.min +
                    " - " +
                    (job?.salary?.currency ?? "$") +
                    job?.salary?.max}
              </span>
            </div>
          ) : job?.expectedSalaryMax || job?.expectedSalaryMin ? (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Salary</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {job?.expectedSalaryMin + " - " + job?.expectedSalaryMax}
              </span>
            </div>
          ) : null}
          {job?.equity && Object.entries(job?.equity)?.length > 1 && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Equity</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {typeof job?.equity === "string"
                  ? job?.equity
                  : job?.equity?.min + " - " + job?.equity?.max + " %"}
              </span>
            </div>
          )}

          {job?.company?.name && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Company</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {job?.company?.name}
              </span>
            </div>
          )}

          {(job?.recruiter_name || job?.creator) && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">Creator</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {job?.recruiter_name ?? job?.creator}
              </span>
            </div>
          )}
          {job?.jobCode && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <span className="text-[#5F6989] text-sm min-w-max">job code</span>
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                {"#" + job?.jobCode}
              </span>
            </div>
          )}
          {job?.atsUrl && (
            <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
              <img
                className="w-5 rounded mx-auto object-cover"
                src={GHLogo}
                alt="Greenhouse integration steps"
              />
              <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                <a href={job?.atsUrl} target="_blank" rel="noreferrer">
                  Visit
                </a>
              </span>
            </div>
          )}
        </div>
      )}
      <div className="mb-5">
        <h4
          className={
            "text-[#16182F] text-[16px] not-italic font-medium " + headingStyle
          }
        >
          Description
        </h4>
        <div className="mt-[12px]">
          <p className="text-textColor1 text-sm not-italic font-normal  job-description">
            {job?.description ? (
              job?.description?.includes("<body>") ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: job?.description?.split("&lt;")?.join("<"),
                  }}
                ></div>
              ) : (
                parse(job?.description?.split("&lt;")?.join("<"))
              )
            ) : (
              "No Content"
            )}
          </p>
          {/* <div
            className={
              "text-textColor1 text-sm not-italic font-normal break-words " +
              paraStyle
            }
          >
            {job?.description ? (
              job?.description?.includes("<body>") ? (
                <div
                  dangerouslySetInnerHTML={{ __html: job?.description }}
                ></div>
              ) : parse(job?.description)?.length > 0 ? (
                parse(getText(job?.description))
              ) : (
                "No Content"
              )
            ) : (
              "No Content"
            )}
          </div> */}
        </div>
      </div>
      {job?.roles && parse(getText(job?.roles))?.length > 0 && (
        <div className="mb-5">
          <h4
            className={
              "text-[#16182F] text-[16px] not-italic font-medium " +
              headingStyle
            }
          >
            Roles and responsibilities
          </h4>
          <div className="mt-[12px]">
            <div
              className={
                "text-textColor1 text-sm not-italic font-normal " + paraStyle
              }
            >
              {parse(getText(job?.roles))}
            </div>
          </div>
        </div>
      )}

      {job?.education?.length > 0 && (
        <div className="mb-5">
          <h4
            className={
              "text-[#16182F] text-[16px] not-italic font-medium " +
              headingStyle
            }
          >
            Experience and education
          </h4>
          <div className="mt-[12px]">
            <div
              className={
                "text-textColor1 text-sm not-italic font-normal " + paraStyle
              }
            >
              {parse(getText(job?.education))}
            </div>
          </div>
        </div>
      )}
      {job?.bonus?.filter((d) => d.status == true).length > 0 && (
        <div className="mb-5">
          <h4
            className={
              "text-[#16182F] text-[16px] not-italic font-medium " +
              headingStyle
            }
          >
            Do you offer any other compensation?
          </h4>
          <div className="mt-[12px]">
            <div className="flex flex-wrap ">
              {job?.bonus
                ?.filter((d) => d.status == true)
                .map((item) => (
                  <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                    <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                      {item?.benifits}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {job?.benefits?.filter((d) => d.status == true).length > 0 && (
        <div>
          <h4
            className={
              "text-[#16182F] text-[16px] not-italic font-medium " +
              headingStyle
            }
          >
            Benefits offered
          </h4>
          <div className="mt-[12px]">
            <div className="flex flex-wrap ">
              {job?.benefits
                ?.filter((d) => d.status == true)
                ?.map((item) => (
                  <div className=" flex items-center rounded-[50px] px-3 py-2 mr-2 mb-2 gap-2 border border-[#E1E1EE] flex-nowrap max-w-full">
                    <span className="text-[#252E4B] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
                      {item?.benifits}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
