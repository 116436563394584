import React, { useState } from "react";

import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme ,inputValues,handleChangeInput ,qno}) => {
  const [value, setValue] = useState(code || "");

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <Editor
        height="85vh"
        width={`100%`}
        language={language}
        theme="vs-dark"
        // language={language || "javascript"}
        // value={value}
        // theme={theme}
        textAlign={"left"}
        defaultValue="// some comment"
        value={inputValues[qno]}
        // onChange={handleEditorChange}
        onChange={(val) => handleChangeInput(val)}
        options={{
          cursorStyle: "line",
          renderWhitespace: "all",
          formatOnPaste: false,
          formatOnType: true,
          wordWrap: true,
          autoIndent: "full",
          minimap: { enabled: false },
        }}
      />
    </div>
  );
};
export default CodeEditorWindow;