import * as d3 from "d3";
import { useEffect, useState } from "react";
import { width } from "styled-system";

export default function ProgressBar(props) {
  const {
    id,
    width,
    height,
    data,
    bgColor,
    barColor,
    hoverText,
    style_classes,
    fromRight,
  } = props;
  var tooltip;
  const barWidth = data * 10 + "%";
  useEffect(() => {
    if (hoverText?.length > 0) {
      d3.select("svg#" + id + " #progress-bar")
        .on("mouseover", (e, i) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .text(() => {
              return hoverText;
            })
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
        })
        .on("mouseout", function () {
          d3.selectAll(".tooltip").remove();
        });
    }
  }, [props]);
  // console.log(
  //   data,
  //   Math.round(Math.abs(10 - data))/10,
  //   width - width * Math.round(Math.abs(10 - data))/10
  // );
  return (
    <svg className={style_classes} id={id} height={height + 2}>
      <rect
        id="full-bar"
        className="w-full"
        x={0}
        y={1}
        fill={bgColor}
        height={height}
        width={width}
        rx={4}
        ry={4}
      />
      <rect
        id="progress-bar"
        className="z-10"
        x={fromRight ? width * Math.round(Math.abs(10 - data))/10 : 0}
        y={1}
        fill={barColor}
        width={barWidth ?? data * 10 + "%"}
        height={height}
        title={hoverText}
        rx={4}
        ry={4}
      />
    </svg>
  );
}
