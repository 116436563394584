import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchJobDetails } from "../../../../redux/services";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
const core = require("../../../../lib/core");

function InviteModal({ modalDismiss }) {
  const { setContentReload } = useContext(JobPageContext);
  const [inputEmail, setInputEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [AssessmentId, setAssessmentId] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState({});
  const [expiryHrs, setExpiryHrs] = useState(60);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();

  const { jobId, jobDetails } = useSelector((state) => {
    return {
      jobDetails: state?.JobExpandSlice?.assessment,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (jobDetails) setAssessmentDetails(jobDetails?.assessments);
  }, [jobDetails]);

  function handleEnter(email) {
    if (/\S+@\S+\.\S+/.test(email)) {
      if (emailList.includes(email)) {
        setWarning("Email already added");
        setInputEmail("");
      } else {
        setWarning("");
        setEmailList([...emailList, email]);
        setInputEmail("");
      }
    } else {
      setWarning("Invalid format for email. Try again");
    }
  }

  const onCancel = () => {
    setEmailList([]);
    setInputEmail("");
    setAssessmentId("");
    setWarning("");
    setExpiryHrs(60);
    modalDismiss();
  };

  const inviteCandidate = async () => {
    try {
      console.log(emailList, inputEmail, jobId, AssessmentId);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_INVITE_CANDIDATE_ASSESSMENT,
        1,
        {
          email: emailList?.length > 0 ? emailList : [inputEmail],
          job: jobId,
          assessment: AssessmentId,
          expiryHrs: expiryHrs,
        }
      );
      if (json.data) {
        setContentReload((prev) => prev + 1);
        setMessage({
          type: "success",
          message: "Invitation sent to candidate.",
        });
        setTimeout(() => modalDismiss(), 2500);
        // toast.success(json.data.message);
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ?? "Something went wrong. Please try again",
        });
        // toast.error(json.error.message);
      }
    } catch (error) {
      setMessage({
        type: "error",
        message: error ?? "Something went wrong. Please try again",
      });
    }
  };

  const modalElements = {
    heading: "Invite candidates",
    subheading: "Add candidates to invite them for assessment.",
    modalDismiss: () => onCancel(),
    onSave: () => {
      inviteCandidate();
    },
    defaultButtons: {
      primaryDisabled: !(
        (emailList?.length > 0 || String(inputEmail)?.length > 0) &&
        AssessmentId?.length > 0 &&
        expiryHrs > 0
      ),
    },
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full assessment">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Assessment
        </h3>
        <select
          name="assessment-dropdown"
          required
          disabled={!(assessmentDetails && assessmentDetails?.length > 0)}
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] disabled:pointer-events-none"
          onChange={(e) => setAssessmentId(e.target.value)}
        >
          {assessmentDetails ? (
            assessmentDetails?.map((item) => (
              <>
                <option value="" hidden>
                  Select assessment
                </option>
                <option key={item?._id} value={item?._id}>
                  {item?.name}
                </option>
              </>
            ))
          ) : (
            <option value="" hidden>
              No assessment available
            </option>
          )}
        </select>
      </div>

      <div className="w-full">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Email
        </h3>
        <input
          type="email"
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          required
          value={inputEmail}
          placeholder="Press enter to insert email"
          onChange={(e) => {
            setWarning("");
            setInputEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
              handleEnter(e.target.value);
            }
          }}
        />
        {warning && <span className="col-xs-12 warning">{warning}</span>}
        {emailList?.length > 0 && (
          <div className="w-full mt-3 flex flex-wrap">
            {emailList.map((item) => (
              <span
                className="inline-flex rounded-lg px-2 py-1 bg-blue-200 gap-2 items-center mr-2 mb-2"
                key={item}
              >
                <span className="text-sm text-gray-800 font-normal !normal-case first-letter:!normal-case">
                  {item}
                </span>
                <button
                  className="inline-block cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setEmailList(emailList.filter((d) => d != item));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M24 24L8 8M24 8L8 24"
                      stroke="#001B4B"
                      strokeWidth="2.8"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="w-full relative">
        <h3 className="text-black text-sm font-normal">Hours till expiry</h3>
        <input
          type="number"
          className="w-full border border-gray-400 pl-4 pr-10 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          required
          value={expiryHrs}
          onChange={(e) => {
            setExpiryHrs(e.target.value);
          }}
        />
        <h3 className="absolute top-[36px] right-2 text-black text-sm font-normal">
          hrs
        </h3>
      </div>
      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
}

export default InviteModal;
