import React, { useState } from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import Applicantlist from "../../components/recruiter/job/candidates/Applicantlist";
import InitialsLogo from "../../components/all/InitialsLogo";
import Skeleton from "react-loading-skeleton";

function HandPickedCandidate() {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="hand-picked"
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <ConversationList
            //   dataList={dataList}
            //   candidateId={candidateId}
            //   setCandidateId={setCandidateId}
            />
          }
        />
        <div className="w-full">
          <HeaderNew />
          <DashboardContent />
        </div>
      </div>
    </div>
  );
}

export default HandPickedCandidate;

const DashboardContent = ({}) => {
  const candidateList = [
    {
      _id: "65a1670b884d7967e8afd3fd",
      proficiencyScores: [
        {
          name: "Machine Learning",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Artificial Intelligence",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Deep Learning",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "MLOps",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Data Science",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Data Mining",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Classification",
          proficiency_score: 0,
          criteria: "AI, Machine Learning and Deep Learning Skills",
        },
        {
          name: "Statistics",
          proficiency_score: 0,
          criteria:
            "Data Analysis, Visualization and Predictive Modeling Skills",
        },
        {
          name: "Analytics",
          proficiency_score: 0,
          criteria:
            "Data Analysis, Visualization and Predictive Modeling Skills",
        },
        {
          name: "R",
          proficiency_score: 0,
          criteria:
            "Data Analysis, Visualization and Predictive Modeling Skills",
        },
        {
          name: "Regression",
          proficiency_score: 0,
          criteria:
            "Data Analysis, Visualization and Predictive Modeling Skills",
        },
        {
          name: "Azure Synapse Analytics",
          proficiency_score: 0,
          criteria:
            "Data Analysis, Visualization and Predictive Modeling Skills",
        },
        {
          name: "Mathematics",
          proficiency_score: 0,
          criteria: "Computer Science, Programming and Automation Skills",
        },
        {
          name: "Computer Programming",
          proficiency_score: 5,
          criteria: "Computer Science, Programming and Automation Skills",
        },
        {
          name: "Python",
          proficiency_score: 0,
          criteria: "Computer Science, Programming and Automation Skills",
        },
        {
          name: "SQL",
          proficiency_score: 0,
          criteria: "Data Management and Big Data Processing Skills",
        },
        {
          name: "Azure ML",
          proficiency_score: 0,
          criteria: "Cloud Computing and Platform Services",
        },
        {
          name: "AWS SageMaker",
          proficiency_score: 0,
          criteria: "Cloud Computing and Platform Services",
        },
        {
          name: "Communication Skills",
          proficiency_score: 6,
          criteria: "Communication, Ethics, and Team Collaboration",
        },
        {
          name: "Leadership",
          proficiency_score: 0,
          criteria: "Strategic Leadership and Operational Management Skills",
        },
        {
          name: "Project Management",
          proficiency_score: 0,
          criteria: "Strategic Leadership and Operational Management Skills",
        },
        {
          name: "Mentoring",
          proficiency_score: 0,
          criteria:
            "Educational Leadership, Coaching, and Training Development",
        },
        {
          name: "Azure DevOps",
          proficiency_score: 0,
          criteria:
            "Advanced Infrastructure and Distributed Systems Development",
        },
        {
          name: "JIRA",
          proficiency_score: 0,
          criteria: "Remote Collaboration, Agile and Scrum Methodologies",
        },
        {
          name: "Visio",
          proficiency_score: 0,
          criteria: "Software Development and Design Skills",
        },
        {
          name: "LucidChart",
          proficiency_score: 0,
          criteria: "Software Development and Design Skills",
        },
        {
          name: "User Experience",
          proficiency_score: 0,
          criteria: "Software Development and Design Skills",
        },
        {
          name: "Snowflake",
          proficiency_score: 0,
          criteria: "Big Data and Cloud-Based Storage Technologies",
        },
        {
          name: "AWS Redshift",
          proficiency_score: 0,
          criteria: "Big Data and Cloud-Based Storage Technologies",
        },
        {
          name: "GCP BigQuery",
          proficiency_score: 0,
          criteria: "Big Data and Cloud-Based Storage Technologies",
        },
        {
          name: "Software Development",
          proficiency_score: 0,
          criteria: "Software Development and Debugging Skills",
        },
      ],
      bestMatchedSkills: [
        "Salesforce.com Administration",
        "Salesforce Lightning",
        "Salesforce.com Development",
      ],
      thumbs_up: false,
      questions_generated: false,
      overallMatch: 0.3,
      jobPositionMatch: 0,
      experienceMatch: 0.5,
      educationMatch: 0.5,
      skillsMatch: 0.2,
      matchSummary:
        "The candidate has experience in Salesforce administration and development, which is not relevant to the Machine Learning Engineer position. The candidate`s education in Computer Science is partially relevant, but the job requires specific skills in Machine Learning, Data Science, and related technologies, which the candidate does not appear to possess. The candidate`s experience is also less than the required 3-5 years.",
      applicant: {
        _id: "659bf27bd230f455e936bb9e",
        email: ["tvsdhanan009@gmail.com"],
        phone: ["+918247824567"],
        first_name: "Sakshi Agarwal",
        source: "Greenhouse",
        title: "Salesforce Technical Support Engineer",
        linkedin: "https://www.linkedin.com/in/sakshiagarwal-16a490202",
        resume_link:
          "https://sproutsai-staging.s3.amazonaws.com/resumes/6551e23c0694321b21ad8700/fa8e38b2-5584-46b3-923e-bec01f83cb81.pdf",
        createdAt: "2024-01-08T13:02:51.393Z",
        updatedAt: "2024-02-02T18:06:47.729Z",
        education: [
          {
            school: "PSIT Kanpur (Pranveer Singh Institute of Technology)",
            degree: "B. Tech (Computer Science)",
            description:
              "The candidate holds a Bachelor of Technology degree in Computer Science from PSIT Kanpur (Pranveer Singh Institute of Technology).",
            start: "2015-01-01T00:00:00.000Z",
            end: "2019-01-01T00:00:00.000Z",
            ongoing: false,
          },
        ],
        experience: [
          {
            company: "Salesforce",
            position: "Technical Support Engineer",
            description:
              "Currently working as a Salesforce Technical Support Engineer at Salesforce. Responsible for providing technical support and assistance to Salesforce users. Utilizing skills in Salesforce.com Administration, Salesforce Lightning, and Salesforce.com Development to troubleshoot and resolve issues.",
            start: "2022-06-30T18:30:00.000Z",
            end: "Present",
            ongoing: true,
          },
          {
            company: "Tata Consultancy Services",
            position: "System Engineer",
            description:
              "Worked as a System Engineer at Tata Consultancy Services. Responsibilities included system administration, troubleshooting, and maintenance tasks.",
            start: "2021-06-30T18:30:00.000Z",
            end: "2021-12-31T18:30:00.000Z",
            ongoing: false,
          },
          {
            company: "Tata Consultancy Services",
            position: "Associate System Engineer",
            description:
              "Worked as an Associate System Engineer at Tata Consultancy Services. Responsibilities included system administration, troubleshooting, and maintenance tasks.",
            start: "2019-07-31T18:30:00.000Z",
            end: "2020-12-31T18:30:00.000Z",
            ongoing: false,
          },
        ],
        location: [],
      },
      current_stage: "Rejected",
      rejection_notes: "h",
    },
  ];
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <div className="flex items-center">
        <InitialsLogo str="J" />
        <div className="flex flex-col ml-[10px]">
          <h2 className="text-md font-semibold not-italic text-[#252E4B]">
            Software engineer
          </h2>
          <span className="text-sm font-normal not-italic text-[#252E4B]">
            Location date
          </span>
        </div>
      </div>

      <div className="mt-[40px] relative">
        <div className="flex relative col-span-12 h-[36px] bg-bg1 items-center px-[22px] ">
          <div className="flex  items-center col-span-4 w-[35%] ">
            <input
              type="checkbox"
              className="w-[20px] h-[20px] rounded-[6px] mt-[-4px]"
              // checked={checkedItems.length !== 0}
              // onChange={}

              // onChange={(e) => {
              //   if (e.target.checked) {
              //     if (activeStatusTab === "All") {
              //       const array = candidateList?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "New") {
              //       const array = candidateList
              //         ?.filter(
              //           (iter) =>
              //             iter?.current_stage === "New Applicant" ||
              //             iter?.current_stage === "Application Review",
              //         )
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "In process") {
              //       const array = candidateList
              //         ?.filter(
              //           (iter) =>
              //             iter?.current_stage !== "New Applicant" &&
              //             iter?.current_stage !== "Application Review" &&
              //             iter?.current_stage !== "Hired" &&
              //             iter?.current_stage !== "Rejected" &&
              //             iter?.current_stage !== "To Be Declined",
              //         )
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "Hired") {
              //       const array = candidateList
              //         ?.filter((iter) => iter?.current_stage === "Hired")
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "Not a good match") {
              //       const array = candidateList
              //         ?.filter((iter) => iter?.overallMatch <= 0.75)
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "Top") {
              //       const array = candidateList
              //         ?.filter((iter) => iter?.overallMatch >= 0.8)
              //         ?.slice(0, topCandidatesCount ?? 20)
              //         ?.map((item) => item?.id);
              //       setCheckedItems(array.slice(0, topCandidatesCount ?? 20));
              //     }
              //     if (activeStatusTab === "Rejected") {
              //       const array = candidateList
              //         ?.filter((iter) => iter?.current_stage === "Rejected")
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "Referral") {
              //       const array = candidateList
              //         ?.filter(
              //           (iter) =>
              //             iter?.applicant?.remote_source === "Referral",
              //         )
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //     if (activeStatusTab === "Internal") {
              //       const array = candidateList
              //         ?.filter(
              //           (iter) =>
              //             iter?.applicant?.remote_source ===
              //             "Internal Applicant",
              //         )
              //         ?.map((item) => item?._id);
              //       setCheckedItems(array);
              //     }
              //   } else {
              //     setCheckedItems([]);
              //   }
              // }}
            />

            <h5 className="text-[#252E4B] not-italic font-normal text-[14px] ml-[14px]">
              Candidate name
            </h5>
          </div>
          <h5 className="flex-initial col-span-2 text-[#252E4B] not-italic font-normal text-[14px]">
            Match with job
          </h5>
        </div>

        {Array.isArray(candidateList) && candidateList?.length > 0 ? (
          <div className="">
            <>
              {candidateList?.map((iter, idx) => (
                <Applicantlist
                  // matchScoreThreshold={matchScoreThreshold}
                  // setShowModal={setShowModal}
                  iter={iter}
                  idx={idx}
                  handPicked={"handPicked"}
                  // setQuestions={setQuestions}
                  // pipeline={pipeline}
                  // setEmail={setEmail}
                  // checkedItems={checkedItems}
                  // company={company}
                  // setCheckedItems={setCheckedItems}
                  // updateStatus={updateStatus}
                  // jobId={jobId}
                  // setHidechatbar={setHidechatbar}
                  // setHideCallBar={setHideCallBar}
                  // setChatDetail={setChatDetail}
                  // removeCandidate={setCandidateDetail}
                  // setCanId={setCanId}
                />
              ))}
            </>
          </div>
        ) : Array.isArray(candidateList) && candidateList?.length === 0 ? (
          <div className="w-full h-full min-h-[calc(100vh-92px-155px-100px)] flex items-center justify-center flex-col">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="140"
                height="131"
                viewBox="0 0 140 131"
                fill="none"
              >
                <path
                  d="M74.4084 129.006C109.686 129.006 138.286 100.406 138.286 65.0031C138.286 29.5998 109.686 1 74.4084 1C39.1311 1 10.5312 29.5998 10.5312 65.0031C10.5312 100.406 39.1311 129.006 74.4084 129.006Z"
                  fill="#F1F3F9"
                  stroke="#D6DCE8"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M133.625 39.4324H24.3911C21.9973 39.4324 20.1074 37.5425 20.1074 35.1487V10.5805C20.1074 8.18673 21.9973 6.29688 24.3911 6.29688H133.499C135.893 6.29688 137.782 8.18673 137.782 10.5805V35.1487C137.908 37.5425 135.893 39.4324 133.625 39.4324Z"
                  fill="white"
                  stroke="#D6DCE8"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M46.5665 22.8087C46.5665 25.5804 45.3066 28.1003 43.4168 29.8641C41.7789 31.376 39.5111 32.2579 37.1172 32.2579C34.7234 32.2579 32.4556 31.376 30.8177 29.8641C28.8019 28.1003 27.668 25.5804 27.668 22.8087C27.668 17.643 31.9516 13.3594 37.1172 13.3594C42.2829 13.3594 46.5665 17.643 46.5665 22.8087Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M43.4174 29.8581C41.7795 31.37 39.5117 32.2519 37.1179 32.2519C34.7241 32.2519 32.4562 31.37 30.8184 29.8581C31.5743 29.6061 32.4562 29.2281 33.5901 28.7242C33.8421 28.5982 34.0941 28.3462 34.2201 28.0942C34.3461 27.9682 34.3461 27.7162 34.3461 27.5902V25.1964L34.2201 25.0704C33.9681 24.8184 33.8421 24.4405 33.8421 24.0625L33.5901 23.9365C32.9602 24.0625 33.0862 23.4326 32.8342 22.1726C32.8342 21.6687 32.8342 21.5427 33.0862 21.4167L33.3382 21.1647C32.2043 18.5189 32.7082 16.8811 33.9681 16.2511C33.5901 15.3692 33.5901 15.1172 33.5901 15.1172C33.5901 15.1172 36.11 15.4952 36.8659 15.3692C37.9998 15.1172 39.6377 15.3692 40.2676 16.8811C41.2756 17.259 41.6535 17.889 41.7795 18.6449C41.9055 19.7788 41.2756 21.0387 41.1496 21.4167C41.2756 21.5427 41.4016 21.6687 41.2756 22.0467C41.1496 23.1806 41.1496 23.9365 40.5196 23.8105L40.0157 24.9444C40.0157 25.0704 40.0157 25.0704 40.0157 25.1964C40.0157 25.3224 40.0157 25.7004 40.0157 27.7162C40.0157 27.9682 40.1416 28.2202 40.1416 28.3462C40.2676 28.5982 40.5196 28.7242 40.6456 28.8501C41.7795 29.2281 42.6615 29.6061 43.4174 29.8581Z"
                  fill="white"
                />
                <path
                  d="M41.1823 21.5427C41.1823 21.2804 41.0511 20.887 40.92 20.6248V20.4936C40.3955 19.4446 39.3464 19.0512 38.2973 19.0512C35.6747 18.92 35.4124 19.4446 34.4945 18.6578C34.7567 19.0512 34.7567 19.7068 34.3633 20.4936C34.1011 21.0182 33.5765 21.2804 33.052 21.4116C31.8718 18.6578 32.3963 16.9531 33.7077 16.2974C33.3143 15.3795 33.3143 15.1172 33.3143 15.1172C33.3143 15.1172 35.9369 15.5106 36.7237 15.3795C37.9039 15.1172 39.6087 15.3795 40.2643 16.9531C41.3134 17.3465 41.7068 18.0021 41.8379 18.7889C41.9691 19.7068 41.3134 21.0182 41.1823 21.5427Z"
                  fill="#AAB2C5"
                />
                <path
                  d="M40.1423 28.3461V28.85H34.2207V28.2201C34.3467 28.0941 34.3467 27.8421 34.3467 27.7161V25.3223L34.2207 25.1963V25.0703C34.3467 25.3223 34.4727 25.4483 34.7247 25.7003L36.6145 26.9602C36.9925 27.3381 37.6224 27.3381 38.1264 26.9602L39.8903 25.4483L40.0163 25.3223C40.0163 25.4483 40.0163 25.8263 40.0163 27.8421C39.8903 27.9681 40.0163 28.0941 40.1423 28.3461Z"
                  fill="url(#paint0_linear_4307_81330)"
                />
                <path
                  d="M77.5594 20.025H53.3693C52.7393 20.025 52.3613 19.521 52.3613 19.0171V15.8673C52.3613 15.2373 52.8653 14.8594 53.3693 14.8594H77.5594C78.1894 14.8594 78.5673 15.3633 78.5673 15.8673V19.0171C78.5673 19.647 78.1894 20.025 77.5594 20.025Z"
                  fill="#1369E9"
                />
                <path
                  d="M106.285 30.7359H53.3693C52.7393 30.7359 52.3613 30.232 52.3613 29.728V26.5782C52.3613 25.9483 52.8653 25.5703 53.3693 25.5703H106.285C106.915 25.5703 107.293 26.0743 107.293 26.5782V29.728C107.293 30.3579 106.789 30.7359 106.285 30.7359Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M133.625 122.073H24.3911C21.9973 122.073 20.1074 120.183 20.1074 117.789V93.2212C20.1074 90.8273 21.9973 88.9375 24.3911 88.9375H133.499C135.893 88.9375 137.782 90.8273 137.782 93.2212V117.789C137.908 120.183 135.893 122.073 133.625 122.073Z"
                  fill="white"
                  stroke="#D6DCE8"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M46.5665 105.449C46.5665 108.221 45.3066 110.741 43.4168 112.505C41.7789 114.017 39.5111 114.899 37.1172 114.899C34.7234 114.899 32.4556 114.017 30.8177 112.505C28.8019 110.741 27.668 108.221 27.668 105.449C27.668 100.284 31.9516 96 37.1172 96C42.2829 96 46.5665 100.284 46.5665 105.449Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M43.4174 112.499C41.7795 114.011 39.5117 114.893 37.1179 114.893C34.7241 114.893 32.4562 114.011 30.8184 112.499C31.5743 112.247 32.4562 111.869 33.5901 111.365C33.8421 111.239 34.0941 110.987 34.2201 110.735C34.3461 110.609 34.3461 110.357 34.3461 110.231V107.837L34.2201 107.711C33.9681 107.459 33.8421 107.081 33.8421 106.703L33.5901 106.577C32.9602 106.703 33.0862 106.073 32.8342 104.813C32.8342 104.309 32.8342 104.183 33.0862 104.057L33.3382 103.805C32.2043 101.16 32.7082 99.5217 33.9681 98.8917C33.5901 98.0098 33.5901 97.7578 33.5901 97.7578C33.5901 97.7578 36.11 98.1358 36.8659 98.0098C37.9998 97.7578 39.6377 98.0098 40.2676 99.5217C41.2756 99.8996 41.6535 100.53 41.7795 101.286C41.9055 102.419 41.2756 103.679 41.1496 104.057C41.2756 104.183 41.4016 104.309 41.2756 104.687C41.1496 105.821 41.1496 106.577 40.5196 106.451L40.0157 107.459C40.0157 107.585 40.0157 107.585 40.0157 107.711C40.0157 107.837 40.0157 108.215 40.0157 110.231C40.0157 110.483 40.1416 110.735 40.1416 110.861C40.2676 111.113 40.5196 111.239 40.6456 111.365C41.7795 111.995 42.6615 112.247 43.4174 112.499Z"
                  fill="white"
                />
                <path
                  d="M41.1823 104.183C41.1823 103.921 41.0511 103.528 40.92 103.265V103.134C40.3955 102.085 39.3464 101.692 38.2973 101.692C35.6747 101.561 35.4124 102.085 34.4945 101.298C34.7567 101.692 34.7567 102.347 34.3633 103.134C34.1011 103.659 33.5765 103.921 33.052 104.052C31.8718 101.298 32.3963 99.5937 33.7077 98.938C33.3143 98.0201 33.3143 97.7578 33.3143 97.7578C33.3143 97.7578 35.9369 98.1512 36.7237 98.0201C37.9039 97.7578 39.6087 98.0201 40.2643 99.5937C41.3134 99.9871 41.7068 100.643 41.8379 101.43C41.9691 102.479 41.3134 103.659 41.1823 104.183Z"
                  fill="#AAB2C5"
                />
                <path
                  d="M40.1423 110.987V111.491H34.2207V110.861C34.3467 110.735 34.3467 110.483 34.3467 110.357V107.963L34.2207 107.837V107.711C34.3467 107.963 34.4727 108.089 34.7247 108.341L36.6145 109.601C36.9925 109.979 37.6224 109.979 38.1264 109.601L39.8903 108.089L40.0163 107.963C40.0163 108.089 40.0163 108.467 40.0163 110.483C39.8903 110.609 40.0163 110.861 40.1423 110.987Z"
                  fill="url(#paint1_linear_4307_81330)"
                />
                <path
                  d="M77.5594 102.798H53.3693C52.7393 102.798 52.3613 102.294 52.3613 101.79V98.6407C52.3613 98.0108 52.8653 97.6328 53.3693 97.6328H77.5594C78.1894 97.6328 78.5673 98.1368 78.5673 98.6407V101.79C78.5673 102.294 78.1894 102.798 77.5594 102.798Z"
                  fill="#1369E9"
                />
                <path
                  d="M106.285 113.509H53.3693C52.7393 113.509 52.3613 113.005 52.3613 112.501V109.352C52.3613 108.722 52.8653 108.344 53.3693 108.344H106.285C106.915 108.344 107.293 108.848 107.293 109.352V112.501C107.293 113.005 106.789 113.509 106.285 113.509Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M115.104 80.7527H5.99656C3.60275 80.7527 1.71289 78.8628 1.71289 76.469V51.9009C1.71289 49.507 3.60275 47.6172 5.99656 47.6172H115.104C117.498 47.6172 119.388 49.507 119.388 51.9009V76.469C119.514 78.8628 117.498 80.7527 115.104 80.7527Z"
                  fill="white"
                  stroke="#D6DCE8"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M59.1629 61.3453H34.9728C34.3428 61.3453 33.9648 60.8413 33.9648 60.3374V57.1876C33.9648 56.5577 34.4688 56.1797 34.9728 56.1797H59.1629C59.7929 56.1797 60.1708 56.6836 60.1708 57.1876V60.3374C60.1708 60.9673 59.7929 61.3453 59.1629 61.3453Z"
                  fill="#1369E9"
                />
                <path
                  d="M87.8887 72.1812H34.9728C34.3428 72.1812 33.9648 71.6773 33.9648 71.1733V68.0235C33.9648 67.3936 34.4688 67.0156 34.9728 67.0156H87.7628C88.3927 67.0156 88.7707 67.5196 88.7707 68.0235V71.1733C88.8967 71.6773 88.3927 72.1812 87.8887 72.1812Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M28.296 64.129C28.296 66.7748 27.1621 69.1686 25.3983 70.9325C25.2723 71.0584 25.2723 71.0584 25.1463 71.1844C23.5084 72.6963 21.2406 73.5782 18.8468 73.5782C16.4529 73.5782 14.3111 72.6963 12.6732 71.3104C12.6732 71.3104 12.6732 71.3104 12.5472 71.3104C10.5314 69.5466 9.27148 67.0267 9.27148 64.129C9.27148 58.9634 13.5552 54.6797 18.7208 54.6797C24.0124 54.6797 28.296 58.9634 28.296 64.129Z"
                  fill="#D6DCE8"
                />
                <path
                  d="M25.3595 71.1269C24.9762 70.2326 24.3374 69.466 23.3152 68.9549C22.8042 68.6994 22.4209 68.5716 21.782 68.5716H21.3987C20.5044 68.5716 20.3766 68.4438 20.3766 68.4438V66.9106C21.3987 66.144 22.1653 65.1219 22.4209 63.972C23.0597 63.8442 23.443 63.3332 23.3152 62.6943C23.3152 62.4388 23.0597 62.1833 23.0597 61.9277C23.0597 61.8 23.0597 61.6722 23.0597 61.5444C23.0597 61.4167 23.0597 61.4167 23.0597 61.2889C23.0597 61.1611 23.0597 61.1611 23.0597 61.0334C23.0597 60.5223 22.9319 60.139 22.5486 59.6279C21.782 58.2225 20.2488 57.3281 18.5879 57.3281C18.2046 57.3281 17.949 57.3281 17.5657 57.4559C16.9269 57.5837 16.4158 57.8392 15.9048 58.2225C15.777 58.3503 15.777 58.3503 15.6492 58.478C15.6492 58.478 15.6492 58.478 15.5215 58.6058C15.0104 59.1169 14.6271 59.7557 14.3716 60.3945C14.116 61.0334 14.116 61.8 14.116 62.5666C14.116 62.5666 14.116 62.5666 14.116 62.6943C14.116 62.8221 14.116 62.8221 14.116 62.9499C14.116 62.9499 14.116 63.0776 13.9883 63.0776C14.2438 62.8221 14.116 63.2054 14.2438 63.4609C14.4993 64.0998 14.8826 63.972 15.3937 64.3553C15.3937 64.3553 15.3937 64.3553 15.2659 64.3553L14.7549 64.4831C11.944 65.3774 11.6885 68.3161 14.4993 68.9549C14.2438 69.0827 14.116 69.2104 13.9883 69.2104C13.2217 69.8493 12.7106 70.6159 12.4551 71.3825C14.116 72.7879 16.2881 73.6822 18.7156 73.6822C21.1432 73.6822 23.443 72.7879 25.104 71.2547C25.2317 71.3824 25.3595 71.2547 25.3595 71.1269C25.4873 71.1269 25.4873 71.1269 25.3595 71.1269ZM17.438 68.0605C17.3102 67.9327 17.1824 67.805 17.1824 67.805C17.0547 67.6772 16.9269 67.6772 16.7991 67.5495C16.7991 67.5495 16.6714 67.5494 16.6714 67.4217C16.2881 67.1662 15.9048 66.9106 15.777 66.5273C15.777 66.3996 15.9048 66.144 16.1603 65.8885C16.2881 66.0163 16.4158 66.144 16.5436 66.3996C16.6714 66.5273 16.7991 66.6551 16.9269 66.7829C17.0547 66.7829 17.1824 66.9106 17.1824 67.0384C17.1824 67.0384 17.1824 67.0384 17.3102 67.0384C17.3102 67.0384 17.3102 67.0384 17.438 67.0384V68.0605Z"
                  fill="white"
                />
                <path
                  d="M21.5523 68.4972C21.4263 69.0012 21.1744 69.3792 21.1744 69.3792L16.3867 68.6232L16.6387 67.3633C16.6387 67.3633 16.7647 67.3633 16.7647 67.4893C16.8907 67.6153 17.0167 67.6153 17.1427 67.7413C17.2687 67.7413 17.2687 67.8673 17.3946 67.9933V67.1114C17.3946 67.1114 17.3946 67.1114 17.2687 67.1114C18.0246 67.4893 19.0325 67.7413 20.4184 66.8594V68.3713C20.5444 68.3713 20.6704 68.4972 21.5523 68.4972Z"
                  fill="url(#paint2_linear_4307_81330)"
                />
                <path
                  d="M20.5601 61.4673C21.4589 61.4673 22.3577 61.3389 23.1281 61.0821C23.1281 61.0821 23.1281 60.9537 23.2565 60.8253C23.1281 60.44 22.9997 59.9264 22.7429 59.4128C21.9725 58.0004 20.4317 57.1016 18.7624 57.1016C18.3772 57.1016 18.0077 57.129 17.4925 57.2574C16.8069 57.467 16.4885 57.6512 16.3261 57.7496C16.3261 57.7496 15.9508 58.002 15.6808 58.2572C15.4107 58.5124 14.7819 59.4128 14.5251 60.0548C14.2683 60.8253 14.0611 61.4801 14.1895 62.2505C14.3179 62.2505 14.5251 62.1093 14.6535 62.1093C15.6808 61.5957 16.4512 60.9537 16.8364 59.9264C17.8636 60.8253 19.1476 61.3389 20.5601 61.4673Z"
                  fill="#AAB2C5"
                />
                <path
                  d="M16.2012 66.0156C16.3272 66.1416 16.4532 66.2676 16.5791 66.3936C16.4532 66.2676 16.3272 66.1416 16.2012 66.0156Z"
                  fill="#3973F4"
                />
                <path
                  d="M17.965 70.6745C17.5871 71.1785 16.9571 71.1785 16.3271 71.1785C16.9571 70.5485 16.5791 68.6587 14.5633 68.9107C11.6655 68.4067 12.0435 65.3829 14.8153 64.501L15.3192 64.375C15.3192 64.375 15.3192 64.375 15.4452 64.501C15.6972 65.1309 15.9492 65.5089 16.3271 66.0129C15.1932 67.0208 16.8311 67.2728 17.5871 68.0287C18.091 68.2807 18.595 69.9186 17.965 70.6745Z"
                  fill="#AAB2C5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4307_81330"
                    x1="37.1833"
                    y1="28.873"
                    x2="37.1833"
                    y2="26.7908"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E2E5EC" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_4307_81330"
                    x1="37.1833"
                    y1="111.514"
                    x2="37.1833"
                    y2="109.431"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E2E5EC" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_4307_81330"
                    x1="18.9711"
                    y1="69.3945"
                    x2="18.9711"
                    y2="68.0064"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E2E5EC" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="text-[#252E4B] font-medium text-[18px] leading-[17px] not-italic mt-[24px]">
              No candidate found
            </span>
            <span className="text-[#5F6989] font-normal text-[14px] leading-[17px] not-italic mt-[8px]">
              Add candidate if you have the
            </span>
            <span className="text-[#5F6989] font-normal text-[14px] leading-[17px] not-italic">
              resume in hand or promote the job
            </span>
          </div>
        ) : (
          <>
            {/* {scaltenNumber?.map((item, index) => (
                <div className="" key={index}>
                  <div className="flex items-center col-span-12 data-row">
                    <div className="col-span-4 w-[35%] pr-[10px]   flex">
                      <div className="mr-[14px]">
                        <input
                          type="checkbox"
                          className="w-[20px] h-[20px] border border-[#DADAE4] rounded-[6px] bg-[#FFF] "
                        />
                      </div>
                      <Skeleton width={"40px"} height={"40px"} />
                      <div className="col-span-8 ml-[14px]">
                        <h4 className=" text-[16px] not-italic font-medium text-[#252E4B] ">
                          <Skeleton width={"200px"} height={"10px"} />
                        </h4>
                        <h6 className="text-[14px] not-italic font-normal text-[#5F6989] ">
                          <Skeleton width={"100px"} height={"10px"} />
                        </h6>
                        <div className=" flex mt-[10px]"></div>
                      </div>
                    </div>

                    <div className="col-span-4 flex flex-col w-[25%]">
                      <div className="flex items-center "></div>
                    </div>

                    <div className="col-span-4 relative data-item flex justify-end  w-[40%]">
                      <button
                        className="border-button rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF]  ml-[12px] flex items-center justify-center"
                        data-toggle="modal"
                        data-target="#Question-modal"
                        title="Questions"
                      >
                        <Skeleton width={"32px"} height={"32px"} />
                      </button>
                      <button
                        className="border-button rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF]  ml-[12px] flex items-center justify-center"
                        data-toggle="modal"
                        data-target="#Question-modal"
                        title="Questions"
                      >
                        <Skeleton width={"32px"} height={"32px"} />
                      </button>
                      <button
                        className="border-button rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF]  ml-[12px] flex items-center justify-center"
                        data-toggle="modal"
                        data-target="#Question-modal"
                        title="Questions"
                      >
                        <Skeleton width={"32px"} height={"32px"} />
                      </button>
                      <button
                        className="border-button rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF]  ml-[12px] flex items-center justify-center"
                        data-toggle="modal"
                        data-target="#Question-modal"
                        title="Questions"
                      >
                        <Skeleton width={"32px"} height={"32px"} />
                      </button>
                    </div>
                  </div>
                </div>
              ))} */}
          </>
        )}
      </div>
    </main>
  );
};

function ConversationList({}) {
  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)]  ">
      {/* <div className="overflow-y-scroll h-[calc(100vh-180px)] scrollbar-candidate ">
          {dataList &&
            dataList?.map((data, i) => (
              <li
                key={`job${i}`}
                onClick={(e) => setCandidateId(data?.id)}
                className={`flex items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2 border-b border-gray-300 group gap-2 ${data?.id === candidateId && "bg-[#F2F4F8]"}`}
              >
                <div className="flex flex-col w-[200px] grow">
                  <span
                    className={`inline-block text-sm not-italic  text-gray-800 truncate ${true ? "font-semibold" : "font-medium"
                      }`}
                    title={data?.candidateName}
                  >
                    {data?.candidateName}
                  </span>
                  <span className=" items-center gap-2 justify-start">
                    <span
                      className={`inline-block text-sm text-[#5F6989] truncate`}
                    >
                      {data?.createdAt &&
                        dateFormatter.format(new Date(data?.createdAt)) +
                        " " +
                        timeFormatter.format(new Date(data?.createdAt))}
                    </span>
                  </span>
                </div>
              </li>
            ))}
        </div> */}
      <div className="overflow-y-scroll h-[calc(100vh-180px)] scrollbar-candidate ">
        <li
          className={`flex items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2 border-b border-gray-300 group gap-2 `}
        >
          <div className="flex flex-col w-[200px] grow">
            <span
              className={`inline-block text-sm not-italic  text-gray-800 truncate ${
                true ? "font-semibold" : "font-medium"
              }`}
              // title={data?.candidateName}
            >
              {"data?.candidateName"}
            </span>
            <span className=" items-center gap-2 justify-start">
              <span className={`inline-block text-sm text-[#5F6989] truncate`}>
                Total candidates : 20
                {/* {data?.createdAt &&
                        dateFormatter.format(new Date(data?.createdAt)) +
                        " " +
                        timeFormatter.format(new Date(data?.createdAt))} */}
              </span>
            </span>
          </div>
        </li>
      </div>
    </div>
  );
}
