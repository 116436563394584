import React, { useContext } from "react";
import SvgIcon1 from "./icons/SvgIcon1";
import SvgIcon2 from "./icons/SvgIcon2";
import SvgIcon3 from "./icons/SvgIcon3";
import SvgIcon4 from "./icons/SvgIcon4";
import SvgIcon5 from "./icons/SvgIcon5";
import SvgIcon6 from "./icons/SvgIcon6";
import SvgIcon7 from "./icons/SvgIcon7";
import SvgIcon8 from "./icons/SvgIcon8";
import InitialsLogo from "../../all/InitialsLogo";
import { useState } from "react";
import ProfileSection from "./Profile";
import { useSelector } from "react-redux";
import ScheduleInterview from "../applicant/ScheduleInterview";
import InviteModal from "../job/assessment/InviteModal";
import { CandidatePageContext } from "../../../lib/context";

const TopBanner = ({ setActiveTab, btnList }) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const [activeTabName, setActiveTabName] = useState("Details");
  const [showMenu, setShowMenu] = useState(false);
  const profile = useSelector((state) => state?.jobCandidateMatch?.profile);
  const activeAssessments = useSelector((state) =>
    state?.JobExpandSlice?.assessment?.assessments?.filter(
      (d) => d?.status == "active",
    ),
  );

  return (
    profile && (
      <>
        <div
          id="topBanner"
          className="w-full px-6 border-t-0 border-x-0 border-b border-[#e1e1ee] border-solid"
        >
          <div className="flex justify-between items-start flex-row gap-2 ">
            <div className="flex justify-start items-start flex-row shrink ">
              <InitialsLogo
                width="56px"
                height="56px"
                fontSize="24px"
                styleclassName="block rounded-xl border-[none]"
                str={profile?.matchProfile?.first_name}
              />
              <div className=" shrink ml-3.5 pb-[8.5px]">
                <p className=" text-lg font-medium text-[#252e4b]">
                  {profile?.matchProfile?.first_name}{" "}
                  {profile?.matchProfile?.last_name?.length
                    ? profile?.matchProfile?.last_name
                    : ""}
                </p>
                {profile?.matchProfile?.title && (
                  <p className=" text-sm overflow-hidden text-ellipsis whitespace-nowrap text-[#5f6989] mt-[8px]">
                    {profile?.matchProfile?.title}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-end items-stretch flex-row gap-3 h-10 grow basis-[265px] ">
              {/* Invite candidate for assessment */}
              {/* {activeAssessments?.length > 0 && (
                <button
                  onClick={() => setShowModal({type:"assessment"})}
                  className="border shadow-[0px_2px_0px_rgba(0,0,0,0.04)] bg-white flex justify-center items-stretch flex-col px-2.5 rounded-lg border-[#dadae4] border-solid"
                >
                  <SvgIcon1 className="w-5 h-5 text-[#252e4b] flex" />
                </button>
              )} */}
              {/* Schedule interview candidate */}

              {/* <button
                onClick={() => setShowModal({ type: "interview", edit: false })}
                className="border shadow-[0px_2px_0px_rgba(0,0,0,0.04)] bg-white flex justify-center items-stretch flex-col px-2.5 rounded-lg border-[#dadae4] border-solid"
              >
                <SvgIcon2 className="w-5 h-5 text-[#252e4b] flex" />
              </button> */}

              {/* Select Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
              {/* <button
                onClick={() => {
                  console.log(showMenu);
                  setShowMenu((prev) => !prev);
                }}
                className="btn-md btn-sec shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
              >
                <span className="text-base text-[#252e4b]">
                  {profile?.matchProfile?.current_stage}
                </span>
                <SvgIcon3 className="w-4 h-4 text-[#252e4b] flex" />
                {showMenu && (
                  <div className="top-[80px] bg-white absolute w-[200px] p-1 rounded-lg border border-[#dadae4] shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                    {profile?.job?.stages?.map((stage) => (
                      <button className="w-full text-sm text-gray-800 hover:bg-gray-300 px-2 py-1 text-left">
                        {stage}
                      </button>
                    ))}
                  </div>
                )}
              </button> */}
            </div>
          </div>
          <div className="flex justify-start items-stretch flex-row gap-2.5 h-8 mt-2">
            {/* <div
              className={`bg-[rgba(0,172,133,0.10)] flex justify-start items-center flex-row gap-1.5 px-2 rounded-lg`}
            >
              <SvgIcon1 className="w-[18px] h-[18px] text-[#00ac85] flex" />
              <p className={` text-sm font-medium text-[#252e4b]`}>
                {profile?.matchProfile?.overallMatch * 100}% Match
              </p>
            </div> */}
            {profile?.matchProfile?.linkedin && (
              <a
                className="btn-sec w-8 h-8 px-1"
                href={profile?.matchProfile?.linkedin}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                    fill="#5F6989"
                  />
                </svg>
              </a>
            )}
            {profile?.matchProfile?.github && (
              <a href={profile?.matchProfile?.github}>
                <SvgIcon5 className="w-8 h-8" />
              </a>
            )}
            {profile?.matchProfile?.email?.length > 0 && (
              <a href={profile?.matchProfile?.email[0]}>
                <SvgIcon6 className="w-8 h-8" />
              </a>
            )}
            {/* {!profile?.link && (
            <a href={profile?.link}>
              <SvgIcon7 className="w-8 h-8 flex" />
            </a>
          )} */}
            {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
            {/* <button className="w-[78px] h-8 border bg-white shadow-[0px_1px_0px_rgba(0,0,0,0.04)] text-sm text-[#5f6989] cursor-pointer inline-flex items-center justify-center gap-1.5 rounded-lg border-[#e1e1ee] border-solid">
            <SvgIcon8 className="w-5 h-5 text-[#5f6989] flex" />
            Chat
          </button> */}
          </div>
          <div className="flex justify-start items-center flex-row gap-6 mt-7">
            {btnList.map((btn) => (
              <button
                onClick={() => {
                  setActiveTab(btn.component);
                  setActiveTabName(btn.name);
                }}
                className={`text-base font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                  btn.name == activeTabName
                    ? "border-[#1369e9] text-[#16182f]"
                    : "border-transparent text-[#252e4b]"
                } border-solid hover:border-blue-600`}
              >
                {btn?.name}
              </button>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default TopBanner;
