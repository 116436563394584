import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/services";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import DashboardPipeline from "../../components/recruiter/dashBoard/DashboardPipeline";
import DashboardInterviewSection from "../../components/recruiter/dashBoard/DashboardInterviewSection";
import DashboardTask from "../../components/recruiter/dashBoard/DashboardTask";
import InstantMeeting from "../../components/recruiter/dashBoard/InstantMeet";
import "../../style/DashboardIframe.scss";
const core = require("../../lib/core");

function DashboardRecruiter() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // Initialize page to 1
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = useSelector(
    (state) => state?.dashboardSlice?.dashboardData,
  );

  const dashData = useMemo(() => fetchData, [fetchData]);

  useEffect(() => {
    if (dashData?.length === 0) {
      setHasMore(false);
    } else {
      setData(dashData?.pipeline ?? dashData);
      setHasMore(true);
    }
  }, [dashData]);

  useEffect(() => {
    if (page === 1) {
      getDashboard();
    }
  }, []); // Fetch data when page changes

  const dispatch = useDispatch();

  const getDashboard = async () => {
    setPage(page + 1);
    try {
      await dispatch(fetchDashboardData(page)); // Use the current page value
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const getRecruiterDashboard = async () => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_DASHBOARD + "?page=" + page,
        1,
      );

      if (list?.data?.content?.length > 0) {
        setData((prevData) => [...prevData, ...list?.data?.content]);
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
        setLoading(true);
      }
    } catch (error) {}
  };

  const dashboardContentRef = useRef(null);

  const handleScroll = () => {
    if (!dashboardContentRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } =
      dashboardContentRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && loading && hasMore) {
      setLoading(false);
      getRecruiterDashboard();
    }
  };

  const sortByAlphabate = () => {
    let sortData = data.sort((a, b) => a.position.localeCompare(b.position));
    setData(sortData);
  };

  const sortByDate = () => {
    let sortData = data.sort((a, b) => {
      if (a.name === b.name) {
        return new Date(a.date) - new Date(b.date);
      }
      return 0;
    });
    setData(sortData);
  };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar active="Dashboard" />
        <div className="w-full">
          <HeaderNew />
          <DashboardContent
            data={data}
            handleScroll={handleScroll}
            dashboardContentRef={dashboardContentRef}
            loading={loading}
            sortByAlphabate={sortByAlphabate}
            sortByDate={sortByDate}
          />
          <InstantMeeting />
        </div>
      </div>
    </div>
  );
}

export default DashboardRecruiter;

const DashboardContent = ({
  data,
  jobId,
  params,
  setParams,
  handleScroll,
  reload,
  dashboardContentRef,
  setReload,
  sortByAlphabate,
  sortByDate,
  loading,
  ...props
}) => {
  const [org, setOrg] = useState();

  useEffect(() => {
    let org = core.getData(core.data.ORGANIZATION);
    setOrg(org);
  }, []);

  console.log(org);
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"
      onScroll={handleScroll}
      ref={dashboardContentRef}
    >
      <div className="flex">
        {/* <span className="font-semibold text-[25px] not-italic text-[#1369E9] mx-2"> {org?.name ? org?.name+"'s" : <></>}</span> */}
        <span className="font-semibold text-[25px] not-italic text-[#16182F]">
          Dashboard
        </span>
      </div>

      <div className="grid grid-cols-4 gap-5 mt-[18px] ">
        <div className="col-span-3 ...">
          {core.local() && (
            <div
              id="dashboardAnalytics"
              className="justify-between flex flex-row gap-5 mb-5"
            >
              <div className="w-1/5 flex flex-col justify-center rounded-[12px] bg-[#FFF] shadow-PipelineCardsShadow">
                <h1 className="w-full text-md font-medium text-gray-700 px-3 py-2">
                  New applicants
                </h1>
                <iframe
                  className="w-full bg-white [div]:border-0 border-0  h-[160px]"
                  title="New Applicants"
                  frameBorder="0"
                  src={core.GRAFANA_URL + "1"}
                />
              </div>
              <div className="w-[30%] flex flex-col rounded-[12px] bg-[#FFF] shadow-PipelineCardsShadow">
                <h1 className="w-full text-md font-medium text-gray-700 px-3 py-2">
                  Candidates selected for interview (%)
                </h1>
                <iframe
                  className="w-full bg-white [div]:border-0 border-0  h-[160px]"
                  title="% of candidates accepted"
                  src={core.GRAFANA_URL + "6"}
                />
              </div>
              <div className="w-2/5 grow flex flex-col rounded-[12px] bg-[#FFF] shadow-PipelineCardsShadow">
                <h1 className="w-full text-md font-medium text-gray-700 px-3 py-2">
                  Sprouts sourced candidate
                </h1>
                <iframe
                  className="w-full bg-white [div]:border-0 border-0  h-[160px]"
                  title="Sprouts Sourced candidate"
                  src={core.GRAFANA_URL + "3"}
                />
              </div>
            </div>
          )}
          <DashboardPipeline
            data={data}
            loading={loading}
            sortByDate={sortByDate}
            sortByAlphabate={sortByAlphabate}
          />
        </div>
        <div className="col-span-1">
          {/* <DashboardInterviewSection /> */}
          <DashboardTask data={data} />
        </div>
      </div>
    </main>
  );
};
