import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../modal/Modal";
import InputBox from "../../../layouts/InputBox";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { useSelector } from "react-redux";
const core = require("../../../lib/core");

const SequencingModal = ({ data, modalDismiss, changeModal, candidates }) => {
  const { user } = useContext(core.UserContext);
  const [edit, setEdit] = useState(-1);
  const [steps, setSteps] = useState([]);
  const [message, setMessage] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState(() => EditorState.createEmpty());
  const [waitUpdated, setWaitUpdated] = useState(false);
  const { jobId, jobName } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
      jobName: state?.JobExpandSlice?.profile?.position,
    };
  });

  useEffect(() => {
    console.log("loop");
    getTemplateData();
  }, []);

  const getTemplateData = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_DATA + data?._id,
      1,
    );
    if (json?.response) {
      setSteps(json?.response?.steps);
    }
  };

  const enableEdit = (i) => {
    setEdit(i);
    setSubject(steps[i]?.template?.subject);
    setBody(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(
            new String(
              steps[i]?.template?.body?.split("&lt;").join("<"),
            ).toString(),
          ),
        ),
      ),
    );
  };

  const editTemplate = async () => {
    let json = await core.API(
      core.API_METHODS.PUT,
      core.TEMPLATE_API.PUT_TEMPLATE_DATA + steps[edit]?.template_id,
      1,
      {
        subject: subject,
        body: convertToHTML(body?.getCurrentContent()),
      },
    );
    if (json?.data) {
      setEdit(-1);
      setMessage({ type: "success", message: "Template updated successfully" });
    } else {
      setMessage({
        type: "error",
        message:
          json?.error?.message ??
          "Unable to update the template. Please try again",
      });
    }
    console.log(json);
  };

  const submitSequence = async () => {
    try {
      if (!user?.refresh_token && templateData?.length > 0) {
        setMessage({
          type: "error",
          message: (
            <p>
              Your account is not connected with any email provider. Please
              connect your account to send emails. Verify from your settings
              page.{" "}
              <Link className="text-blue font-semibold" to="/settings">
                Go to settings.
              </Link>
            </p>
          ),
        });
        setTimeout(() => {
          return;
        }, 3000);
      }
      if (waitUpdated) {
        let tempData = steps?.map((iter) => {
          let { template, ...rest } = iter;
          return rest;
        });
        let json = await core.API(
          core.API_METHODS.PUT,
          core.SEQUENCE_API.PUT_SEQUENCE_DATA + data?._id,
          1,
          {
            steps: tempData,
          },
        );
        if (json?.code && json?.code != 200) {
          setMessage({
            type: "error",
            message:
              json?.message ??
              "Unable to update the wait time. Please try again",
          });
        }
      }
      let json = await core.API(
        core.API_METHODS.POST,
        core.SEQUENCE_API.POST_ADD_SEQUENCE,
        1,
        {
          from_email_address: user?.email,
          job_id: jobId,
          org_name: user?.organization?.name,
          job_name: jobName,
          sequence_id: data?._id,
          applicants: candidates,
        },
      );
      if (json?.data) {
        setMessage({ type: "success", message: "Sequence added successfully" });
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ??
            "Unable to add the sequence. Please try again",
        });
      }
    } catch (err) {
      setMessage({
        type: "error",
        message: "Unable to add the sequence. Please try again",
      });
    }
  };

  const changeWaitTime = (iter, value) => {
    setWaitUpdated(true);
    let temp = [...steps];
    temp[iter].wait_time = value;
    setSteps(temp);
  };

  return (
    <Modal
      heading={data?.name}
      modalDismiss={modalDismiss}
      noBtns={true}
      className={"sm:!max-w-[60%]"}
    >
      {steps?.map((iter, i) =>
        iter?.template_id ? (
          <div
            className={`w-full border-gray-500 border-b flex flex-col gap-3`}
            key={i}
          >
            <div className="w-full flex justify-between items-center">
              <h2 className="text-gray-700 text-base not-italic font-normal ">
                {iter?.template?.template_name}
              </h2>
              <button
                disabled={edit > -1 && edit != i}
                onClick={() => enableEdit(i)}
                title={
                  edit > -1 && edit != i
                    ? "Please save the current template before editing another"
                    : "Edit"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke={edit == i ? "blue" : "currentColor"}
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
            </div>
            <div className="w-full">
              <label
                className="text-sm font-normal not-italic required"
                htmlFor=""
              >
                Subject
              </label>
              <InputBox
                size={"small"}
                type="text"
                value={edit == i ? subject : iter?.template?.subject}
                onChange={(e) => setSubject(e?.target?.value)}
              />
            </div>
            <div className="w-full">
              <label className="text-right text-sm font-normal not-italic">
                Body
              </label>
              <Editor
                editorState={
                  edit == i
                    ? body
                    : EditorState?.createWithContent(
                        ContentState?.createFromBlockArray(
                          convertFromHTML(
                            new String(
                              iter?.template?.body?.split("&lt;").join("<"),
                            ).toString(),
                          ),
                        ),
                      )
                }
                onEditorStateChange={setBody}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="w-full flex items-end gap-4 flex-row-reverse self-end mb-4">
              <button
                disabled={edit != i}
                onClick={editTemplate}
                className="btn-md btn-primary disabled:opacity-50 disabled:pointer-events-none"
              >
                Update
              </button>
              <button
                disabled={edit != i}
                onClick={() => setEdit(-1)}
                className="btn-md btn-sec disabled:opacity-50 disabled:pointer-events-none"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full border-gray-500 border-b flex flex-col gap-3">
            <h2 className="text-gray-700 text-base not-italic font-normal ">
              Wait time before next step
            </h2>
            <div className="w-full flex gap-2 mb-4 items-center">
              <label className="text-right text-sm font-normal not-italic  !mb-0">
                Follow up in
              </label>
              <InputBox
                className={"w-auto"}
                min={0}
                size={"small"}
                type="number"
                value={+iter?.wait_time}
                onChange={(e) => changeWaitTime(i, +e?.target?.value)}
              />
              <label className="text-right text-sm font-normal not-italic !mb-0">
                days
              </label>
            </div>
          </div>
        ),
      )}
      {Object?.entries(message)?.length > 0 && (
        <p
          style={
            message?.type == "error" ? { color: "red" } : { color: "green" }
          }
          className="block text-sm font-medium mt-2"
        >
          {message?.message}
        </p>
      )}
      <div className="w-full flex items-end gap-4 flex-row-reverse self-end mt-2">
        <button onClick={submitSequence} className="btn-md btn-primary">
          Submit
        </button>
        <button
          onClick={() => changeModal("bulk-action-modal")}
          className="btn-md btn-sec"
        >
          Back
        </button>
      </div>
    </Modal>
  );
};

export default SequencingModal;
