/*
props structure 
props = {
    dropdownText : String
    dropdownData : List
}
*/
import { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import InitialsLogo from "../InitialsLogo";
import CandidateDrawer from "../../recruiter/candidate/CandidateDrawer";
const core = require("../../../lib/core");

export default function RecruiterNewSearchBar(props) {
  const [list, setList] = useState([]);
  const [hide, setHide] = useState([]);
  useEffect(() => {}, []);

  $(document).on("click", function (e) {
    if (
      $(e.target).is("#" + "recruiter_searchbar" + " .auto-complete-input") ===
        false &&
      $(e.target).is("#" + "recruiter_searchbar" + " .search-menu") === false
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  // const [searchItem, setSearchItem] = useState()
  const nav = useNavigate();

  const getSearchResult = (searchItem) => {
    if (searchItem?.type == "Job") {
      nav(`/job/active/${searchItem?._id}/description`);
    }
    if (searchItem?.type == "Candidate") {
      nav(`/job/${searchItem?.jobId}/candidate/${searchItem?._id}`);
    }
    if (searchItem?.type == "Company") {
      nav(`/company-profile/${searchItem?._id}`);
    } else {
    }
  };

  // function searchList(term) {
  //   try {
  //     setHide(false);
  //     if (term?.length < 1 && false) {
  //     } else {
  //       setTimeout(async () => {
  //         let response = await core.API(
  //           core.API_METHODS.GET,
  //           core.RECRUITER_API.GET_UNIVERSAL_SEARCH + "?key=" + term,
  //           1,
  //           {}
  //         );
  //         if (response?.data.results.length > 0) {
  //           setList(response?.data?.results);
  //         } else {
  //           setList([{ name: "No Option" }]);
  //         }
  //       }, 0);
  //     }
  //   } catch (err) {}
  // }

  let timeoutId;

  const searchList = (searchTerm) => {
    try {
      setHide(false);

      clearTimeout(timeoutId);

      timeoutId = setTimeout(async () => {
        if (searchTerm?.length < 1 && false) {
          // You can add additional conditions here if needed
        } else {
          // Replace the API call with your actual API request
          const response = await core.API(
            core.API_METHODS.GET,
            core.RECRUITER_API.GET_UNIVERSAL_SEARCH + "?key=" + searchTerm,
            1,
            {},
          );

          if (response?.data?.results?.length > 0) {
            setList(response?.data?.results);
          } else {
            setList([{ name: "No Option" }]);
          }
        }
      }, 1000); // Adjust the delay (in milliseconds) as needed
    } catch (err) {
      // Handle any errors
    }
  };

  const [activeSearch, setActiveSearch] = useState([]);
  const [buttonArray, setButtonArray] = useState([
    "Jobs",
    "Candidates",
    "Companies",
  ]);

  const [showCandidate, setShowCandidate] = useState(false);
  const [candId, setCandId] = useState("");

  return (
    // <form className="" id="recruiter_searchbar">
    <div
      className="absolute top-[14px] h-[48px] z-100 w-[35%]  bg-[#F3F5F9]  rounded-[8px] px-[12px] flex items-center focus-within:bg-[#FFF] focus-within:border-b focus-within:rounded-t-[12px] focus-within:rounded-b-[0] focus-within:border-b-[#E1E1EE]  focus-within:shadow-searchboxShadow"
      id="recruiter_searchbar"
    >
      <div className="auto-complete-input">
        <ul
          className={`search-menu absolute w-[100%] ml-[-14px]  z-[100] max-h-[400px] overflow-y-auto top-[48px] rounded-b-[12px] p-[12px] scrollbar-hide bg-[#FFF] shadow-searchboxShadow ${
            hide ? " hide" : "auto-complete-input"
          }`}
        >
          <div className="auto-complete-input">
            <span className="text-[#5F6989] text-[12px] not-italic font-normal">
              I'm searching for
            </span>
            <div className="mt-[8px]">
              {buttonArray.map((item, idx) => (
                <button
                  key={"key" + idx}
                  className={`auto-complete-input border border-[#DADAE4] rounded-[166px] px-[10px] py-[6.5px]  mr-[6px] ${activeSearch?.includes(item) ? "bg-[#EFF0F2]" : "bg-[#FFF]"}`}
                  onClick={() => {
                    if (activeSearch?.includes(item)) {
                      setActiveSearch(
                        activeSearch.filter((item1) => item1 !== item),
                      );
                    } else {
                      setActiveSearch([...activeSearch, item]);
                    }
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          {(activeSearch?.length === 0 || activeSearch.includes("Jobs")) && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal ">
                Jobs
              </span>
              {list
                ?.filter((item) => item.type === "Job")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      $(
                        "#" + "recruiter_searchbar" + " .auto-complete-input",
                      ).val(item?.name);
                      getSearchResult(item);
                      // setSearchItem(item)
                      // getDirectdata()
                    }}
                  >
                    <div className="flex items-center">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}

          {(activeSearch?.length === 0 ||
            activeSearch.includes("Candidates")) && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal ">
                Candidates
              </span>
              {list
                ?.filter((item) => item.type === "Candidate")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      setShowCandidate(true);
                      setCandId(item?.matchedProfiles[0]?._id);
                    }}
                  >
                    <div className="flex items-center  p-1">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.first_name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.first_name}
                      </span>
                      {/* <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.source}
                      </span> */}
                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic text-blue-800  font-normal">
                        {" "}
                        {item?.title}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}

          {(activeSearch?.length === 0 ||
            activeSearch.includes("Companies")) && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal">
                Companies
              </span>
              {list
                ?.filter((item) => item?.type === "Company")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      $(
                        "#" + "recruiter_searchbar" + " .auto-complete-input",
                      ).val(item.name);
                      // console.log($("#" + "recruiter_searchbar" + " .auto-complete-input"))
                      getSearchResult(item);
                      // setSearchItem(item)
                      // getDirectdata()
                    }}
                  >
                    <div className="flex items-center">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}
        </ul>
      </div>

      <span className="w-[18px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g id="Group 427319278">
            <path
              id="Star 8"
              d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
              fill="#5F6989"
            />
            <path
              id="Star 9"
              d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
              fill="#5F6989"
            />
            <path
              id="Star 10"
              d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
              fill="#5F6989"
            />
          </g>
        </svg>
      </span>
      <input
        className="h-[48px] w-[calc(100%-55px)] placeholder-[#5F6989] placeholder-[14px] bg-transparent p-[6px]  outline-none auto-complete-input "
        type="text"
        onFocus={() => setHide(false)}
        placeholder="Search jobs, companies, candidates..."
        onChange={(e) => {
          searchList(e.target.value);
        }}
      />
      {/* <span className="w-[37px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39"
          height="24"
          viewBox="0 0 39 24"
          fill="none"
        >
          <g filter="url(#filter0_d_3603_27488)">
            <rect x="1.13281" width="36.8659" height="22" rx="4" fill="white" />
            <path
              d="M15.4674 8.66406H10.8008V13.3307H15.4674V8.66406Z"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.05078 16.8359C10.0133 16.8359 10.8008 16.0484 10.8008 15.0859V13.3359H9.05078C8.08828 13.3359 7.30078 14.1234 7.30078 15.0859C7.30078 16.0484 8.08828 16.8359 9.05078 16.8359Z"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.05078 8.66406H10.8008V6.91406C10.8008 5.95156 10.0133 5.16406 9.05078 5.16406C8.08828 5.16406 7.30078 5.95156 7.30078 6.91406C7.30078 7.87656 8.08828 8.66406 9.05078 8.66406Z"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.4648 8.66406H17.2148C18.1773 8.66406 18.9648 7.87656 18.9648 6.91406C18.9648 5.95156 18.1773 5.16406 17.2148 5.16406C16.2523 5.16406 15.4648 5.95156 15.4648 6.91406V8.66406Z"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.2148 16.8359C18.1773 16.8359 18.9648 16.0484 18.9648 15.0859C18.9648 14.1234 18.1773 13.3359 17.2148 13.3359H15.4648V15.0859C15.4648 16.0484 16.2523 16.8359 17.2148 16.8359Z"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.5923 16.728H22.7578L26.8799 5.27344H28.8766L32.9987 16.728H31.1642L27.9258 7.35405H27.8363L24.5923 16.728ZM24.8999 12.2424H30.8509V13.6966H24.8999V12.2424Z"
              fill="#252E4B"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3603_27488"
              x="0.132812"
              y="0"
              width="38.8672"
              height="24"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="0.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.371771 0 0 0 0 0.411402 0 0 0 0 0.5375 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3603_27488"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3603_27488"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </span> */}
      {/* {list?.length > 0 && ( */}

      {showCandidate && (
        <CandidateDrawer setShowCandidate={setShowCandidate} candId={candId} />
      )}
    </div>
  );
}
