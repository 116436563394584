import { useState, useEffect } from "react";
import Header from "../../../components/all/header/Header";
import Filter from "../../../components/candidate/dashboard/Filter";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import JobPost from "../../../components/candidate/dashboard/JobPost";
import { useLocation } from "react-router-dom";
import HeaderNew from "../../../components/all/header/HeaderNew";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
const core = require("../../../lib/core");

export default function Dashboard() {
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const loc = useLocation();
  var filterStr;
  useEffect(() => {
    filterStr = loc.state ? loc.state : "";
    console.log(loc.state ? loc.state : "")
    getDefalutCandidateDashboard("?" + loc.pathname.split("/").at(-1));
  }, [filterStr]);

  const getDefalutCandidateDashboard = async (location) => {
    console.log(location)
    const json = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD + location,
      1
    );
    // console.log(json)
    if (json.data) {
      setFilters(json?.data?.filters);
      setSort(json?.data?.sortBy);
      setJobs(json?.data?.jobs);
      console.log(json?.data);
    } else {
      setJobs([]);
    }
  };


  const getCandidateDashboard = async (filter = "") => {
    console.log(filter)
    const json = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD + filter,
      1
    );
    // console.log(json)
    if (json.data) {
      setFilters(json?.data?.filters);
      setSort(json?.data?.sortBy);
      setJobs(json?.data?.jobs);
      console.log(json?.data);
    } else {
      setJobs([]);
    }
  };




  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="job_details_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <CandidateSidebar active="Dashboard" open={false} />
        <div className="w-full">
          <HeaderNew />
          <DashboardContent
            jobs={jobs}
            sort={sort}
            filters={filters}
            getCandidateDashboard={getCandidateDashboard}

          />
        </div>
      </div>
    </div>
  )

  // return (




  //   <div className="page candidate-pages" id="dashboard">
  //     {/* <Header showOnlyLogo={false} menu={<Menu />} /> */}
  //     <HeaderNew/>
  //     {/* <CandidateSidebar /> */}
  //     <main className="main-content no-padding col-xs-12">
  //       {Array.isArray(jobs) && jobs.length >= 0 && (
  //         <div className="col-xs-12 list-length no-padding">
  //           {jobs?.length} Results found
  //         </div>
  //       )}
  //       {/* Left Section => Sort and Filter */}
  //       <Filter
  //         sort_option={sort}
  //         filter_options={filters}
  //         filter_function={getCandidateDashboard}
  //       />
  //       {/* Right Section => List of Jobs */}
  //       <div id="job-list" className="col-xs-8 main-section no-padding">



  //         {Array.isArray(jobs) && jobs.length > 0
  //           ? jobs.map((job, i) => (
  //               <JobPost
  //                 key={i}
  //                 job={job}
  //                 reload_function={getCandidateDashboard}
  //               />
  //             ))
  //           : (
  //             <div className="col-xs-12 no-job-text">No job is matching</div>
  //           )}
  //       </div>
  //     </main>
  //   </div>
  // );
}


const DashboardContent = ({
  jobs,
  sort,
  filters,
  getCandidateDashboard,
  ...props
}) => {
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"
    >
      <span className="font-semibold text-[28px] not-italic text-[#16182F] ">
        Dashboard ({jobs?.length})
      </span>

      <div className="page candidate-pages" id="dashboard">
        <main className="main-content no-padding col-xs-12">
          {/* Left Section => Sort and Filter */}
          <Filter
            sort_option={sort}
            filter_options={filters}
            filter_function={getCandidateDashboard}
          />
          {/* Right Section => List of Jobs */}
          <div id="job-list" className="col-xs-8 main-section no-padding">



            {Array.isArray(jobs) && jobs.length > 0
              ? jobs.map((job, i) => (
                <JobPost
                  key={i}
                  job={job}
                  reload_function={getCandidateDashboard}
                />
              ))
              : (
                <div className="col-xs-12 no-job-text">No job is matching</div>
              )}
          </div>
        </main>
      </div>


    </main>
  );
};