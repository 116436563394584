import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resumeUpdateStatusService,
  resumeUploadStatusService,
  questionUploadStatusService,
} from "../../redux/services";
import { useSelector } from "react-redux";
import moment from "moment"; // Import Moment.js
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";
// import Svg from "../../../components/assets/Iphone-spinner-2.gif"
import { ScaleLoader } from "react-spinners";
import InitialsLogo from "../../components/all/InitialsLogo";
import TabLayout from "../../layouts/TabLayout";

export default function StatusModal({
  jobid,
  comingFrom,
  children,
  className,
}) {
  const [showModal, setShowModal] = useState(false);
  // const [uploadTime,setUploadTime] = useState()
  const dispatch = useDispatch();
  const [resumes, setResumes] = useState([]);
  const [questions, setQuestions] = useState([]);

  const { resumesData, times, update, questionData } = useSelector((state) => {
    return {
      resumesData: state?.resumeUploadStatusSlice?.resumes?.data,
      questionData: state?.resumeUploadStatusSlice?.questions,
      times: state?.resumeUploadStatusSlice?.times,
      update: state?.resumeUploadStatusSlice?.update,
    };
  });

  useEffect(() => {
    if (resumesData?.data) setResumes(resumesData?.data);
    // if (resumesData?.data) setResumes(resumesData?.data?.data);
    // if (questionData?.data) setQuestions(questionData?.data);
  }, [resumesData, questionData]);

  const openModal = () => {
    setShowModal(true);
    dispatch(resumeUploadStatusService(jobid));
    // dispatch(questionUploadStatusService(jobid));

    // dispatch(resumeUploadStatusService(jobid));
  };

  useEffect(() => {
    if (showModal) {
      const intervalId = setInterval(() => {
        dispatch(resumeUploadStatusService(jobid));
        // dispatch(questionUploadStatusService(jobid));
      }, 30000); // 10000 milliseconds = 10 seconds

      // Clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showModal]);

  const statusIcon = (status) => {
    switch (status) {
      case "Completed":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-lime-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        );
      case "In Progress":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="40" fill="#fff" />
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#999999"
              strokeWidth="8"
            />
            <line
              x1="50"
              y1="50"
              x2="50"
              y2="30"
              stroke="#999999"
              strokeWidth="8"
            />
            <line
              x1="50"
              y1="50"
              x2="65"
              y2="50"
              stroke="#999999"
              strokeWidth="8"
            />
          </svg>
        );
      case "Failed":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 100 100"
          >
            <line
              x1="30"
              y1="50"
              x2="70"
              y2="50"
              stroke="#999999"
              strokeWidth="8"
            />
          </svg>
        );
    }
  };

  function getRandomDelay() {
    return Math.floor(Math.random() * (3000 - 2000 + 1)) + 2000; // Random delay between 2 and 3 seconds
  }

  function countdown(minutes) {
    let seconds = minutes * 60;

    const updateTimer = () => {
      const randomDelay = getRandomDelay();
      if (seconds >= randomDelay / 1000) {
        return `Time left ${Math.floor(seconds / 60)}m ${Math.floor(
          seconds % 60,
        )}s`;
        setTimeout(updateTimer, randomDelay);
        seconds -= randomDelay / 1000;
      } else {
      }
    };

    updateTimer();
  }
  //total
  var timeoutHandle;
  function countdown(minutes, seconds) {
    function tick() {
      var counter = document.getElementById("totalDocumentUploadedETA");
      counter.innerHTML =
        minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {
        timeoutHandle = setTimeout(tick, 1000);
      } else {
        if (minutes >= 1) {
          // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
          setTimeout(function () {
            countdown(minutes - 1, 59);
          }, 1000);
        }
      }
    }
    setTimeout(() => {
      tick();
    }, 5000);
  }

  //upload
  let timeoutHandleUpload;
  function countdownUpload(minutes, seconds) {
    function tickUpload() {
      var counter = document.getElementById("countdownUpload");
      counter.innerHTML =
        minutes.toString() + "m:" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {
        timeoutHandleUpload = setTimeout(tickUpload, 1000);
      } else {
        if (minutes >= 1) {
          // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
          setTimeout(function () {
            countdownUpload(minutes - 1, 59);
          }, 1000);
        }
      }
    }
    setTimeout(() => {
      tickUpload();
    }, 5000);
  }

  let timeoutHandleResume;
  function countdownResume(minutes, seconds) {
    function tickResume() {
      var counter = document.getElementById("countdownResume");
      counter.innerHTML =
        minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {
        timeoutHandleResume = setTimeout(tickResume, 1000);
      } else {
        if (minutes >= 1) {
          // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
          setTimeout(function () {
            countdownResume(minutes - 1, 59);
          }, 1000);
        }
      }
    }
    setTimeout(() => {
      tickResume();
    }, 5000);
  }

  let timeoutHandleMatch;
  function countdownMatch(minutes, seconds) {
    function tickMatch() {
      var counter = document.getElementById("countdownMatch") || {};
      counter.innerHTML =
        minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {
        timeoutHandleMatch = setTimeout(tickMatch, 1000);
      } else {
        if (minutes >= 1) {
          // countdown(mins-1);   never reach “00″ issue solved:Contributed by Victor Streithorst
          setTimeout(function () {
            countdownMatch(minutes - 1, 59);
          }, 1000);
        }
      }
    }
    setTimeout(() => {
      tickMatch();
    }, 2000);
  }

  const deleteQueue = (id) => {
    let payload = {
      queueid: id,
      isdelete: true,
    };
    dispatch(resumeUpdateStatusService(payload));

    // setTimeout(() => {
    //     dispatch(resumeUploadStatusService(jobid));
    // }, 4000);
  };

  useEffect(() => {
    if (update) {
      dispatch(resumeUploadStatusService(jobid));
    }
  }, [update]);

  const pauseQueue = (id) => {
    let payload = {
      queueid: id,
      ispaused: true,
    };
    dispatch(resumeUpdateStatusService(payload));
    // setTimeout(() => {
    //     dispatch(resumeUploadStatusService(jobid));
    // }, 4000);
  };

  const resumeQueue = (id) => {
    let payload = {
      queueid: id,
      isresume: true,
    };
    dispatch(resumeUpdateStatusService(payload));
    // setTimeout(() => {
    //     dispatch(resumeUploadStatusService(jobid));
    // }, 4000);
  };
  const core = require("../../lib/core");

  const fileInputRef = useRef(null);
  //const [resumeFileArray, setResumeFileArray] = useState()

  const handleFileChange = async () => {
    const files = Array.from(fileInputRef.current.files);
    await upload_resume(files);
    //setResumeFileArray(files);
  };
  const { user } = useContext(core.UserContext);

  async function upload_resume(resumeFileArray) {
    try {
      // setLoading(true);
      let formData = new FormData();
      for (let i = 0; i < resumeFileArray.length; i++) {
        formData.append("files", resumeFileArray[i]);
      }

      if (comingFrom !== "candidBrowser") formData.append("jobId", jobid);

      console.log(formData);
      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(core.RECRUITER_API.POST_MULTIPLE_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      }).then((res) => {
        dispatch(resumeUploadStatusService(jobid));
      });
    } catch (err) {}
  }

  function formatMinutes(minutes) {
    // Create a Moment.js duration object
    const duration = moment.duration(minutes, "minutes");

    // Extract hours and minutes
    const hours = duration.hours();
    const mins = duration.minutes();

    // Format the output
    if (hours > 0) {
      return `${hours}hr:${mins}min`;
    } else {
      return `${mins}m`;
    }
  }

  function convertBytesToKBMB(bytes) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    }
  }

  const data = [];

  const [option, setoption] = useState("option1");
  const tableData = [
    { no: 1, skills: "JavaScript", criteria: "Efficiency", status: "Complete" },
    { no: 2, skills: "React", criteria: "Performance", status: "Pending" },
    { no: 3, skills: "HTML", criteria: "Compatibility", status: "Incomplete" },
    // Add more data as needed
  ];

  return (
    <>
      <button
        className={className}
        onClick={() => openModal()}
        data-tooltip-id="my-tooltip-8"
        data-tooltip-content={"Status"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.2391 2H8.75906C4.99906 2 4.70906 5.38 6.73906 7.22L17.2591 16.78C19.2891 18.62 18.9991 22 15.2391 22H8.75906C4.99906 22 4.70906 18.62 6.73906 16.78L17.2591 7.22C19.2891 5.38 18.9991 2 15.2391 2Z"
            stroke="#252E4B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
        </svg>
        {children}
      </button>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex  self-center items-center justify-center overflow-x-hidden  outline-none focus:outline-none">
            <div className="relative w-full max-w-7xl mx-auto h-[650px]  scrollbar-hide bg-white rounded-lg shadow-lg">
              <div className="  rounded-lg p-4">
                <div className="flex justify-between items-center text-left mb-4">
                  <div>
                    <h1 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
                      Status
                    </h1>
                    <span className="text-gray-700 text-sm -mt-3 not-italic font-normal">
                      {" "}
                      Check the current status of resume and questions{" "}
                    </span>
                  </div>
                  <TabLayout
                    btnList={[
                      { name: "Resume", id: "option1" },
                      // { name: "Questions", id: "option2" },
                    ]}
                    state={option}
                    setState={setoption}
                    className="mr-8"
                  />
                </div>

                <header>
                  <div className="">
                    {option === "option1" && (
                      <div className=" -top-2 flex justify-between ">
                        <div className="w-[23%] bg-bg1 flex flex-col text-left text-[#252E4B] text-[18px] font-medium rounded-[12px] PipelineCardsShadow p-[16px]">
                          <div className="  ">
                            Total Document:{" "}
                            {resumesData?.time?.totalDocumentUploaded}
                          </div>
                          <span className="font-light text-xs ">
                            Total time :{" "}
                            <span
                              timer
                              id=""
                              className="totalDocumentUploadedETA font-light"
                            >
                              {formatMinutes(
                                resumesData?.time?.totalDocumentUploadedETA,
                              )}
                            </span>
                          </span>
                        </div>

                        <div className="bg-bg1 w-[23%] flex flex-col text-left text-[#252E4B] text-[18px] font-medium rounded-[12px] PipelineCardsShadow   p-[16px]">
                          <span className="font-medium">
                            Upload: {resumesData?.time?.uploadStatus}
                          </span>
                          {resumesData?.time?.timeRemainingUpload === 0 ? (
                            <div className="font-light text-xs">Completed</div>
                          ) : (
                            <div className="font-light text-xs">
                              <span> Time Remaining: </span>
                              <span className="">
                                <span
                                  timer
                                  id=""
                                  className="inline-span countdownUpload"
                                >
                                  {formatMinutes(
                                    resumesData?.time?.timeRemainingUpload,
                                  )}
                                </span>
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="bg-bg1 w-[23%] flex flex-col text-left  text-[#252E4B] text-[18px] font-medium rounded-[12px] PipelineCardsShadow p-[16px]">
                          <span className="font-medium">
                            Parsing: {resumesData?.time?.resumeStatus}
                          </span>

                          {resumesData?.time?.timeRemainingResume === 0 ? (
                            <div className="font-light text-xs">Completed</div>
                          ) : (
                            <div className="font-light text-xs">
                              <span> Time Remaining: </span>
                              <span className="">
                                {/* <CountdownTimer initialMinutes={(resumesData?.time?.timeRemainingResume)} />   */}
                                {/* {countdownResume(resumesData?.time?.timeRemainingResume, 0)} */}
                                <span timer id="" className="countdownResume">
                                  {formatMinutes(
                                    resumesData?.time?.timeRemainingResume,
                                  )}
                                </span>
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="bg-bg1 w-[23%] flex flex-col text-left text-[#252E4B] text-[18px] font-medium rounded-[12px] PipelineCardsShadow p-[16px]  ">
                          {comingFrom !== "candidBrowser" && (
                            <div>
                              {" "}
                              <span className="font-medium">
                                Match score: {resumesData?.time?.matchStatus}
                              </span>
                              {resumesData?.time?.timeRemainingMatch === 0 ? (
                                <div className="font-light text-xs">
                                  Completed
                                </div>
                              ) : (
                                <div className="font-light text-xs">
                                  <span> Time Remaining: </span>
                                  <span className="text-gray-600">
                                    {countdownMatch(
                                      resumesData?.time?.timeRemainingMatch,
                                      0,
                                    )}
                                    <span
                                      timer
                                      id=""
                                      className="countdownMatch"
                                    >
                                      {formatMinutes(
                                        resumesData?.time?.timeRemainingMatch,
                                      )}
                                    </span>
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="flex justify-between mt-[20px]">
                      <div className="mt-4 ml-4 flex items-start space-x-4 text-xs pb-4">
                        <div className="flex items-center space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          <span className="font-medium">Completed</span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 100 100"
                          >
                            <circle cx="50" cy="50" r="36" fill="#fff" />
                            <circle
                              cx="50"
                              cy="50"
                              r="40"
                              fill="none"
                              stroke="#999999"
                              strokeWidth="6"
                            />
                            <line
                              x1="50"
                              y1="50"
                              x2="50"
                              y2="30"
                              stroke="#999999"
                              strokeWidth="6"
                            />
                            <line
                              x1="50"
                              y1="50"
                              x2="65"
                              y2="50"
                              stroke="#999999"
                              strokeWidth="6"
                            />
                          </svg>
                          <span className="font-medium">In Progress</span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="font-medium">Failed</span>
                        </div>
                        <div className="flex items-end space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 100 100"
                          >
                            <line
                              x1="30"
                              y1="50"
                              x2="70"
                              y2="50"
                              stroke="#999999"
                              strokeWidth="6"
                            />
                          </svg>
                          <span className="font-medium">Not started</span>
                        </div>
                        <div className="flex items-end space-x-2">
                          N/A
                          <span className="font-medium ml-2">
                            {" "}
                            Not relevant match
                          </span>
                        </div>
                      </div>

                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple // Allows multiple file selection
                      />
                      {option === "option1" && (
                        <button
                          className="border-button inline-block w-auto h-[40px] text-center py-2  px-4 bg-white rounded-lg shadow border border-gray-300"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <svg
                            className="relative inline-block"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M5.29492 12.7042C8.02254 11.4822 9.64654 11.4572 12.7023 12.7042"
                              stroke="#252E4B"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <circle
                              cx="2.77778"
                              cy="2.77778"
                              r="2.77778"
                              transform="matrix(1 0 0 -1 6.22266 9)"
                              stroke="#252E4B"
                              strokeWidth="1.2"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.1262 10.8522C17.2613 10.2566 17.3327 9.6368 17.3327 9.00033C17.3327 4.39795 13.6017 0.666992 8.99935 0.666992C4.39698 0.666992 0.666016 4.39795 0.666016 9.00033C0.666016 13.6027 4.39698 17.3337 8.99935 17.3337C9.63582 17.3337 10.2556 17.2623 10.8512 17.1272"
                              stroke="#252E4B"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4805 13.6289V17.3326"
                              stroke="#252E4B"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M17.332 15.4814L13.6283 15.4814"
                              stroke="#252E4B"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="relative inline-block ml-4">
                            Add candidate
                          </span>
                        </button>
                      )}

                      <button
                        className="absolute right-[5px] top-[5px]  rounded-md"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M8.75195 8.75L21.252 21.25"
                            stroke="#252E4B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.75 21.25L21.25 8.75"
                            stroke="#252E4B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </header>

                {option === "option1" ? (
                  <div className="h-[350px] overflow-y-scroll scrollbar-candidate relative ">
                    <table className=" mt-6  overflow-y-auto relative ">
                      <thead
                        className="text-[#252E4B]  text-[12px] sticky top-0 z-[100]  font-normal not-italic   bg-[#F2F4F8] rounded-[8px]"
                        // style="position: sticky; top: 0; z-index: 1;"
                      >
                        <tr>
                          <th className="p-2 whitespace-nowrap ml-8">
                            <div className="font-semibold text-left">No</div>
                          </th>
                          <th className="p-2 whitespace-nowrap  w-1/6">
                            <div className="font-semibold text-left">
                              Document
                            </div>
                          </th>
                          <th className="p-2 whitespace-nowrap  w-1/6">
                            <div className="font-semibold text-center">
                              Source
                            </div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/6">
                            <div className="font-semibold text-left -ml-4">
                              Upload
                            </div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/8">
                            <div className="font-semibold text-left -ml-4"></div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/6">
                            <div className="font-semibold text-left -ml-4">
                              Resume parse
                            </div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/8">
                            <div className="font-semibold text-left -ml-4"></div>
                          </th>
                          {/* <th className="p-2 whitespace-nowrap w-1/6">
                                                    <div className="font-semibold text-left -ml-4">
                                                        Relevant match
                                                    </div>
                                                </th>
                                                <th className="p-2 whitespace-nowrap w-1/8">
                                                    <div className="font-semibold text-left -ml-4">

                                                    </div>
                                                </th> */}
                          {comingFrom !== "candidBrowser" && (
                            <>
                              {" "}
                              <th className="p-2 whitespace-nowrap w-1/6">
                                <div className="font-semibold text-left -ml-4">
                                  Match score
                                </div>
                              </th>
                              <th className="p-2 whitespace-nowrap w-1/8">
                                <div className="font-semibold text-left -ml-4"></div>
                              </th>
                            </>
                          )}

                          <th className="p-2 whitespace-nowrap w-1/4">
                            <div className="font-semibold text-left">
                              {" "}
                              Info{" "}
                            </div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/8">
                            <div className="font-semibold text-left -ml-4"></div>
                          </th>
                          <th className="p-2 whitespace-nowrap w-1/4">
                            <div className="font-semibold text-left">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-[#EFF0F2] relative z-0">
                        {resumes?.map((item, index) => (
                          <tr
                            key={index}
                            className="border-b border-b-[#EFF0F2] py-[15px] z-0"
                          >
                            <td className="p-2 whitespace-nowrap w-8">
                              <div className="flex items-center ">
                                <div className="font-medium  text-[14px] text-gray-800 ">
                                  {index + 1}
                                </div>
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="flex items-center truncate">
                                <div className="font-medium text-[14px] text-gray-800 text-left w-[300px]">
                                  <a
                                    className="text-blue-600"
                                    href={item?.fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.fileName}
                                  </a>
                                  {item?.size && (
                                    <span className="ml-2 text-gray-700">
                                      {convertBytesToKBMB(item?.size)}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap text-gray-700">
                              <p className="text-center text-[12px]">
                                {item?.source}
                              </p>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left">
                                {statusIcon(item?.uploadStatus)}
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left"></div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left">
                                {statusIcon(item?.resumeParseStatus)}
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left"></div>
                            </td>
                            {/* <td className="p-2 whitespace-nowrap">
                                                        <div className="text-left">
                                                            {item?.preMatchStatus === "NotRelevant" ? <span className="text-gray-700">N/A</span> : statusIcon(item?.preMatchStatus)}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-left">

                                                        </div>
                                                    </td> */}

                            {comingFrom !== "candidBrowser" && (
                              <>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-center">
                                    {statusIcon(item?.matchStatus)}
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left"></div>
                                </td>
                              </>
                            )}

                            <td className="p-2 whitespace-nowrap truncate max-w-[200px]">
                              <div className="text-left text-xs text-gray-600">
                                {item?.resumeStatus === "Completed" &&
                                !item?.job
                                  ? ""
                                  : item?.failedReason}
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left"></div>
                            </td>
                            {item?.matchStatus !== "Completed" ? (
                              <td className="p-2 whitespace-nowrap">
                                <div className="flex space-x-2">
                                  {item?.matchStatus === "Failed" ||
                                  item?.resumeParseStatus === "Failed" ||
                                  item?.matchStatus === "Paused" ||
                                  item?.resumeParseStatus === "Paused" ? (
                                    <button
                                      onClick={() => resumeQueue(item?.id)}
                                      className="border-button flex items-center rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF] py-[8px] px-[10px]   justify-center"
                                    >
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C15.3313 3 18.2398 4.80989 19.796 7.5M19.796 7.5V3M19.796 7.5H15.375" stroke="#252E4B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg> */}
                                      <span className="text-[#252E4B] text-[14px] font-normal not-italic hover:text-[#145ECC] focus:text-[#145ECC]">
                                        Retry
                                      </span>
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="border-button flex items-center rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF] py-[8px] px-[10px]   justify-center"
                                        onClick={() => resumeQueue(item?.id)}
                                      >
                                        <span className="text-[#252E4B] text-[14px] font-normal not-italic hover:text-[#145ECC] focus:text-[#145ECC]">
                                          Retry
                                        </span>
                                      </button>
                                    </>
                                  )}

                                  <button
                                    className="border-button flex items-center rounded-[8px] border h-[32px] border-[#dadae4] bg-[#FFF] py-[8px] px-[10px]   justify-center"
                                    onClick={() => deleteQueue(item?.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M14.25 6.75L13.7131 13.7301C13.5928 15.2931 12.2895 16.5 10.7219 16.5H7.27809C5.71048 16.5 4.40716 15.2931 4.28693 13.7301L3.75 6.75M15.75 5.25C13.8016 4.30048 11.4852 3.75 9 3.75C6.51476 3.75 4.19844 4.30048 2.25 5.25M7.5 3.75V3C7.5 2.17157 8.17157 1.5 9 1.5C9.82843 1.5 10.5 2.17157 10.5 3V3.75M7.5 8.25V12.75M10.5 8.25V12.75"
                                        stroke="#FF475D"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            ) : (
                              <></>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="h-[450px] overflow-y-scroll scrollbar-candidate relative ">
                    <table className="min-w-full ">
                      <thead className="text-[#252E4B]  text-[12px] sticky top-0 z-[100]  font-normal not-italic   bg-[#F2F4F8] rounded-[8px]">
                        <tr className="bg-gray-200">
                          <th className=" px-4 py-2">No</th>
                          <th className=" px-4 py-2">Skills</th>
                          <th className=" px-4 py-2">Criteria</th>
                          <th className=" px-4 py-2">Status</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-[#EFF0F2] relative z-0">
                        {questions?.map((item, index) => (
                          <tr key={index} className="text-left">
                            <td className=" px-4 py-2 ">{index + 1}</td>
                            <td className=" px-4 py-2 font-medium text-[14px] text-blue-600">
                              {item.skill}
                            </td>
                            <td className=" px-4 py-2 font-normal text-[14px] whitespace-nowrap text-gray-700">
                              {item.criteria}
                            </td>
                            <td className=" px-4 py-2">
                              {statusIcon(item.status)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {/* <div className="border-t border-gray-300"></div> */}

              {/* <button
                                className="-mt-12 pb-4 text-red-500 font-medium uppercase px-6  text-sm outline-none focus:outline-none mr-2"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button> */}
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
