import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import React, { useState, useEffect, useRef, useContext } from "react";
import Mcq from "../../../components/candidate/assessment/Mcq";
import AutonomusInterview from "../../../components/candidate/assessment/AutonomusInterview";
import CodingSection from "../../../components/candidate/assessment/CodingIDE";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import InterviewStart from "../../../components/candidate/assessment/InterviewStart";
import InterviewEnd from "../../../components/candidate/assessment/InterviewEnd";
import { async } from "q";
const core = require("../../../lib/core");

function Assessment() {
  const nav = useNavigate();
  const loc = useLocation();

  const { user } = useContext(core.UserContext);
  const client = ZoomMtgEmbedded.createClient();

  const [interviewRunning, setInterviewRunning] = useState(false);
  const [popUp, setPopUp] = useState();
  const [sequence, setSequence] = useState([
    "start",
    "mcq",
    "coding",
    "interview",
    "end",
  ]);
  // const [sequence, setSequence] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [mcQuestions, setMcQuestions] = useState([]);
  const [codeQsn, setCodeQsn] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(
    +getCookie("timer")?.split(" ")[0] * 60
  );
  // console.log(getCookie("timer"), timeRemaining)
  const [meetingUrl, setMeetingUrl] = useState("");
  var page;
  // On page load => load only once
  // Get interview details and questions

  useEffect(() => {
    if (interviewRunning) {
      startMeeting();
      getIntdeatails(getCookie("assessmentId"));
    }
    page = document.getElementById("assessment");

    // Disable right-click menu
    page.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, [interviewRunning]);

  async function startMeeting() {
    try {
      let meetingSDKElement = document.getElementById("ZoomMeet");
      let json = await core.API(
        core.API_METHODS.POST,
        core.BOT_API.CREATE_ZOOM_MEET,
        1,
        {}
        // code: loc?.search?.split("code=")?.at(-1),
      );
      // console.log(json.data);
      client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        disableCOEP: false,
        customize: {
          meetingInfo: [
            "topic",
            "host",
            "mn",
            "pwd",
            "telPwd",
            // "invite",
            "participant",
            "dc",
            "enctype",
          ],
          video: {
            popper: {
              disableDraggable: true,
              anchorElement: meetingSDKElement,
              placement: "top",
            },
            isResizable: false,
            viewSizes: {
              default: {
                width: 233,
                height: window.innerHeight - 174 - 80,
              },
              ribbon: {
                width: 233,
                height: window.innerHeight - 174 - 80,
              },
            },
          },
          toolbar: {
            buttons: [],
          },
        },
      });
      client.join({
        signature: json.signature,
        sdkKey: "fzbCTlQTR6eM4Rog8GMqfg",
        meetingNumber: json.data.id,
        password: json.data.encrypted_password,
        userName: user.name,
        userEmail: user.email,
        //   tk: registrantToken,
        zak: json.zak,
        success: function () {
          ZoomMtgEmbedded.getInstance().addListen('userAudioStatusChange', function (payload) {
            console.log('Audio status changed:', payload);
          });

          ZoomMtgEmbedded.getInstance().addListen('userVideoStatusChange', function (payload) {
            console.log('Video status changed:', payload);
          });
        }

      });

      enterBot(json.data.join_url);
      setMeetingUrl(json.data.join_url);
      // setInterviewRunning(true);

      // Get meeting object
      let meetingObj = client.getMeetingObj();

      // Disable active speaker view and enable gallery view
      meetingObj.activeSpeakerView = false; // Disable active speaker view
      meetingObj.showGalleryView();

      // Show host's video by default
      let hostVideo = meetingObj
        .getAttendees()
        .find((attendee) => attendee.isHost);
      if (hostVideo) {
        hostVideo.videoOn = true;
      }


    } catch (err) {
      console.log(err);
      // setInterviewRunning(true);
    }
  }


  async function enterBot(url) {
    console.log(url)
    console.log(getCookie("assessmentId"))

    try {
      const token = "Token aa71aa7bf0dbc53aa62c212206f7df3f95b21962";
      await fetch(core.BOT_API.POST_JOIN_ASSESSMENT_MEETING, {
        method: "POST",
        headers: { Authorization: token, "Content-Type": "application/json" },
        body: JSON.stringify({
          meeting_url: url,
          token: "aa71aa7bf0dbc53aa62c212206f7df3f95b21962",
          interviewId: getCookie("assessmentId"),
        }),
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log("Bot Enterred", res.data);
          })
      );
    } catch (err) {
      console.log(err);
    }
  }
  async function getIntdeatails(id) {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_CANDIDATE_INTERVIEW_DETAILS + id,
        1
      );
      if (json?.data) {
        console.log(json?.data);
        setMcQuestions(json.data.interview.questions);
        setCodeQsn(json.data.interview.codingChallenges);
        setSelectedAnswers({
          coding: [
            ...Array(json.data.interview.codingChallenges.length).fill(""),
          ],
          mcq: [...Array(json.data.interview.questions.length).fill([])],

          testCases:[...Array(json.data.interview.codingChallenges.length).fill(""),]
        });
        // setSequence(["start", ...json.data.interview.sequence, "end"])
        // setCurrentPage(json.data.interview.sequence[0])
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function endTest(status) {
    try {
      setInterviewRunning(false);
      document.exitFullscreen();
      if ($("#confirmPopup").hasClass("in")) {
        closePopUp();
      }
      ZoomMtgEmbedded.destroyClient();
      await core
        .API(
          core.API_METHODS.POST,
          core.BOT_API.POST_LEAVE_MEETING + getCookie("assessmentId"),
          1
        )
        .then((json) => {
          // console.log(json);
        });
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_INTERVIEW + getCookie("assessmentId"),
        1,
        { status: "completed", response: selectedAnswers }
      );
      handleStopSharingRedirect();
      page.removeEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
      // console.log(json?.data?.message);
      // nav("/assessment-dashboard");
      setCurrentPage(sequence?.length - 1);
    } catch (e) {
      console.log(e);
      setInterviewRunning(false);
      setCurrentPage(sequence?.length - 1);
    }
  }

  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  async function UpdateTestState(status) {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_INTERVIEW + loc.state.id,
        1,
        { status: "completed", response: selectedAnswers }
      );
    } catch (e) {
      console.log(e);
    }
  }

  // Timer function
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const formattedTimeRemaining = [
    `${minutes < 10 ? "0" : ""}${minutes}`,
    `${seconds < 10 ? "0" : ""}${seconds}`,
  ];

  useEffect(() => {
    if (interviewRunning) {
      // check if full screen at every second
      // ifExitFullScreen();
      const timer = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining((prevTime) => prevTime - 1);
          if (
            timeRemaining <= +(getCookie("timer")?.split(" ")[0] * 60) / 5 &&
            timeRemaining % 2 == 0
          ) {
            $("#assessment .time-text").css("color", "red");
          } else {
            $("#assessment .time-text").css("color", "#252e4b");
          }
        } else {
          endTest("timeoutExit");
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeRemaining, interviewRunning]);

  function closePopUp() {
    $("#confirmPopup").hide();
    $("#confirmPopup").css("display", "none");
    $("#confirmPopup").removeClass("in");
    $(".modal-backdrop.fade.in").remove();
    $("body").removeClass("modal-open");
  }

  // Disable keyboard shortcuts - Reload, Copy-cut-paste, Open Dev tools

  // document.onkeydown = (e) => {
  //   if (
  //     interviewRunning &&
  //     (e.key == 123 ||
  //       e.key == "F5" ||
  //       (e.ctrlKey &&
  //         e.shiftKey &&
  //         (e.key == "I" || e.key == "C" || e.key == "J" || e.key == "T")) ||
  //       (e.altKey && e.key == "Tab") ||
  //       (e.ctrlKey &&
  //         (e.key == "U" ||
  //           e.key == "u" ||
  //           e.key == "C" ||
  //           e.key == "c" ||
  //           e.key == "X" ||
  //           e.key == "x" ||
  //           e.key == "v" ||
  //           e.key == "V" ||
  //           e.key == "R" ||
  //           e.key == "r")))
  //   ) {
  //     e.preventDefault();
  //   }
  
  // };

  // Detecting Full screen change
  // function ifExitFullScreen() {
  //   if (
  //     interviewRunning &&
  //     window.screen.width != window.innerWidth &&
  //     !(
  //       document.fullscreenElement /* Standard syntax */ ||
  //       document.webkitFullscreenElement /* Safari and Opera syntax */ ||
  //       document.msFullscreenElement
  //     ) &&
  //     !$("#confirmPopup").hasClass("in")
  //   ) {
  //     setPopUp(
  //       <ConfirmPopUp
  //         textDiv={
  //           <div>
  //             <h4>Exiting full screen will end this test.</h4>
  //             {/* <h3>Confirm exit</h3> */}
  //           </div>
  //         }
  //         idTxt="fullScreenExitWarn"
  //         onSave={() => {
  //           closePopUp();
  //           document.documentElement.requestFullscreen();
  //         }}
  //         saveTxt="Continue test "
  //         onReset={() => {
  //           closePopUp();
  //           endTest("fullScreenExit");
  //         }}
  //         resetTxt="End test "
  //       />
  //     );
  //     $("#confirmPopup").show();
  //     $("#confirmPopup").css("display", "block");
  //     $("#confirmPopup").addClass("in");
  //     if ($(".modal-backdrop").length == 0) {
  //       $("#assessment").append('<div className="modal-backdrop fade in"></div>');
  //     }
  //     $("body").addClass("modal-open");
  //   }
  // }

  // Detecting tab, window or desktop switch
  // document.addEventListener("visibilitychange", () => {
  //   if (interviewRunning) {
  //     if (document.visibilityState == "hidden") {
  //       setPopUp(
  //         <ConfirmPopUp
  //           textDiv={
  //             <div>
  //               <h4>Switching from this window will end this test</h4>
  //             </div>
  //           }
  //           idTxt="tabSwitchExitWarn"
  //           onSave={() => {
  //             closePopUp();
  //             document.documentElement.requestFullscreen();
  //           }}
  //           saveTxt="Continue test "
  //           onReset={() => {
  //             closePopUp();
  //             endTest("tabSwitchExit");
  //           }}
  //           resetTxt="End test"
  //         />
  //       );
  //       $("#confirmPopup").show();
  //       $("#confirmPopup").css("display", "block");
  //       $("#confirmPopup").addClass("in");
  //       if ($(".modal-backdrop").length == 0) {
  //         $("#assessment").append('<div className="modal-backdrop fade in"></div>');
  //       }
  //       $("body").addClass("modal-open");
  //     }
  //   }
  // });

  const [isSharing, setIsSharing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const mediaStreamRef = useRef(null);

  const handleStartSharing = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: "monitor",
        },
      });
      mediaStreamRef.current = mediaStream;
      setIsSharing(true);
      // console.log(mediaStream);
      const sharingOption = mediaStream
        .getVideoTracks()[0]
        .getSettings().displaySurface;

      const mediaRecorder = new MediaRecorder(mediaStream);
      const chunks = [];

      // console.log(mediaRecorder);

      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      // console.log(sharingOption);

      if (sharingOption != "monitor") {
        const confirmed = window.confirm(
          "Choose the entire screen sharing option and try again"
        );
        if (confirmed) {
          handleStopSharingRedirect();
        } else {
          handleStopSharingRedirect();
          nav("/assessment-dashboard");
        }
      } else {
      }

      mediaRecorder.onstop = () => {
        // console.log("called");
        const blob = new Blob(chunks, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);

        if (sharingOption != "monitor") {
        } else {
          VideoStoreS3(blob);
          if (interviewRunning) {
            endTest("stopRecordExit");
          } else {
            handleStopSharingRedirect();
            // handleStartSharing()
          }
        }
      };

      mediaRecorder.start();
      setRecordedChunks(chunks);
    } catch (error) {
      console.error(error);
      const confirmed = window.confirm(
        "You are not able to take the test without recording the screen. Please try again"
      );
      if (confirmed) {
        handleStartSharing();
      } else {
        nav("/assessment-dashboard");
      }
    }
  };

  const handleStopSharing = () => {
    const confirmed = window.confirm(
      "This action will take you out from assessment"
    );
    if (confirmed) {
      if (mediaStreamRef.current) {
        const tracks = mediaStreamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      setIsSharing(false);
    } else {
    }
  };

  const handleStopSharingRedirect = () => {
    if (mediaStreamRef.current) {
      const tracks = mediaStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
    setIsSharing(false);
  };

  async function VideoStoreS3(blob) {
    try {
      const formData = new FormData();
      formData.append("file", blob, "recorded-video.mp4");
      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(
        core.CANDIDATE_API.POST_VIDEO_STORAGE + loc.pathname.split("/").at(-1),
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleStartSharing();
  }, []);

  const needHelpAPI = async () => {
    const json = await core.API(
      core.API_METHODS.POST,
      core.CANDIDATE_API.POST_NEED_HELP,
      1,
      { meetingLink: meetingUrl }
    );
    if (json?.data) {
      toast.success(
        "Your request for help has been sent. Someone will be in touch shortly."
      );
    }
  };

  const [reload, setReload] = useState(0);

  async function afterMeeting(rating, feedback) {
    const json = await core.API(
      core.API_METHODS.POST,
      core.CANDIDATE_API.POST_FEEDBACK + getCookie("assessmentId"),
      1,
      {
        rating: rating,
        feedback: feedback,
      }
    );
  }

  const [countSkipQsn, setCountSkipQsn] = useState(0)

  return (
    <div className="page candidate-pages overflow-auto" id="assessment">
      <main
        className={`main-content no-padding col-xs-12 ${sequence[currentPage]}`}
      >
        <div className="col-xs-12 header-section no-padding">
          <div className="jobPosition-section">
            <span className="text">Data Science Intern</span>
          </div>

          <div className="timer-section">
            <span className="time-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
              >
                <path
                  d="M20.1663 11.5C20.1663 16.56 16.0597 20.6666 10.9997 20.6666C5.93967 20.6666 1.83301 16.56 1.83301 11.5C1.83301 6.43998 5.93967 2.33331 10.9997 2.33331C16.0597 2.33331 20.1663 6.43998 20.1663 11.5Z"
                  stroke="#5F6989"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.4021 14.4148L11.5604 12.719C11.0654 12.4256 10.6621 11.7198 10.6621 11.1423V7.38397"
                  stroke="#5F6989"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Time left
            </span>
            <div className="timer">
              <div className="time-text ">{formattedTimeRemaining[0]}</div>
              <span>:</span>
              <div className="time-text">{formattedTimeRemaining[1]}</div>
            </div>
            {isSharing && (
              <button
                className="stopSharing-button"
                onClick={handleStopSharing}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M5 7.66683V12.3335C5 13.2669 5 13.7334 5.18166 14.0899C5.34144 14.4035 5.59623 14.6587 5.90983 14.8185C6.266 15 6.73249 15 7.66409 15H12.3359C13.2675 15 13.7333 15 14.0895 14.8185C14.4031 14.6587 14.6587 14.4035 14.8185 14.0899C15 13.7337 15 13.2679 15 12.3363V7.66409C15 6.73249 15 6.266 14.8185 5.90983C14.6587 5.59623 14.4031 5.34144 14.0895 5.18166C13.733 5 13.2669 5 12.3335 5H7.66683C6.73341 5 6.26635 5 5.90983 5.18166C5.59623 5.34144 5.34144 5.59623 5.18166 5.90983C5 6.26635 5 6.73341 5 7.66683Z"
                    fill="#FF475D"
                    stroke="#FF475D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Stop recording
              </button>
            )}

            <button
              className="end-button"
              onClick={() => {
                endTest("buttonExit");
              }}
            >
              End test
            </button>
          </div>
        </div>
        {interviewRunning ? (
          // Interview is running and the current section is MCQ
          sequence[currentPage] == "mcq" ? (
            <Mcq
              questions={mcQuestions}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              UpdateTestState={UpdateTestState}
              selectedAnswers={selectedAnswers.mcq}
              setSelectedAnswers={(qId, oId, checked) => {
                if (checked) {
                  selectedAnswers.mcq[qId] = [...selectedAnswers.mcq[qId], oId];
                } else {
                  selectedAnswers.mcq[qId] = selectedAnswers.mcq[qId].filter(
                    (d) => d != oId
                  );
                }
                setReload(reload + 1);
              }}
              navigation={{
                currentPage: currentPage,
                allPages: sequence,
                changePage: setCurrentPage,
              }}
            />
          ) : // Interview is running and the current section is Coding
            sequence[currentPage] == "coding" ? (
              <CodingSection
                interviewId={getCookie("assessmentId")}
                codeQsn={codeQsn}
                UpdateTestState={UpdateTestState}
                setAnswers={(val, qId) => {
                  selectedAnswers.coding[qId] = val;
                  // console.log(selectedAnswers.coding);
                }}
                setPassedCases={(val, qId) => {
                  selectedAnswers.testCases[qId] = val;
                }}
                navigation={{
                  currentPage: currentPage,
                  allPages: sequence,
                  changePage: setCurrentPage,
                }}
              />
            ) : // Interview is running and the current section is Video interview
              sequence[currentPage] == "interview" ? (
                // <></>
                <AutonomusInterview
                  questions={mcQuestions}
                  endTest={endTest}
                  formattedTimeRemaining={formattedTimeRemaining}
                  currentQuestion={currentQuestion}
                  selectedAnswers={selectedAnswers}
                  UpdateTestState={UpdateTestState}
                  setCountSkipQsn={setCountSkipQsn}
                  countSkipQsn={countSkipQsn}
                  navigation={{
                    currentPage: currentPage,
                    allPages: sequence,
                    changePage: setCurrentPage,
                  }}
                />
              ) : null
        ) : (
          <>
            <div className="backdrop" style={{ background: "#F3F5F9" }} />
            {
              //  Interview is not running, but it has started. => Landing page
              sequence[currentPage] == "start" ? (
                <InterviewStart
                  startMeeting={() => {
                    setInterviewRunning(true);
                    setCurrentPage(currentPage + 1);
                  }}
                  sequence={sequence}
                  quLen={[mcQuestions, codeQsn]}
                />
              ) : // Interview is not running, it has ended. => End review page
                sequence[currentPage] == "end" ? (
                  <InterviewEnd
                    afterMeeting={afterMeeting}
                    selectedAnswers={selectedAnswers}
                    countSkipQsn={countSkipQsn}
                    timeTaken={
                      +getCookie("timer")?.split(" ")[0] * 60 - timeRemaining
                    }
                    questionsAttempted={selectedAnswers}
                  />
                ) : null
            }
          </>
        )}
        <div id="ZoomMeet" className="interview" />
        <a onClick={() => needHelpAPI()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clipPath="url(#clip0_1034_105539)">
              <path
                d="M10.2623 7.56059L10.7669 7.88527L10.2623 7.56059ZM6.9 6.75C6.9 7.08137 7.16863 7.35 7.5 7.35C7.83137 7.35 8.1 7.08137 8.1 6.75H6.9ZM8.4 10.125C8.4 10.4564 8.66863 10.725 9 10.725C9.33137 10.725 9.6 10.4564 9.6 10.125H8.4ZM9.6 12C9.6 11.6686 9.33137 11.4 9 11.4C8.66863 11.4 8.4 11.6686 8.4 12H9.6ZM8.4 12.75C8.4 13.0814 8.66863 13.35 9 13.35C9.33137 13.35 9.6 13.0814 9.6 12.75H8.4ZM15.9 9C15.9 12.8108 12.8108 15.9 9 15.9V17.1C13.4735 17.1 17.1 13.4735 17.1 9H15.9ZM9 15.9C5.18924 15.9 2.1 12.8108 2.1 9H0.9C0.9 13.4735 4.52649 17.1 9 17.1V15.9ZM2.1 9C2.1 5.18924 5.18924 2.1 9 2.1V0.9C4.52649 0.9 0.9 4.52649 0.9 9H2.1ZM9 2.1C12.8108 2.1 15.9 5.18924 15.9 9H17.1C17.1 4.52649 13.4735 0.9 9 0.9V2.1ZM9.9 6.75C9.9 6.93006 9.84774 7.09609 9.75777 7.23592L10.7669 7.88527C10.9777 7.5576 11.1 7.16716 11.1 6.75H9.9ZM8.1 6.75C8.1 6.25294 8.50294 5.85 9 5.85V4.65C7.8402 4.65 6.9 5.5902 6.9 6.75H8.1ZM9 5.85C9.49706 5.85 9.9 6.25294 9.9 6.75H11.1C11.1 5.5902 10.1598 4.65 9 4.65V5.85ZM8.4 9.75V10.125H9.6V9.75H8.4ZM9.75777 7.23592C9.6645 7.38087 9.54738 7.53138 9.40851 7.70396C9.27593 7.86873 9.11878 8.05925 8.9779 8.25288C8.70036 8.63431 8.4 9.1382 8.4 9.75H9.6C9.6 9.53337 9.70667 9.29089 9.94823 8.95889C10.0669 8.7958 10.1973 8.63784 10.3434 8.45623C10.4833 8.28243 10.636 8.08865 10.7669 7.88527L9.75777 7.23592ZM8.4 12V12.75H9.6V12H8.4Z"
                fill="#5F6989"
              />
            </g>
            <defs>
              <clipPath id="clip0_1034_105539">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Need help
        </a>
      </main>
      {popUp}
      <ToastContainer />
    </div>
  );
}

export default Assessment;
