import React, { useState, useEffect, useMemo } from 'react';
import io from 'socket.io-client';
import { Device } from '@twilio/voice-sdk';
const core =require("../../lib/core")




const Twilio = () => {
  const [token, setToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzY5OTUxZDkyNGEwYzhmMDYxYzAyNjkxY2Y4NGYyY2E2LTE2OTMyODk0MDIiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJ1c2VyIiwidm9pY2UiOnsiaW5jb21pbmciOnsiYWxsb3ciOnRydWV9LCJvdXRnb2luZyI6eyJhcHBsaWNhdGlvbl9zaWQiOiJBUDRiNGQ0MWM5ZjBkY2E2YmMyMTBmNGNjMTIyMGRmZjM2In19fSwiaWF0IjoxNjkzMjg5NDAyLCJleHAiOjE2OTMyOTMwMDIsImlzcyI6IlNLNjk5NTFkOTI0YTBjOGYwNjFjMDI2OTFjZjg0ZjJjYTYiLCJzdWIiOiJBQ2M2ZGU4NTYxOGEyOTcyZGI4NGY0NzI3YmJkODM1NGI1In0.3LM-5lbTq9CmcUME5aHgi-dCb2g70BPrTtXS7B0ljFc");
  const [device, setDevice] = useState(null);

 

  // useEffect(() => {
  //   // Fetch the token from your server
  //   fetch('/token')
  //     .then(response => response.json())
  //     .then(data => {
  //       setToken(data.token);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching token:', error);
  //     });
  // }, []);

  useEffect(() => {
    if (token) {
      const newDevice = new Device(token);
      setDevice(newDevice);

      // Clean up device when component unmounts
      return () => {
        newDevice.disconnectAll();
      };
    }
  }, [token]);

  device.addListener('registered', device => {
    console.log('The device is ready to receive incoming calls.')
  });


  const handleSuccessfulRegistration = () => {
    console.log('The device is ready to receive incoming calls.')
  }
  
  device.on('registered', handleSuccessfulRegistration);

  const handleCall = () => {
    if (device) {
      console.log("called")
      device.connect({ params: { To: '+918005699799' } });
    }
  };

  const handleHangUp = () => {
    if (device) {
      device.disconnectAll();
    }
  };


  return (
    <div>
    <h1>Twilio Audio Call</h1>
    <button onClick={handleCall}>Call</button>
    <button onClick={handleHangUp}>Hang Up</button>
  </div>
  );
};

export default Twilio;
