import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import JobSidebar from "../../components/all/JobSidebar";
// const core = require("../lib/core");
import "../../style/admin/_admin.scss";
import { GRAFANA_BASE } from "../../lib/core";
const AdminDashboard = () => {
  const menuRef = useRef();
  const [tab, setTab] = useState("all");
  const [menu, setMenu] = useState(false);
  // const { user } = useContext(core.UserContext);

  const GRAFANA_URL_ADMIN_URL =
    GRAFANA_BASE +
    "/d-solo/a01e1919-ba09-49b0-859c-17518b982a33/admin-dashboard?orgId=1&theme=light&panelId=";

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setMenu(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const dataLink = [
    {
      title: "Number of job modelled using SproutsAI per month",
      link: "1",
    },
    {
      title: "Number of JD processed per month",
      link: "2",
    },
    {
      title: "Number of resumes processed per month",
      link: "3",
    },
    {
      title: "Applicant source",
      link: "4",
    },
    {
      title: "Number of full video interviews done via SproutsAI per month",
      link: "5",
    },
    {
      title: "Number of outreach via SproutsAI per month",
      link: "6",
    },
    {
      title: "Number of assessments done via SproutsAI per month",
      link: "7",
    },
  ];
  const data =
    tab == "all"
      ? dataLink
      : tab == "job"
        ? dataLink.slice(0, 4)
        : dataLink.slice(4);
  console.log(data.length);
  const pageLength = Math.ceil(dataLink?.length / 4);

  return (
    <div className="w-screen h-screen flex" id="adminDashboard">
      <JobSidebar active="Admin Dashboard" open={false} />
      <div className="w-full">
        <h1
          className="relative leading-10 text-2xl px-10 pt-4 flex items-center"
          ref={menuRef}
        >
          Admin dashboard
          <svg
            onClick={() => setMenu((prev) => !prev)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 ml-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
          {menu && (
            <div className="w-[240px] z-[333] max-h-[400px] overflow-auto scrollbar-hide p-1 list-style-none bg-white shadow-menuOptionShadow rounded-lg absolute origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none top-14 left-8">
              <li
                onClick={() => {
                  setTab("all");
                  setMenu(false);
                }}
                className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 group ${
                  tab == "all"
                    ? "font-medium bg-blue-100 selected "
                    : "font-normal bg-transparent"
                }`}
              >
                All
              </li>
              <li
                onClick={() => {
                  setTab("job");
                  setMenu(false);
                }}
                className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 group ${
                  tab == "job"
                    ? "font-medium bg-blue-100 selected "
                    : "font-normal bg-transparent"
                }`}
              >
                Job KPIs
              </li>
              <li
                onClick={() => {
                  setTab("candidate");
                  setMenu(false);
                }}
                className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 group ${
                  tab == "candidate"
                    ? "font-medium bg-blue-100 selected "
                    : "font-normal bg-transparent"
                }`}
              >
                Candidate KPIs
              </li>
            </div>
          )}
        </h1>

        <Carousel
          className="w-full h-full"
          showStatus={false}
          showArrows={data.length > 4}
          showIndicators={data.length > 4}
          showThumbs={data.length > 4}
          // onChange={onChange}
          // onClickItem={onClickItem}
          // onClickThumb={onClickThumb}
        >
          {Array(pageLength)
            .fill(0)
            ?.map((_, page_no) => (
              <div className="w-full h-[calc(100vh-64px)] grid grid-cols-2 grid-rows-2 gap-4 p-10">
                {Array(data?.length >= (page_no + 1) * 4 ? 4 : data?.length % 4)
                  .fill(0)
                  ?.map((_, frame_no) => (
                    <div className="border border-gray-600 rounded-lg shadow-lg flex flex-col">
                      <h1 className="w-full text-md font-medium text-gray-700 px-3 py-2">
                        {data[page_no * 4 + frame_no]?.title}
                      </h1>
                      <iframe
                        className="!w-full h-full bg-white [div]:border-0 border-0 !m-0"
                        title="statuschart"
                        frameBorder="0"
                        style={{ borderWidth: 0 }}
                        src={
                          GRAFANA_URL_ADMIN_URL +
                          data[page_no * 4 + frame_no]?.link
                        }
                      />
                    </div>
                  ))}
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default AdminDashboard;
